import { BackBottom, ProChat, useProChat } from '@ant-design/pro-chat'
import type { TooltipProps } from 'antd'
import { Modal } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../../../app/hooks'
import assistantImg from '../../../../assistant.jpg'
import Debug from '../../../../components/helpers/Debug'
import type { TChat } from '../../../../redux/api/assistant/enhanced'
import type { RunBody } from '../../../../redux/api/assistant/run'
import type {
  ChatThreadResponse,
  ThreadBody,
} from '../../../../redux/api/assistant/thread'
import {
  getThreadModelDescriptor,
  getVLLMParamsModelDescriptor,
} from '../../../../redux/slices/assistant/thread'
import { params, paths } from '../../../../routes/paths'
import userImg from '../../../../user.jpg'
import { MyTooltip } from '../../../Chat/VLLMParamsForm'
import FeedbackForm from '../FeedbackForm'

import Actions from './Actions'
import ChatBage from './ChatBage'

interface ChatProps {
  user_id: string
  urlThreadId: string
  assistantId: string
  ask: ({
    runBody,
    threadId,
  }: {
    runBody: Omit<RunBody, 'vllm_params'>
    threadId?: string
  }) => Promise<void>
  regenerate: () => Promise<void>
  msgId?: string
  setChats: (chats: TChat[]) => void
  threadId?: string
  areRunsLoading: boolean
  setMsgId: (val: string | undefined) => void
  thread: ChatThreadResponse | undefined
  chats: TChat[]
  startChat: (threadBody: ThreadBody) => Promise<ChatThreadResponse | undefined>
  chatDelete: () => Promise<void>
  formatRunId: (initialId: string | undefined) => string | undefined
}

const Chat: React.FC<ChatProps> = ({
  chats,
  formatRunId,
  urlThreadId,
  threadId,
  thread,
  areRunsLoading,
  msgId,
  assistantId,
  chatDelete,
  setMsgId,
  startChat,
  setChats,
  ask,
  regenerate,
}) => {
  const [open, setOpen] = useState(false)
  const { accessToken, user } = useAppSelector((state) => state.auth)

  const feedbackChat = chats.find(({ id }) => formatRunId(id) === msgId)

  const divRef = useRef<HTMLDivElement>(null)

  const proChat = useProChat()
  const { scrollToBottom } = proChat

  const chatRef = useRef(proChat)

  const { t } = useTranslation()

  const [debugOpen, setDebugOpen] = useState(false)
  const navigate = useNavigate()

  const { threads } = useAppSelector((state) => state.assistant.thread)
  useEffect(() => {
    console.log(threads, threadId)
    if (threads.length > 0 && threadId === undefined) {
      const thread = threads[0]
      const url = paths.userChat
        .replace(params.assistant_id, assistantId)
        .replace(params.thread_id, thread.id)

      console.log(url)
      navigate(url)
    }
  }, [threads])

  const [arrow, setArrow] = useState<'Show' | 'Hide' | 'Center'>('Show')

  const mergedArrow = useMemo<TooltipProps['arrow']>(() => {
    if (arrow === 'Hide') {
      return false
    }

    if (arrow === 'Show') {
      return true
    }

    return {
      pointAtCenter: true,
    }
  }, [arrow])

  const tmd = getThreadModelDescriptor(t)
  const vllmmd = getVLLMParamsModelDescriptor(t)
  const vllm_params = thread?.vllm_params
  const vllm_params2: [React.ReactNode, string, React.ReactNode | undefined][] =
    vllm_params
      ? [
          [
            vllmmd.fields.early_stopping.label,
            JSON.stringify(vllm_params.early_stopping),
            <MyTooltip title={vllmmd.fields.early_stopping.tooltip} />,
          ],
          [
            vllmmd.fields.frequency_penalty.label,
            JSON.stringify(vllm_params.frequency_penalty),
            <MyTooltip title={vllmmd.fields.frequency_penalty.tooltip} />,
          ],
          [
            vllmmd.fields.length_penalty.label,
            JSON.stringify(vllm_params.length_penalty),
            <MyTooltip title={vllmmd.fields.length_penalty.tooltip} />,
          ],
          [
            vllmmd.fields.max_tokens.label,
            JSON.stringify(vllm_params.max_tokens),
            <MyTooltip title={vllmmd.fields.max_tokens.tooltip} />,
          ],
          [
            vllmmd.fields.presence_penalty.label,
            JSON.stringify(vllm_params.presence_penalty),
            <MyTooltip title={vllmmd.fields.presence_penalty.tooltip} />,
          ],
          [
            vllmmd.fields.repetition_penalty.label,
            JSON.stringify(vllm_params.repetition_penalty),
            <MyTooltip title={vllmmd.fields.repetition_penalty.tooltip} />,
          ],
          [
            vllmmd.fields.temperature.label,
            JSON.stringify(vllm_params.temperature),
            <MyTooltip title={vllmmd.fields.temperature.tooltip} />,
          ],
          [
            vllmmd.fields.top_k.label,
            JSON.stringify(vllm_params.top_k),
            <MyTooltip title={vllmmd.fields.top_k.tooltip} />,
          ],
          [
            vllmmd.fields.top_p.label,
            JSON.stringify(vllm_params.top_p),
            <MyTooltip title={vllmmd.fields.top_p.tooltip} />,
          ],
          [
            vllmmd.fields.use_beam_search.label,
            JSON.stringify(vllm_params.use_beam_search),
            <MyTooltip title={vllmmd.fields.use_beam_search.tooltip} />,
          ],
        ]
      : []

  const [divHeight, setDivHeight] = useState(700)
  useEffect(() => {
    const updateHeight = () => {
      const screenHeight = window.innerHeight
      // Calculate the new height based on the ratio of 700/1080
      let newHeight = (900 / 1080) * screenHeight
      // Ensure the height is not less than 600px
      newHeight = Math.max(600, newHeight)
      setDivHeight(newHeight)
    }

    // Initial height calculation
    updateHeight()

    // Update height on window resize
    window.addEventListener('resize', updateHeight)
    return () => window.removeEventListener('resize', updateHeight)
  }, [])
  function logKeys(obj: Record<string, any>, parentKey: string = ''): void {
    Object.keys(obj).forEach((key) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key
      console.log(fullKey) // Log the full key path

      // if (typeof obj[key] === 'object' && obj[key] !== null) {
      //   logKeys(obj[key], fullKey) // Recursively log nested keys
      // }
    })
  }
  return (
    <>
      <Modal open={debugOpen} onCancel={() => setDebugOpen(false)}>
        <Debug debug objects={{ threadId, urlThreadId }} />
        <Debug debug objects={{ threads }} />
        <Debug debug objects={{ chats }} />
        <Debug debug objects={{ vllm_params }} />
      </Modal>
      {/* <LandingPage /> */}
      <ChatBage
        thread={thread}
        chatDelete={chatDelete}
        assistantId={assistantId}
        user={user}
        startChat={startChat}
      />
      <div ref={divRef} style={{ height: `${divHeight}px` }}>
        <ProChat
          chatRef={chatRef}
          showTitle={true}
          // backToBottomConfig={backBottomProps}
          chats={chats}
          displayMode="chat"
          userMeta={{
            avatar: '👨🏻',
            title: user?.username || 'User',
          }}
          assistantMeta={{
            avatar: '💁🏾',
            title: t('pages.assistants.chat.assistant.meta.title'),
          }}
          helloMessage={<div>{t('pages.assistants.chat.welcome')}</div>}
          request={async (messages, config, signal) => {
            console.log(messages)
            if (scrollToBottom) scrollToBottom()
            const message = messages[messages.length - 1]

            const content = message.content?.toString()

            if (!content) {
              return new Response("input can't be empty", {
                status: 400,
                statusText: 'not empty',
              })
            }

            await ask({
              runBody: {
                query: content,
              },
              threadId,
            })

            // return new Response('No response', {
            //   status: 200,
            //   statusText: 'success',
            // })
          }}
          genMessageId={async (messages, parentId) => {
            console.log(messages, parentId)
            return 'message-id'
          }}
          onResetMessage={async () => console.log('Messages reset')}
          placeholder={t('pages.assistants.chat.message.placeholder')}
          locale={'en-US'}
          chatItemRenderConfig={{
            actionsCallbacks: {
              beforeDelFinished: (id) => {
                console.log(id)
              },
              onEditFinished(id, value) {
                console.log(id, value)
              },
            },
            actionsRender: (
              {
                avatar,
                actions,
                avatarAddon,
                chatItemRenderConfig,
                className,
                editing,
                error,
                loading,
                markdownProps,
                message,
                messageExtra,
                onAvatarClick,
                onChange,
                onDoubleClick,
                onEditingChange,
                originData,
                placement,
                primary,
                renderErrorMessages,
                renderMessage,
                showTitle,
                text,
                time,
                type,
              },
              defaultDom,
            ) => {
              const initialId: string | undefined =
                originData && originData['id']
              const msgId = formatRunId(initialId)
              const isAssistant = originData
                ? originData['role'] === 'assistant'
                : false
              return (
                <>
                  {placement === 'left' && (
                    <>
                      <Actions
                        hasFeedback={originData?.extra?.feedback}
                        isLast={
                          chats.findIndex(
                            (chat) => chat.id === originData?.id,
                          ) >=
                          chats.length - 2
                        }
                        onRegenerateClick={async () => {
                          // const runId = formatRunId(originData?.id)
                          await regenerate()
                        }}
                        onCopyClick={() => {
                          if (originData?.content) {
                            navigator.clipboard.writeText(originData?.content)
                          }
                        }}
                        onFeedbackClick={async () => {
                          setOpen(true)
                          setMsgId(msgId)
                        }}
                      />
                    </>
                  )}
                </>
              )
            },
            contentRender: ({ editing, onEditingChange }, defaultDom) => {
              return <div>{defaultDom}</div>
            },
            titleRender: ({ avatar }, defaultDom) => {
              return (
                <div
                  className="layoutkit-flexbox ant-pro-chat-list-item-title"
                  style={{ fontSize: '12px', color: 'rgb(101 101 101' }}
                >
                  {avatar.title}
                </div>
              )
            },
            avatarRender: (
              { type, avatar, messageExtra, message, placement },
              defaultDom,
            ) => {
              if (placement === 'left') {
                return (
                  <img
                    style={{
                      width: '5%',
                      height: '5%',
                      maxWidth: '50px',
                      minWidth: '40px',
                    }}
                    src={assistantImg}
                  />
                )
              }
              return (
                <img
                  style={{
                    width: '5%',
                    height: '5%',
                    maxWidth: '50px',
                    minWidth: '40px',
                  }}
                  src={userImg}
                />
              )
            },
            // actionsCallbacks: {
            //   onEditFinished: (id, value) => {
            //     console.log(id, value)
            //   },
            // },
          }}
          actions={{
            render: (dd) => {
              return [<div key={'actions'}></div>]
            },
          }}
          loading={areRunsLoading}
          onChatEnd={(id, type) => console.log('Chat ended:', id, type)}
          onChatStart={(messages) => console.log('Chat started:', messages)}
          // onScroll={(e) => {
          //   console.log(e)
          // }}
          onChatGenerate={(chunkText) =>
            console.log('Chat generated:', chunkText)
          }
        />
        {feedbackChat && (
          <FeedbackForm
            open={open}
            chat={feedbackChat}
            setOpen={setOpen}
            response={feedbackChat.extra?.response}
            onSuccess={async (feedback) => {
              const updatedChats = chats.map((chat) =>
                formatRunId(chat.id) === feedback.run_id
                  ? {
                      ...chat,
                      extra: chat.extra
                        ? {
                            ...chat.extra,
                            feedback,
                          }
                        : undefined,
                    }
                  : chat,
              )
              setChats(updatedChats)
            }}
          />
        )}
        <BackBottom target={divRef} />
      </div>
    </>
  )
}

export default Chat
