import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import { AssistantsRoute } from '../../routes'

import AssistantDashboard from './components/AssistantDashboard'

interface Props {}

const { Title } = Typography

export default function AssistantsPage({}: Props) {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()

  const assistantId = searchParams.get('assistant')

  const { selectedBusiness } = useAppSelector(
    (state) => state.business.business,
  )
  const { user, accessToken } = useAppSelector((state) => state.auth)
  return (
    <Page
      title={t(AssistantsRoute.title)}
      styles={{ body: { padding: '0px' } }}
    >
      <Debug debug objects={{ user, accessToken }} />
      {selectedBusiness && (
        <AssistantDashboard
          selectedBusiness={selectedBusiness}
          assistantId={assistantId}
        />
      )}
    </Page>
  )
}
