import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type { FeedbackBody, FeedbackResponse } from '../../api/assistant/run'

export const getFeedbackModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<FeedbackBody & FeedbackResponse>(
    {
      assistant_id: {},
      thread_id: {},
      run_id: {},
      like: {},
      context: {},
      query: {},
      response: {},
      improvement: {},
      id: {},
    },
    t,
    'assistant.feedback',
  )
  return nd
}
