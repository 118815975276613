import type { FormItemProps } from 'antd'
import { Input as ANTDInput, Form } from 'antd'
import type { TextAreaProps } from 'antd/lib/input'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { FormField } from '../../utils/modelDescriptor/formFields'

interface Props {
  formField: FormField<any>
  textAreaProps?: TextAreaProps & { autoFocus?: boolean }
  itemProps?: FormItemProps
}

export default function FormTextArea({
  itemProps,
  formField,
  textAreaProps,
}: Props) {
  const { TextArea } = ANTDInput
  const { t } = useTranslation()
  const id = useId()
  return (
    <>
      <Form.Item
        id={id}
        name={formField?.name}
        rules={formField?.rules}
        // validateTrigger={formField?.rules !== undefined ? 'validateTrigger' : undefined}
        {...itemProps}
        label={
          <label htmlFor={id} style={{ fontWeight: 'bold' }}>
            {t(formField.label)}
          </label>
        }
      >
        <TextArea
          name={formField?.name}
          placeholder={t(formField.placeholder)}
          {...textAreaProps}
        />
      </Form.Item>
    </>
  )
}
