import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import type { TagProps } from 'antd'
import { Button, Col, Form, Popconfirm, Row, Tag } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import type { FormItemProps } from 'antd/lib'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams, Link as RouterLink } from 'react-router-dom'

import { useAppSelector } from '../../../app/hooks'
import type { IFormBody } from '../../../components/Form/helpers'
import FormInput from '../../../components/Form/Input'
import FormLanguageSelect from '../../../components/Form/LanguageSelect'
import FormTextArea from '../../../components/Form/TextArea'
import Debug from '../../../components/helpers/Debug'
import type {
  AssistantBody,
  AssistantGetManyApiResponse,
  AssistantResponse,
} from '../../../redux/api/assistant/assistant'
import {
  useAssistantCreateMutation,
  useAssistantDeleteMutation,
  useAssistantUpdateMutation,
} from '../../../redux/api/assistant/assistant'
import type { BusinessResponse } from '../../../redux/api/auth/auth'
import type { AssistantStatus } from '../../../redux/api/business/business'
import { getAssistantModelDescriptor } from '../../../redux/slices/assistant/assistant'
import { params, paths } from '../../../routes/paths'
import { formatDate } from '../../../utils/time'

import AssistantFileDashboard from './AssistantFileDashboard'
import AssistantList from './AssistantList'

const mapActiveColors: {
  [key in AssistantStatus]: TagProps['color']
} = {
  active: 'geekblue-inverse',
  created: 'success',
  deleted: 'error',
  failed: 'error',
  inactive: 'default',
  missing_payment: 'warning',
}

interface Props {
  businessId: string
  assistant: AssistantResponse
  selectedBusiness: BusinessResponse
  assistants: AssistantGetManyApiResponse | undefined
  onAssistantChange: (assistantId: string | undefined) => void
}

export default function AssistantUpdateForm({
  assistant,
  businessId,
  selectedBusiness,
  assistants,
  onAssistantChange,
}: Props) {
  const { t } = useTranslation()
  type FormBody = IFormBody<AssistantBody, never>
  const [form] = Form.useForm<FormBody>()
  const assistantMD = getAssistantModelDescriptor(t)
  const { user } = useAppSelector((state) => state.auth)
  const assistant_id = user?.assistant_id
  const [createAssistant] = useAssistantCreateMutation()
  const [
    deleteAssistant,
    { error: deleteError, isSuccess: isDeleteSuccess, isError: isDeleteError },
  ] = useAssistantDeleteMutation()
  const [submittable, setSubmittable] = useState<boolean>(true)
  const values = Form.useWatch([], form)

  const { id, created_at, status, updated_at, ...rest } = assistant

  const initialValues: AssistantBody = {
    business_id: businessId,
    ...rest,
  }

  useEffect(() => {
    if (values) {
      if (isEqual(values, initialValues)) {
        setSubmittable(false)
      } else {
        setSubmittable(true)
      }
    }
  }, [form, values])

  const [updateAssistant, { error, isSuccess }] = useAssistantUpdateMutation()
  const [confirmLoading, setConfirmLoading] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      assistantMD.toast.updated()
    }
  }, [isSuccess])

  useEffect(() => {
    if (error !== undefined) {
      console.log(error)
      assistantMD.handleErrors(form as any, error)
    }
  }, [error])

  useEffect(() => {
    form.setFieldsValue({
      business_id: businessId,
    })
  }, [businessId])

  useEffect(() => {
    if (assistant !== undefined) {
      form.setFieldsValue({
        ...assistant,
      })
    }
  }, [assistant])

  // delete
  useEffect(() => {
    if (isDeleteSuccess) {
      assistantMD.toast.deleted()
    }
  }, [isDeleteSuccess])

  useEffect(() => {
    if (isDeleteError) {
      assistantMD.toast.error()
    }
  }, [isDeleteError])
  //

  const handleOk = async () => {
    const { description, title, language, business_id, min_score } =
      form.getFieldsValue()
    setConfirmLoading(true)

    const {} = await updateAssistant({
      id: assistant.id,
      assistantBody: { business_id, description, title, language, min_score },
    })
    setConfirmLoading(false)
  }

  const [serverErrors, setServerErrors] = useState<{
    [key in keyof AssistantBody]?: string
  }>({})

  const itemProps = (label: keyof AssistantBody): FormItemProps => ({
    rules: assistantMD.fields[label].rules,
    help: serverErrors[label],
    validateStatus: serverErrors[label] ? 'error' : '',
  })
  const [searchParams, setSearchParams] = useSearchParams()

  const submit = () => {
    form.submit()
  }

  return (
    <>
      <Content style={{ overflowY: 'auto' }}>
        <Debug debug objects={{ assistant }} />

        <Form<AssistantBody>
          form={form}
          labelCol={{ span: 24 }}
          onFinish={handleOk}
          initialValues={initialValues}
        >
          {/* businessId */}
          <Form.Item name={assistantMD.fields.business_id.name} hidden />
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              {/* thread_count */}
              <p>Number of threads: {assistant.thread_count}</p>
            </Col>
            <Col span={24}>
              {/* title */}
              <FormInput
                formField={assistantMD.fields.title}
                itemProps={{
                  ...itemProps('title'),
                }}
                inputProps={{
                  onBlur: submit,
                }}
              />
            </Col>
            <Col span={24}>
              {/* min_score */}
              <FormInput
                formField={assistantMD.fields.min_score}
                inputProps={{
                  defaultValue: 1.4,
                  onBlur: submit,
                }}
                itemProps={{
                  ...itemProps('min_score'),
                }}
              />
            </Col>
            <Col span={24}>
              {/* max_docs */}
              <FormInput
                formField={assistantMD.fields.max_docs}
                inputProps={{
                  defaultValue: 5,
                  onBlur: submit,
                }}
                itemProps={{
                  ...itemProps('max_docs'),
                }}
              />
            </Col>
            <Col span={24}>
              {/* language */}
              <FormLanguageSelect
                formField={assistantMD.fields.language}
                style={{
                  width: '100%',
                  alignItems: 'left',
                  textAlign: 'left',
                }}
                onBlur={submit}
                dropdownStyle={{}}
                itemProps={{
                  ...itemProps('language'),
                }}
              />
            </Col>
            <Col span={24}>
              {/* description */}
              <FormTextArea
                formField={assistantMD.fields.description}
                textAreaProps={{
                  onBlur: submit,
                }}
                itemProps={{
                  ...itemProps('description'),
                }}
              />
            </Col>
            <Col span={18}>
              {/* files */}
              <AssistantFileDashboard
                businessId={businessId}
                assistantId={assistant.id}
              />
            </Col>
            <Col span={6}>
              {assistant_id && (
                <RouterLink
                  to={paths.userChat
                    .replace(params.assistant_id, assistant_id)
                    .replace(params.thread_id, '')}
                  style={{ textDecoration: 'none' }}
                >
                  <Button>{t('assistant.assistant.form.actions.user')}</Button>
                </RouterLink>
              )}
            </Col>
          </Row>
        </Form>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
          margin: 0,
          paddingInline: 0,
        }}
      >
        {assistant && (
          <Row
            style={{
              padding: 0,
              margin: 0,
            }}
          >
            <Col span={2}>
              <Popconfirm
                title={assistantMD.form.action.delete()}
                description={assistantMD.pop.delete.question({
                  id: assistant.id.toString(),
                  title: assistant.title,
                })}
                overlayStyle={{
                  maxWidth: '300px',
                }}
                onConfirm={async () => {
                  await deleteAssistant({ id: assistant.id })
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Col>
            <Col span={2} />
            {/* updated_at */}
            <Col span={14}>
              <Tag>
                {`${assistantMD.fields.updated_at.label}: ${formatDate(assistant.updated_at, 'MM/DD, h:mm A')}`}
              </Tag>
            </Col>
            {/* status */}
            <Col span={6}>
              <Tag color={mapActiveColors[assistant.status]}>
                {`${assistantMD.fields.status.label} ${t(
                  `${assistantMD.fields.status.fieldDomain}.options.${assistant.status}`,
                )}`}
              </Tag>
            </Col>
          </Row>
        )}
      </Footer>
    </>
  )
}
