import { Card, Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography
function FeaturesHighlight() {
  const { t } = useTranslation()

  const features = [
    {
      icon: '📝',
      title: t('pages.landing.features.features.content.title'),
      description: t('pages.landing.features.features.content.description'),
    },
    {
      icon: '🎯',
      title: t('pages.landing.features.features.assessment.title'),
      description: t('pages.landing.features.features.assessment.title'),
    },
    {
      icon: '⏰',
      title: t('pages.landing.features.features.timeSaving.title'),
      description: t('pages.landing.features.features.timeSaving.title'),
    },
  ]
  return (
    <section
      id="features"
      style={{
        padding: '5rem 0', // Approx. py-20
        backgroundColor: '#f9fafb', // Similar to bg-gray-50
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 1.5rem', // Approx. px-6
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
          <Title
            level={2}
            style={{
              color: 'var(--violet)',
              fontWeight: 'bold',
              fontSize: '1.875rem',
            }}
          >
            {t('pages.landing.features.title')}
          </Title>
          <Paragraph style={{ color: '#4B5563', marginTop: '1rem' }}>
            {t('pages.landing.features.description')}
          </Paragraph>
        </div>

        <Row gutter={[24, 32]}>
          {features.map((feature, index) => (
            <Col key={index} xs={24} md={8}>
              <Card
                style={{
                  textAlign: 'center',
                  borderRadius: '0.5rem',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                  border: 'none',
                  padding: '1.5rem',
                }}
                bodyStyle={{ padding: 0 }}
              >
                <div style={{ fontSize: '3rem', marginBottom: '1rem' }}>
                  {feature.icon}
                </div>
                <Title
                  level={3}
                  style={{
                    color: 'var(--violet)',
                    fontWeight: '600',
                    fontSize: '1.25rem',
                    marginBottom: '0.5rem',
                  }}
                >
                  {feature.title}
                </Title>
                <Paragraph style={{ color: '#4B5563' }}>
                  {feature.description}
                </Paragraph>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  )
}

export default FeaturesHighlight
