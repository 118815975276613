// ----------------------------------------------------------------------

import { GlobalDebug } from '../../config'

import { ExpandWrapper } from './ExpandWrapper'
// ----------------------------------------------------------------------

export default function Debug({
  debug = false,
  objects,
  ...props
}: {
  objects: any
  debug?: boolean
}) {
  return (
    <>
      {debug && GlobalDebug ? (
        <div {...props}>
          {ExpandWrapper({
            content: <pre>{JSON.stringify(objects, null, 2)}</pre>,
            label: <>{JSON.stringify(Object.keys(objects))}</>,
          })}
        </div>
      ) : null}
    </>
  )
}
