import { Form, Select } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { FormItemProps, SelectProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { LanguageCode } from '../../redux/api/assistant/assistant'
import type { FormField } from '../../utils/modelDescriptor/formFields'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
} & SelectProps

export default function FormLanguageSelect({
  itemProps,
  formField,
  ...props
}: Props) {
  const id = useId()
  const { t } = useTranslation()
  type RequiredOptions = {
    [K in LanguageCode]: string
  }
  const mop: RequiredOptions = {
    English: t('components.language_selector.options.English'),
    French: t('components.language_selector.options.French'),
    German: t('components.language_selector.options.German'),
    Kazakh: t('components.language_selector.options.Kazakh'),
    Portuguese: t('components.language_selector.options.Portuguese'),
    Russian: t('components.language_selector.options.Russian'),
    Spanish: t('components.language_selector.options.Spanish'),
    Turkish: t('components.language_selector.options.Turkish'),
  }

  const options2: DefaultOptionType[] = Object.entries(mop).map(
    ([value, label]) => ({
      label,
      value,
    }),
  )
  return (
    <Form.Item
      id={id}
      name={formField?.name}
      rules={formField?.rules}
      hasFeedback={formField?.rules !== undefined}
      // validateTrigger={formField?.rules !== undefined ? 'validateTrigger' : undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <Select
        {...props}
        placeholder={t(formField.placeholder)}
        allowClear
        options={options2}
      />
    </Form.Item>
  )
}
