import { Button, Col, Form, Input, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography
const { TextArea } = Input
function ContactSection() {
  const { t } = useTranslation()
  return (
    <section
      id="contact"
      style={{
        padding: '5rem 0',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 1.5rem',
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
          <Title
            level={2}
            style={{
              fontWeight: 'bold',
              fontSize: '1.875rem',
              color: 'var(--violet)',
              marginBottom: 0,
            }}
          >
            {t('pages.landing.contact.title')}
          </Title>
          <Paragraph style={{ color: '#4B5563', marginTop: '1rem' }}>
            {t('pages.landing.contact.description')}
          </Paragraph>
        </div>

        <Row gutter={[24, 32]}>
          <Col xs={24} md={16}>
            <div
              style={{
                background: '#fff',
                padding: '2rem',
                borderRadius: '0.5rem',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              }}
            >
              <Form layout="vertical">
                <Form.Item
                  name="name"
                  rules={[
                    { required: true, message: 'Please enter your name' },
                  ]}
                >
                  <Input
                    placeholder="Your Name"
                    style={{ padding: '1rem', borderRadius: '0.375rem' }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email',
                    },
                  ]}
                >
                  <Input
                    placeholder="Your Email"
                    style={{ padding: '1rem', borderRadius: '0.375rem' }}
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[
                    { required: true, message: 'Please enter your message' },
                  ]}
                >
                  <TextArea
                    placeholder="Your Message"
                    style={{
                      padding: '1rem',
                      borderRadius: '0.375rem',
                      height: '8rem',
                    }}
                  />
                </Form.Item>
                <Button
                  type="primary"
                  style={{
                    background: 'var(--violet)',
                    border: 'none',
                    borderRadius: '0.375rem',
                    padding: '0.5rem 1.5rem',
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.background = '#7C3AED')
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.background = 'var(--violet)')
                  }
                >
                  {t('pages.landing.contact.action')}
                </Button>
              </Form>
            </div>
          </Col>
          <Col xs={24} md={8}>
            <div
              style={{
                background: '#f9fafb',
                padding: '2rem',
                borderRadius: '0.5rem',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
              }}
            >
              <Title
                level={3}
                style={{
                  fontWeight: '600',
                  fontSize: '1.25rem',
                  color: 'var(--violet)',
                  marginBottom: '1rem',
                }}
              >
                {t('pages.landing.contact.info.title')}
              </Title>
              <Paragraph style={{ color: '#374151', marginBottom: '0.5rem' }}>
                {t('pages.landing.contact.info.email')}
              </Paragraph>
              <Paragraph style={{ color: '#374151', marginBottom: '0.5rem' }}>
                {t('pages.landing.contact.info.phone')}
              </Paragraph>
              <Paragraph style={{ color: '#374151', marginBottom: 0 }}>
                {t('pages.landing.contact.info.address')}
              </Paragraph>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default ContactSection
