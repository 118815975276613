import { DislikeOutlined, LikeOutlined } from '@ant-design/icons'
import { Button, Space, Tooltip } from 'antd'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { getFeedbackModelDescriptor } from '../../../redux/slices/assistant/feedback'

type tValue = boolean | undefined

type Props = {
  value?: tValue
  onChange?: Dispatch<SetStateAction<tValue>>
}
export default function LikeDislikeComponent({ value, onChange }: Props) {
  const handleLike = () => {
    if (onChange) onChange(value === true ? undefined : true)
  }
  const { t } = useTranslation()
  const fmd = getFeedbackModelDescriptor(t)

  const handleDislike = () => {
    if (onChange) onChange(value === false ? undefined : false)
  }

  return (
    <Space>
      <Tooltip placement="topLeft" title={fmd.fields.like.placeholder}>
        <Button
          type={value === true ? 'primary' : 'default'}
          icon={<LikeOutlined />}
          onClick={handleLike}
        >
          {fmd.fields.like.label}
        </Button>
      </Tooltip>
      <Tooltip
        placement="topLeft"
        title={t('assistant.feedback.fields.dislike.placeholder')}
      >
        <Button
          type={value === false ? 'primary' : 'default'}
          icon={<DislikeOutlined />}
          onClick={handleDislike}
        >
          {t('assistant.feedback.fields.dislike.label')}
        </Button>
      </Tooltip>
    </Space>
  )
}
