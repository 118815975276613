import { assistantApi as api } from './index'
export const addTagTypes = ['run'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      runGetMany: build.query<RunGetManyApiResponse, RunGetManyApiArg>({
        query: (queryArg) => ({
          url: `/assistant/run/${queryArg.assistantId}/${queryArg.threadId}/`,
        }),
        providesTags: ['run'],
      }),
      runCreate: build.mutation<RunCreateApiResponse, RunCreateApiArg>({
        query: (queryArg) => ({
          url: `/assistant/run/${queryArg.assistantId}/${queryArg.threadId}/`,
          method: 'POST',
          body: queryArg.runBody,
        }),
        invalidatesTags: ['run'],
      }),
      runStream: build.mutation<RunStreamApiResponse, RunStreamApiArg>({
        query: (queryArg) => ({
          url: `/assistant/run/${queryArg.assistantId}/${queryArg.threadId}/stream`,
          method: 'POST',
          body: queryArg.runBody,
        }),
        invalidatesTags: ['run'],
      }),
      runRegenerate: build.mutation<
        RunRegenerateApiResponse,
        RunRegenerateApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/run/${queryArg.assistantId}/${queryArg.threadId}/${queryArg.runId}/regenerate`,
          method: 'POST',
        }),
        invalidatesTags: ['run'],
      }),
      runCreateFeedback: build.mutation<
        RunCreateFeedbackApiResponse,
        RunCreateFeedbackApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/run/${queryArg.assistantId}/${queryArg.threadId}/${queryArg.runId}/feedback`,
          method: 'POST',
          body: queryArg.feedbackBody,
        }),
        invalidatesTags: ['run'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as runApi }
export type RunGetManyApiResponse =
  /** status 200 Successful Response */ RunResponse[]
export type RunGetManyApiArg = {
  assistantId: string
  threadId: string
}
export type RunCreateApiResponse =
  /** status 200 Successful Response */ RunResponse
export type RunCreateApiArg = {
  assistantId: string
  threadId: string
  runBody: RunBody
}
export type RunStreamApiResponse =
  /** status 200 Successful Response */ RunResponse
export type RunStreamApiArg = {
  assistantId: string
  threadId: string
  runBody: RunBody
}
export type RunRegenerateApiResponse =
  /** status 200 Successful Response */ RunResponse
export type RunRegenerateApiArg = {
  assistantId: string
  threadId: string
  runId: string
}
export type RunCreateFeedbackApiResponse =
  /** status 200 Successful Response */ FeedbackResponse
export type RunCreateFeedbackApiArg = {
  assistantId: string
  threadId: string
  runId: string
  feedbackBody: FeedbackBody
}
export type RunStatus = 'COMPLETED' | 'IN_PROGRESS' | 'FAILED'
export type FeedbackResponse = {
  id: string
  assistant_id: string
  thread_id: string
  run_id: string
  /** Like or dislike */
  like?: boolean | null
  /** RAG(or other) context sent to the model */
  context?: string | null
  /** User's input message */
  query: string
  /** Assistant's response message */
  response: string
  /** Correction suggested by the user */
  improvement?: string | null
}
export type VllmParams = {
  /** Max number of tokens generated by the model */
  max_tokens?: number
  /** Model temperature */
  temperature?: number
  /** Float that controls the cumulative probability of the top tokens to consider. Must be in (0, 1]. Set to 1 to consider all tokens. */
  top_p?: number
  /** Integer that controls the number of top tokens to consider. Set to -1 to consider all tokens. */
  top_k?: number
  /** Penalizes new tokens based on their presence in generated text */
  presence_penalty?: number
  /** Penalizes new tokens based on their frequency in generated text */
  frequency_penalty?: number
  /** Penalizes new tokens based on their repetition in generated text */
  repetition_penalty?: number
  /** Whether to use beam search instead of sampling */
  use_beam_search?: boolean
  /** Penalizes sequences based on length in beam search */
  length_penalty?: number
  /** Controls beam search stopping condition */
  early_stopping?: boolean
  /** Number of output sequences that are generated from the prompt. From these best_of sequences, the top n sequences are returned. best_of must be greater than or equal to n. This is treated as the beam width when use_beam_search is True. By default, best_of is set to n. */
  best_of?: number
  /** Controls how many runs to pass */
  max_memory?: number
}
export type RunResponse = {
  id: string
  /** User's input message */
  assistant_id: string
  /** User's input message */
  thread_id: string
  /** Identifier for tracking run sequence */
  last_run_id?: string | null
  /** Current status of the run */
  status: RunStatus
  /** User's input message */
  query: string
  /** Assistant's response message */
  response?: string | null
  /** Feedback response body */
  feedback?: FeedbackResponse | null
  old_runs?: RunResponse[] | null
  vllm_params: VllmParams
  created_at: string
  updated_at: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type RunBody = {
  /** User's input message */
  query: string
  vllm_params: VllmParams
}
export type FeedbackBody = {
  /** Like or dislike */
  like?: boolean | null
  /** Correction suggested by the user */
  improvement?: string | null
}
export const {
  useRunGetManyQuery,
  useLazyRunGetManyQuery,
  useRunCreateMutation,
  useRunStreamMutation,
  useRunRegenerateMutation,
  useRunCreateFeedbackMutation,
} = injectedRtkApi
