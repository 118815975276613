import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Space } from 'antd'
import type { MenuProps } from 'antd/lib'
import type { CSSProperties } from 'react'
import { useState } from 'react'

import Iconify from '../../../../components/Iconify'

type Props = {
  onFeedbackClick: () => Promise<void>
  onRegenerateClick: () => Promise<void>
  onCopyClick: () => void
  hasFeedback: boolean
  isLast: boolean
}

export default function Actions({
  onFeedbackClick,
  onRegenerateClick,
  hasFeedback,
  onCopyClick,
  isLast,
}: Props) {
  const [copied, setCopied] = useState(false)
  const iconifyStyle: CSSProperties = {
    cursor: 'pointer',
    color: 'gray',
    width: '30px',
  }
  const padding = '5px'
  const buttonStyle: CSSProperties = { padding: 0 }
  return (
    <>
      <Space
        direction="vertical"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.02)',
          columnGap: padding,
          border: '1px solid #d9d9d9',
          paddingInline: padding,
          paddingTop: padding,
          paddingBottom: padding,
          borderRadius: '5px',
        }}
      >
        <Button
          size="small"
          type="text"
          style={buttonStyle}
          onClick={onFeedbackClick}
        >
          <Iconify
            icon={
              hasFeedback
                ? 'material-symbols-light:add-comment'
                : 'material-symbols-light:add-comment-outline'
            }
            style={iconifyStyle}
            fontSize={25}
          />
        </Button>
        {isLast && (
          <Button
            size="small"
            type="text"
            style={buttonStyle}
            onClick={onRegenerateClick}
          >
            <Iconify
              icon={'material-symbols-light:refresh'}
              style={iconifyStyle}
              fontSize={25}
            />
          </Button>
        )}
        <Button
          type="text"
          size="small"
          style={buttonStyle}
          onClick={() => {
            onCopyClick()
            setCopied(!copied)
          }}
        >
          <Iconify
            icon={
              copied
                ? 'material-symbols-light:check-box-outline'
                : 'material-symbols-light:content-copy-outline'
            }
            style={iconifyStyle}
            fontSize={25}
          />
        </Button>
        {/* <Dropdown
          menu={{
            items: [
              {
                label: 'Submit and continue',
                key: '1',
              },
              {
                label: 'Submit and continue',
                key: '2',
              },
            ],
          }}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Iconify
              icon={'material-symbols-light:more-horiz'}
              style={{
                cursor: 'pointer',
                flexShrink: 0,
              }}
              fontSize={25}
            />
          </a>
        </Dropdown> */}
      </Space>
    </>
  )
}
