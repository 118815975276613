import { Button, Col, Input, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography

function Footer() {
  const { t } = useTranslation()
  return (
    <footer
      style={{
        background: 'var(--violet)',
        color: 'white',
        padding: '2rem 0',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 1.5rem',
        }}
      >
        <Row gutter={[24, 24]} justify="space-between">
          <Col xs={24} md={8}>
            <Title
              level={3}
              style={{
                fontWeight: '600',
                fontSize: '1.25rem',
                marginBottom: '0.5rem',
                color: 'white',
              }}
            >
              {t('pages.landing.footer.quickLinks.title')}
            </Title>
            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
              {(
                t('pages.landing.footer.quickLinks.links', {
                  returnObjects: true,
                }) as string[]
              ).map((link) => (
                <li key={link} style={{ marginBottom: '0.5rem' }}>
                  <a
                    href={`#${link.toLowerCase().replace(' ', '')}`}
                    style={{ color: 'white', textDecoration: 'none' }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.color = 'orange')
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.color = 'white')
                    }
                  >
                    {link}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
          <Col xs={24} md={8}>
            <Title
              level={3}
              style={{
                fontWeight: '600',
                fontSize: '1.25rem',
                marginBottom: '0.5rem',
                color: 'white',
              }}
            >
              {t('pages.landing.footer.follow')}
            </Title>
            <div style={{ display: 'flex', gap: '1rem' }}>
              {['Facebook', 'Twitter', 'Instagram', 'LinkedIn'].map(
                (platform) => (
                  <a
                    key={platform}
                    href="#"
                    style={{ color: 'white', textDecoration: 'none' }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.color = 'orange')
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.color = 'white')
                    }
                  >
                    {platform}
                  </a>
                ),
              )}
            </div>
          </Col>
          <Col xs={24} md={8}>
            <Title
              level={3}
              style={{
                fontWeight: '600',
                fontSize: '1.25rem',
                marginBottom: '0.5rem',
                color: 'white',
              }}
            >
              {t('pages.landing.footer.newslater')}
            </Title>
            <Paragraph style={{ marginBottom: '1rem' }}>
              {t('pages.landing.footer.subscribe')}
            </Paragraph>
            <div style={{ display: 'flex' }}>
              <Input
                type="email"
                placeholder="Your Email"
                style={{
                  borderTopLeftRadius: '0.375rem',
                  borderBottomLeftRadius: '0.375rem',
                  marginRight: '-1px', // To avoid double border
                }}
              />
              <Button
                style={{
                  background: 'orange',
                  color: 'white',
                  border: 'none',
                  borderTopRightRadius: '0.375rem',
                  borderBottomRightRadius: '0.375rem',
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.background = '#EA580C')
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.background = 'orange')
                }
              >
                {t('pages.landing.footer.action')}
              </Button>
            </div>
          </Col>
        </Row>

        <div
          style={{
            marginTop: '2rem',
            borderTop: '1px solid white',
            paddingTop: '1rem',
            textAlign: 'center',
          }}
        >
          <div style={{ marginTop: '0.5rem' }}>
            <a
              href="#"
              style={{
                color: 'white',
                margin: '0 0.5rem',
                textDecoration: 'none',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.color = 'orange')}
              onMouseLeave={(e) => (e.currentTarget.style.color = 'white')}
            >
              {t('pages.landing.footer.privacy')}
            </a>
            <a
              href="#"
              style={{
                color: 'white',
                margin: '0 0.5rem',
                textDecoration: 'none',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.color = 'orange')}
              onMouseLeave={(e) => (e.currentTarget.style.color = 'white')}
            >
              {t('pages.landing.footer.tos')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
