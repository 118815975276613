import BlogSection from './components/components/BlogSection'
import CollaborationSection from './components/components/CollaborationSection'
import ContactSection from './components/components/ContactSection'
import FeaturesHighlight from './components/components/FeaturesHighlight'
import Footer from './components/components/Footer'
import HeroSection from './components/components/HeroSection'
import PromotionalBanner from './components/components/PromotionalBanner'
import SubscriptionPlans from './components/components/SubscriptionPlans'

function App() {
  return (
    <div>
      <HeroSection />
      <FeaturesHighlight />
      <SubscriptionPlans />
      <BlogSection />
      <PromotionalBanner />
      <CollaborationSection />
      <ContactSection />
      <Footer />
    </div>
  )
}

export default App
