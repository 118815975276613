import { authApi as api } from './index'
export const addTagTypes = ['/auth'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({
          url: `/auth/auth/login`,
          method: 'POST',
          body: queryArg.bodyLoginAuthAuthLoginPost,
        }),
        invalidatesTags: ['/auth'],
      }),
      refreshToken: build.mutation<RefreshTokenApiResponse, RefreshTokenApiArg>(
        {
          query: (queryArg) => ({
            url: `/auth/auth/refresh-token`,
            method: 'POST',
            body: queryArg.refreshTokenRequest,
          }),
          invalidatesTags: ['/auth'],
        }
      ),
      signUp: build.mutation<SignUpApiResponse, SignUpApiArg>({
        query: (queryArg) => ({
          url: `/auth/auth/sign-up`,
          method: 'POST',
          body: queryArg.signUpUserBody,
        }),
        invalidatesTags: ['/auth'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as authApi }
export type LoginApiResponse =
  /** status 200 Successful Response */ LoginResponse
export type LoginApiArg = {
  bodyLoginAuthAuthLoginPost: BodyLoginAuthAuthLoginPost
}
export type RefreshTokenApiResponse =
  /** status 200 Successful Response */ LoginResponse
export type RefreshTokenApiArg = {
  refreshTokenRequest: RefreshTokenRequest
}
export type SignUpApiResponse =
  /** status 201 Successful Response */ UserResponse
export type SignUpApiArg = {
  signUpUserBody: SignUpUserBody
}
export type IndustryEnum =
  | 'Technology'
  | 'Finance'
  | 'Healthcare'
  | 'Education'
  | 'Retail'
  | 'Hospitality'
  | 'Sport'
  | 'Other'
export type CountryResponse = {
  id: number
  /** Country name */
  name: string
}
export type BusinessResponse = {
  id: string
  /** Business name */
  name: string
  /** Brief description of the business */
  description: string | null
  /** Brief description of the business */
  ein: string | null
  /** Brief description of the business */
  address: string | null
  industry: IndustryEnum | null
  /** Country details */
  country?: CountryResponse | null
}
export type RoleResponse = {
  id: number
  name: string
}
export type UserAssistantResponse = {
  id: string
  title: string
}
export type UserResponse = {
  id: string
  email: string
  is_admin: boolean
  is_activated: boolean
  tenant?: string | null
  username: string
  assistant_id?: string | null
  /** List of businesses associated with the user, can be empty. */
  businesses: BusinessResponse[]
  /** List of roles associated with the user */
  roles: RoleResponse[]
  /** List of assistants the user can interact with */
  user_assistants: UserAssistantResponse[]
}
export type LoginResponse = {
  token_type?: string
  access_token: string
  expires_at: number
  refresh_token: string
  refresh_token_expires_at: number
  user: UserResponse
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type BodyLoginAuthAuthLoginPost = {
  grant_type?: string | null
  username: string
  password: string
  scope?: string
  client_id?: string | null
  client_secret?: string | null
}
export type RefreshTokenRequest = {
  refresh_token: string
}
export type SignUpUserBody = {
  email: string
  password: string
  username: string
  tenant?: string | null
}
export const { useLoginMutation, useRefreshTokenMutation, useSignUpMutation } =
  injectedRtkApi
