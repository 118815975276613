// ----------------------------------------------------------------------
import { Divider, Layout } from 'antd'
import { Content, Header } from 'antd/es/layout/layout'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useSearchParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import { useLazyUserGetMeQuery } from '../../redux/api/auth/user'
import { updateSearchParams } from '../../utils/router'

// ----------------------------------------------------------------------

type Props = {
  bar?: ReactNode
  sider?: ReactNode
}

export default function MainLayout({ sider, bar }: Props) {
  const { accessToken, user } = useAppSelector((state) => state.auth)
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()
  const [getMe] = useLazyUserGetMeQuery()

  const fetchMe = async () => {
    await getMe()
  }

  useEffect(() => {
    // reset page to 1 if in URL params
    const page = searchParams.get('page')
    if (page) {
      updateSearchParams(
        {
          page: 1,
        },
        searchParams,
        setSearchParams,
      )
    }
  }, [])

  useEffect(() => {
    if (user === null && accessToken !== null) {
      fetchMe()
    }
  }, [accessToken])

  return (
    <>
      <Layout
        // className="Page"
        style={{
          flex: '1 1 auto',
          overflowY: 'auto',
        }}
      >
        {bar && (
          <>
            <Divider
              style={{
                margin: 0,
                padding: 0,
              }}
            />
            {bar}
          </>
        )}
        <Layout
          style={{
            // height: '100vh',
            // position: 'relative',
            // display: 'flex',
            overflow: 'hidden',
          }}
        >
          {sider}
          <Content>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  )
}
