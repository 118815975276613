import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  BusinessBody,
  BusinessResponse,
} from '../../api/business/business'
// ----------------------------------------------------------------------

export interface BusinessState {
  selectedBusiness?: BusinessResponse
}

const initialState: BusinessState = {
  selectedBusiness: undefined,
}

export const businessSlice = createSlice({
  name: 'business/business',
  initialState,
  reducers: {
    setBusiness: (state, action: PayloadAction<Partial<BusinessState>>) => {
      state.selectedBusiness = action.payload.selectedBusiness
    },
  },
})

export const getBusinessModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<BusinessBody>(
    {
      address: {},
      country_id: {},
      description: {},
      ein: {},
      industry: {},
      name: {},
    },
    t,
    'business.business',
  )
  return nd
}

export const { setBusiness } = businessSlice.actions
export default businessSlice.reducer
