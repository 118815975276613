import type { ThemeConfig } from 'antd'

export const light = '#f5f5f5'
export const dark = '#000000'
export const orange = '#FF9800'
export const gray = '#1a1a1c'
export const green = 'rgb(7 163 153)'

export const lightThemeConfig: ThemeConfig = {
  // algorithm: theme.darkAlgorithm,
  components: {
    Layout: {
      headerBg: light,
      siderBg: light,
    },
    Menu: {
      itemBg: light,
    },
    Button: {
      // defaultBg: orange,
      // colorPrimary: orange,
    },
  },
  token: {
    colorPrimary: orange,
    // fontFamily: 'Roboto',
  },
}

export const darkThemeConfig: ThemeConfig = {
  // algorithm: theme.darkAlgorithm,
  components: {
    Layout: {
      headerBg: dark,
      siderBg: dark,
    },
    Menu: {
      itemBg: dark,
    },
  },
  token: {
    // colorPrimary: '#000000',
    // fontFamily: 'Roboto',
  },
}
