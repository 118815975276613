import { businessApi as api } from './index'
export const addTagTypes = ['business'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      businessCreate: build.mutation<
        BusinessCreateApiResponse,
        BusinessCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business/`,
          method: 'POST',
          body: queryArg.businessBody,
        }),
        invalidatesTags: ['business'],
      }),
      businessGetMany: build.query<
        BusinessGetManyApiResponse,
        BusinessGetManyApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business/`,
          params: { user_id: queryArg.userId },
        }),
        providesTags: ['business'],
      }),
      businessRetrieve: build.query<
        BusinessRetrieveApiResponse,
        BusinessRetrieveApiArg
      >({
        query: (queryArg) => ({ url: `/business/business/${queryArg.id}` }),
        providesTags: ['business'],
      }),
      businessUpdate: build.mutation<
        BusinessUpdateApiResponse,
        BusinessUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business/${queryArg.id}/`,
          method: 'PUT',
          body: queryArg.businessBody,
        }),
        invalidatesTags: ['business'],
      }),
      businessGetAssistants: build.query<
        BusinessGetAssistantsApiResponse,
        BusinessGetAssistantsApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business/${queryArg.id}/assistants`,
        }),
        providesTags: ['business'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as businessApi }
export type BusinessCreateApiResponse =
  /** status 201 Successful Response */ BusinessResponse
export type BusinessCreateApiArg = {
  businessBody: BusinessBody
}
export type BusinessGetManyApiResponse =
  /** status 200 Successful Response */ BusinessResponse[]
export type BusinessGetManyApiArg = {
  /** UUID of the user to get businesses for */
  userId?: string
}
export type BusinessRetrieveApiResponse =
  /** status 200 Successful Response */ BusinessResponse
export type BusinessRetrieveApiArg = {
  id: string
}
export type BusinessUpdateApiResponse =
  /** status 201 Successful Response */ BusinessResponse
export type BusinessUpdateApiArg = {
  id: string
  businessBody: BusinessBody
}
export type BusinessGetAssistantsApiResponse =
  /** status 200 Successful Response */ AssistantResponse[]
export type BusinessGetAssistantsApiArg = {
  id: string
}
export type IndustryEnum =
  | 'Technology'
  | 'Finance'
  | 'Healthcare'
  | 'Education'
  | 'Retail'
  | 'Hospitality'
  | 'Sport'
  | 'Other'
export type CountryResponse = {
  id: number
  /** Country name */
  name: string
}
export type BusinessResponse = {
  id: string
  /** Business name */
  name: string
  /** Brief description of the business */
  description: string | null
  /** Brief description of the business */
  ein: string | null
  /** Brief description of the business */
  address: string | null
  industry: IndustryEnum | null
  /** Country details */
  country?: CountryResponse | null
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type BusinessBody = {
  name: string
  description?: string | null
  industry?: IndustryEnum | null
  /** Country ID */
  country_id?: number | null
  address?: string | null
  ein?: string | null
}
export type LanguageCode =
  | 'Kazakh'
  | 'Russian'
  | 'English'
  | 'Turkish'
  | 'Spanish'
  | 'Portuguese'
  | 'German'
  | 'French'
export type AssistantStatus =
  | 'created'
  | 'active'
  | 'inactive'
  | 'missing_payment'
  | 'deleted'
  | 'failed'
export type AssistantResponse = {
  id: string
  title: string
  min_score: number | null
  max_docs: number
  description: string | null
  language: LanguageCode
  status: AssistantStatus
  created_at: string
  updated_at: string
  thread_count: number
}
export const {
  useBusinessCreateMutation,
  useBusinessGetManyQuery,
  useLazyBusinessGetManyQuery,
  useBusinessRetrieveQuery,
  useLazyBusinessRetrieveQuery,
  useBusinessUpdateMutation,
  useBusinessGetAssistantsQuery,
  useLazyBusinessGetAssistantsQuery,
} = injectedRtkApi
