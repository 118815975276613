import { useAppSelector } from '../app/hooks'
import type { tRole } from '../redux/slices/mainSlice'

export default function useHasPermission(
  role?: tRole | tRole[],
): boolean | undefined {
  const { user } = useAppSelector((state) => state.auth)
  const userRoles = user?.roles.map((userGroup) => userGroup.name) as
    | tRole[]
    | undefined
  if (typeof role === 'string') {
    return userRoles?.includes(role || 'admin')
  }

  if (typeof role === 'object') {
    return role?.some((r) => userRoles?.includes(r))
  }
  return true
}
