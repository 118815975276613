import type { TFunction, TOptions } from 'i18next'

export class TitleDescriptor {
  domain: string
  t: TFunction
  constructor(domain: string, t: TFunction) {
    this.domain = domain
    this.t = t
  }

  update(options?: TOptions) {
    return this.t(`${this.domain}.title.update`, options)
  }

  create(options?: TOptions) {
    return this.t(`${this.domain}.title.create`, options)
  }
}
