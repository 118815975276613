import assistant from './ru-RU/assistant.json'
import assistant_file from './ru-RU/assistant_file.json'
import business from './ru-RU/business.json'
import business_file from './ru-RU/business_file.json'
import feedback from './ru-RU/feedback.json'
import footer from './ru-RU/footer.json'
import form from './ru-RU/form.json'
import language_selector from './ru-RU/language_selector.json'
import layouts from './ru-RU/layouts.json'
import navbar from './ru-RU/navbar.json'
import order from './ru-RU/order.json'
import pages from './ru-RU/pages.json'
import select from './ru-RU/select.json'
import teacherDiscipline from './ru-RU/teacherDiscipline.json'
import thread from './ru-RU/thread'
import user from './ru-RU/user.json'
import vllm_params from './ru-RU/vllm_params.json'

export default {
  trademark: 'ProntoEdu',
  business: {
    business,
    business_file,
  },
  edu: {
    teacherDiscipline,
    order,
  },
  assistant: {
    assistant,
    assistant_file,
    thread,
    feedback,
    vllm_params,
  },
  auth: {
    user,
  },
  components: {
    footer,
    form,
    language_selector,
    navbar,
    select,
  },
  layouts,
  pages,
}
