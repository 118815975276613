import { UploadOutlined } from '@ant-design/icons'
import { Button, Upload } from 'antd'
import { useTranslation } from 'react-i18next'

import { extractFile } from '../../../components/Form/helpers'
import {
  useBusinessFileCreateMutation,
  type BodyBusinessFileCreateBusinessBusinessFilePost,
} from '../../../redux/api/business/business_file'
import { buildFormDataBody } from '../../../utils/form'

// const props: UploadProps = {
//   name: 'file',
//   multiple: true,
//   action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
//   onChange(info) {
//     const { status } = info.file
//     if (status !== 'uploading') {
//       console.log(info.file, info.fileList)
//     }
//     if (status === 'done') {
//       message.success(`${info.file.name} file uploaded successfully.`)
//     } else if (status === 'error') {
//       message.error(`${info.file.name} file upload failed.`)
//     }
//   },
//   onDrop(e) {
//     console.log('Dropped files', e.dataTransfer.files)
//   },
// }
type Props = {
  businessId: string
}
export default function UploadButton({ businessId }: Props) {
  const [businessFileCreate, { isLoading }] = useBusinessFileCreateMutation()
  const { t } = useTranslation()
  return (
    <Upload
      customRequest={async (options) => {
        const { onSuccess, onError, file, onProgress } = options
        const body =
          buildFormDataBody<BodyBusinessFileCreateBusinessBusinessFilePost>({
            file: extractFile(file as any),
          })
        const { data, error } = await businessFileCreate({
          body,
          businessId,
        })
        if (data && onSuccess) {
          onSuccess('ok')
        } else if (error && onError) {
          onError({ message: 'Error!', name: 'ERROR' })
          console.error(error)
        }
      }}
      onRemove={async ({ uid }) => {
        // const {} = await destroyInspectionFile({ id: parseInt(uid) })
      }}
      onDownload={async (file) => {
        // await retrieveInspectionFile({ id: file.uid, fileName: file.name })
      }}
      itemRender={(
        originNode,
        file,
        fileList,
        { download, preview, remove },
      ) => {
        return <></>
      }}
    >
      <Button
        icon={<UploadOutlined />}
        loading={isLoading}
        disabled={isLoading}
      >
        {t('business.business_file.form.actions.upload')}
      </Button>
    </Upload>
  )
}
