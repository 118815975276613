import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Layout, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Sider from 'antd/es/layout/Sider'
import Title from 'antd/es/typography/Title'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactJson from 'react-json-view'
import { useSearchParams } from 'react-router-dom'

import { useAppSelector } from '../../../app/hooks'
import Debug from '../../../components/helpers/Debug'
import Iconify from '../../../components/Iconify'
import type {
  AssistantGetManyApiResponse,
  AssistantResponse,
} from '../../../redux/api/assistant/assistant'
import {
  useAssistantCreateMutation,
  useAssistantGetManyQuery,
} from '../../../redux/api/assistant/assistant'
import {
  useLazyThreadHistoryQuery,
  useLazyThreadJsonQuery,
  useThreadCreateMutation,
} from '../../../redux/api/assistant/thread'
import type { BusinessResponse } from '../../../redux/api/business/business'
import { getAssistantModelDescriptor } from '../../../redux/slices/assistant/assistant'
import { getThreadModelDescriptor } from '../../../redux/slices/assistant/thread'
import { AssistantsRoute } from '../../../routes'
import { updateSearchParams } from '../../../utils/router'

import AssistantList from './AssistantList'
import AssistantUpdateForm from './AssistantUpdateForm'
import ThreadCascader from './ThreadCascader'
type Props = {
  selectedBusiness: BusinessResponse
  assistantId: string | null
}

export default function AssistantDashboard({
  selectedBusiness,
  assistantId,
}: Props) {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth)
  const [threadId, setThreadId] = useState<string | undefined>(undefined)
  const [userId, setUserId] = useState<string | undefined>(undefined)
  const user_id = user?.id
  const [createAssistant] = useAssistantCreateMutation()
  const [assistant, setAssistant] = useState<undefined | AssistantResponse>(
    undefined,
  )
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: assistants } = useAssistantGetManyQuery({
    businessId: selectedBusiness.id,
  })

  const setFirstOrNull = (assistants: AssistantGetManyApiResponse) => {
    if (assistants.length > 0) {
      const foundAssistant = assistants[0]
      const newAssistantId = foundAssistant.id
      updateSearchParams(
        { assistant: newAssistantId },
        searchParams,
        setSearchParams,
      )
      setAssistant(foundAssistant)
    } else {
      updateSearchParams(
        { assistant: undefined },
        searchParams,
        setSearchParams,
      )
      setAssistant(undefined)
    }
  }

  useEffect(() => {
    // assistant list loading.drawio
    if (assistants !== undefined) {
      if (assistantId !== null) {
        const foundAssistant = assistants.find(({ id }) => id === assistantId)
        if (foundAssistant !== undefined) {
          setAssistant(foundAssistant)
        } else {
          setFirstOrNull(assistants)
        }
      } else {
        setFirstOrNull(assistants)
      }
    }
  }, [assistantId, assistants])

  const [getThread] = useLazyThreadHistoryQuery()

  const tmd = getThreadModelDescriptor(t)
  const [threadCreate] = useThreadCreateMutation()
  const resizerRef = useRef<HTMLDivElement>(null)

  const [contentWidth, setContentWidth] = useState(500) // Initial width

  const startResize = (e: React.MouseEvent) => {
    const startX = e.clientX
    const startWidth = contentWidth

    const onMouseMove = (event: MouseEvent) => {
      const newWidth = startWidth + (event.clientX - startX)
      const maxWidth = window.innerWidth - 600
      setContentWidth(
        newWidth > 300 ? (newWidth > maxWidth ? maxWidth : newWidth) : 300,
      ) // Set minimum width
    }

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
  }

  const [collapsed, setCollapsed] = useState(true)
  const onAssistantChange = (assistantId: string | undefined) => {
    updateSearchParams(
      { assistant: assistantId },
      searchParams,
      setSearchParams,
    )
    setThreadId(undefined)
  }

  const assistantMD = getAssistantModelDescriptor(t)

  const data = {
    name: 'John',
    age: 30,
    details: { city: 'New York', hobbies: ['reading', 'traveling'] },
  }

  const [getThreadJson, { data: threadJson }] = useLazyThreadJsonQuery()

  return (
    <>
      <Debug
        objects={{ assistants, assistant, assistantId, threadJson }}
        debug
      />
      <Row>
        <Col span={21}>
          <Title level={3} style={{ paddingLeft: '24px' }}>
            {t(AssistantsRoute.title)}
          </Title>
        </Col>
      </Row>
      <Flex gap="middle" wrap style={{ overflow: 'auto' }}>
        <Layout
          style={{
            borderRadius: 8,
            overflow: 'hidden',
            // width: 'calc(50% - 8px)',
            // maxWidth: 'calc(50% - 8px)',
          }}
        >
          <Sider
            width={contentWidth}
            // collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
          >
            {!collapsed ? (
              <>
                {assistant ? (
                  <AssistantList
                    assistants={assistants || []}
                    assistant={assistant}
                    onClick={onAssistantChange}
                    businessId={selectedBusiness.id}
                  />
                ) : (
                  <Button
                    icon={<PlusOutlined />}
                    onClick={async () => {
                      await createAssistant({
                        assistantBody: {
                          business_id: selectedBusiness.id,
                        },
                      })
                    }}
                  >
                    {assistantMD.form.action.create()}
                  </Button>
                )}
                {assistant && (
                  <AssistantUpdateForm
                    businessId={selectedBusiness.id}
                    assistant={assistant}
                    selectedBusiness={selectedBusiness}
                    assistants={assistants}
                    onAssistantChange={onAssistantChange}
                  />
                )}
              </>
            ) : (
              <div
                style={{
                  writingMode: 'vertical-rl',
                  transform: 'rotate(180deg)',
                  textAlign: 'center',
                  height: '80%',
                  // margin: '20px',
                  // border: '1px solid #ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '20px',
                }}
              >
                {t('assistant.assistant.form.update.title')}
              </div>
            )}
          </Sider>
          <Iconify
            icon={
              !collapsed
                ? 'mdi:arrow-collapse-left'
                : 'mdi:arrow-collapse-right'
            }
            onClick={() => setCollapsed(!collapsed)}
            fontSize={15}
            style={{
              height: '100%',
              backgroundColor: 'gray',
              cursor: 'pointer',
              marginInline: '10px',
            }}
          />
          {!collapsed && (
            <div
              ref={resizerRef}
              onMouseDown={startResize}
              style={{
                width: '15px',
                cursor: 'ew-resize',
                backgroundColor: '#ddd',
              }}
            />
          )}
          <Layout>
            <Row>
              {assistant && user && (
                <>
                  <Col xs={24}>
                    <ThreadCascader
                      assistantId={assistant.id}
                      value={[userId, threadId]}
                      onChange={([userId, threadId]) => {
                        console.log(userId, threadId)
                        setUserId(userId)
                        setThreadId(threadId)
                        if (threadId)
                          getThreadJson({ assistantId: assistant.id, threadId })
                      }}
                    />
                  </Col>
                  {/* <Button
                    onClick={async () => {
                      const { data } = await threadCreate({
                        threadBody: {
                          name: defaultThreadName,
                          assistant_id: assistant.id,
                          user_id: user.id,
                        },
                      })
                      if (data !== undefined) {
                        //   setAssistant(data.)
                      }
                    }}
                  >
                    {tmd.form.action.create()}
                  </Button> */}
                </>
              )}
              {threadId && assistantId && (
                <Button
                  onClick={async () => {
                    await getThread({ assistantId, threadId })
                  }}
                >
                  download
                </Button>
              )}
            </Row>
            <ReactJson
              src={threadJson || {}}
              style={{
                padding: 0,
                display: 'flex',
              }}
              theme="bright:inverted" // Optional theme
              collapsed={4} // Collapsed to level 2
              enableClipboard={true} // Enable copying path/values
            />
            <Content style={{ overflow: 'auto' }}>
              {/* {assistant && threadId && (
                <ProChatProvider>
                  {user_id && (
                    <Chat
                      assistantId={assistant.id}
                      urlThreadId={threadId}
                      user_id={user_id}
                    />
                  )}
                </ProChatProvider>
              )} */}
            </Content>
          </Layout>
        </Layout>
      </Flex>
    </>
  )
}
