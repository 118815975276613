import { Button, Space, Typography } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Page from '../components/Page'
import { AccessDeniedRoute } from '../routes'
import { paths } from '../routes/paths'

const { Title, Text } = Typography

export default function ActivatedPage() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(paths.home)
    }, 5000)

    return () => clearTimeout(timer) // Cleanup timeout on component unmount
  }, [navigate])

  return (
    <Page title={t(AccessDeniedRoute.title)}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <Space direction="vertical" size="large">
          <Title level={2}>{t('pages.activated.message')}</Title>
          <Text>{t('pages.activated.warning')}</Text>
          <Button type="primary" onClick={() => navigate('/')}>
            {t('pages.activated.action')}
          </Button>
        </Space>
      </div>
    </Page>
  )
}
