import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import { ProfileRoute } from '../../routes'

import ProfileCard from './components/ProfileCard'

interface Props {}
const { Title } = Typography

export default function ProfilePage({}: Props) {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth)

  return (
    <Page title={t(ProfileRoute.title)}>
      <Debug debug objects={{ user }} />
      <Title level={3}>{t(ProfileRoute.title)}</Title>
      <Row gutter={50}>
        <Col lg={18}>{user && <ProfileCard user={user} />}</Col>
      </Row>
    </Page>
  )
}
