import type { IconifyIcon } from '@iconify/react'
import type { TableProps } from 'antd'
import { Button, Space, Table } from 'antd'
import type { ButtonProps } from 'antd/lib'
import type { MouseEventHandler, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import Iconify from './Iconify'

interface Props<T> extends TableProps<T> {
  orderer?: ReactNode
  createBtnProps?:
    | {
        onClick: MouseEventHandler<HTMLElement>
        disabled?: boolean
      }
    | false
  btns?: ReactNode[]
  btnsProps?: (ButtonProps & {
    tLabel?: string
    icon?: string | IconifyIcon | null
  })[]
}

export default function MyTable<T extends Record<string, any>>({
  orderer,
  btnsProps,
  btns,
  createBtnProps,
  style,
  ...props
}: Props<T>) {
  const { t } = useTranslation()
  return (
    <>
      {(btnsProps || orderer || btns) && (
        <Space
          direction="horizontal"
          style={{
            width: '100%',
            flexWrap: 'wrap',
            marginTop: '30px',
            justifyContent: 'right',
          }}
        >
          {btnsProps?.map(
            (
              {
                tLabel = 'common.create',
                icon = 'fluent:add-16-regular',
                ...props
              },
              key,
            ) => (
              <Button
                key={key}
                type="primary"
                style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                {...props}
              >
                {icon && <Iconify icon={icon} />}
                {t(tLabel)}
              </Button>
            ),
          )}
          {btns?.map((btn, index) => <div key={index}>{btn}</div>)}
          {orderer}
        </Space>
      )}
      <Table
        style={{ width: '100%', marginTop: '10px', ...style }}
        rowKey={(record) => record.id}
        scroll={{ x: 'max-content', ...style }}
        size="small"
        {...props}
      />
    </>
  )
}
