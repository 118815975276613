import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
// ----------------------------------------------------------------------

export type tRole = 'client' | 'admin' | 'user'

export interface MainState {
  isDarkMode: boolean
  env: tRole
}

const DARK_MODE = 'dark-mode'
const ENV = 'env'

const possibleEnvs: tRole[] = ['admin', 'user', 'client']

const getEnv = (): tRole => {
  const currentEnv = localStorage.getItem(ENV)
  return possibleEnvs.includes(currentEnv as tRole)
    ? (currentEnv as tRole)
    : 'client'
}

const initialState: MainState = {
  isDarkMode: localStorage.getItem(DARK_MODE) === 'true' || false,
  env: getEnv(),
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsDarkMode: (state, action: PayloadAction<{ isDarkMode: boolean }>) => {
      const darkMode = action.payload.isDarkMode
      localStorage.setItem(DARK_MODE, darkMode ? 'true' : 'false')
      state.isDarkMode = darkMode
    },
    setEnv: (state, action: PayloadAction<{ env: tRole }>) => {
      const env = action.payload.env
      localStorage.setItem(ENV, env)
      state.env = env
    },
  },
})

export const { setIsDarkMode, setEnv } = mainSlice.actions
export default mainSlice.reducer
