import { Col, Form, Modal, Row } from 'antd'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import type { IFormBody } from '../../../components/Form/helpers'
import FormSelect from '../../../components/Form/Select'
import Debug from '../../../components/helpers/Debug'
import { useDisciplineGetManyQuery } from '../../../redux/api/edu/discipline'
import {
  useTeacherDisciplineCreateMutation,
  type TeachersDisciplineBody,
} from '../../../redux/api/edu/teachers_discipline'
import { getTeacherDisciplineModelDescriptor } from '../../../redux/slices/edu/teacherDiscipline'

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function TeacherDisciplineForm({ open, setOpen }: Props) {
  type FormBody = IFormBody<TeachersDisciplineBody, never>
  const [form] = Form.useForm<FormBody>()
  const { t } = useTranslation()
  const fmd = getTeacherDisciplineModelDescriptor(t)
  const [teacherDisciplineCreate] = useTeacherDisciplineCreateMutation()
  const handleOk = async () => {
    const { discipline_id } = form.getFieldsValue()
    const { data } = await teacherDisciplineCreate({
      teachersDisciplineBody: {
        discipline_id,
      },
    })
  }
  const { data: disciplines } = useDisciplineGetManyQuery({})
  const handleClose = () => {
    setOpen(false)
    form.resetFields()
  }

  const values = Form.useWatch([], form)
  const initialValues = {}
  return (
    <Modal
      title={fmd.form.action.create()}
      open={open}
      width={'800px'}
      onOk={handleOk}
      // confirmLoading={confirmLoading}
      onCancel={handleClose}
      okText={''}
      cancelText={''}
      //   okButtonProps={{ title: createBtnText }}
      //   cancelButtonProps={{ title: cancelBtnText }}
      afterOpenChange={() => form.resetFields()}
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        size="small"
        initialValues={initialValues}
      >
        <Debug objects={{ initialValues }} />
        <Debug objects={{ values }} />
        <Row gutter={30}>
          <Col span={24}>
            <FormSelect
              formField={fmd.fields.discipline_id}
              options={
                disciplines
                  ? disciplines.map(({ title, en, id, kz, ru }) => ({
                      key: id,
                      value: id,
                      label: en,
                    }))
                  : []
              }
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
