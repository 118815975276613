// ----------------------------------------------------------------------
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import type { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Iconify from '../../components/Iconify'
import { AssistantsRoute, BusinessFilesRoute } from '../../routes'
import { paths } from '../../routes/paths'
import { gray } from '../../theme'
// ----------------------------------------------------------------------
type Props = {}

type MenuItem = Required<MenuProps>['items'][number]

export default function ClientDashboardSider({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isDarkMode } = useAppSelector((state) => state.main)

  const style: CSSProperties = {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '12px',
  }

  const childStyle: CSSProperties = {
    ...style,
    paddingLeft: '35px',
  }

  const items: MenuItem[] = [
    {
      key: paths.clientAssistants,
      label: (
        <a onClick={() => navigate(paths.clientAssistants)} style={style}>
          {t(AssistantsRoute.title)}
        </a>
      ),
      icon: <Iconify icon={'hugeicons:bot'} />,
    },
    {
      key: paths.clientBuisnessFiles,
      label: (
        <a onClick={() => navigate(paths.clientBuisnessFiles)} style={style}>
          {t(BusinessFilesRoute.title)}
        </a>
      ),
      icon: <Iconify icon={'gravity-ui:files'} />,
    },
  ]

  return (
    <Sider
      width={256}
      style={{
        // minHeight: '100vh',
        height: '100%',
        backgroundColor: isDarkMode ? gray : undefined,
      }}
    >
      <Menu
        mode="inline"
        items={items}
        selectedKeys={[pathname]}
        style={{ backgroundColor: isDarkMode ? gray : undefined }}
      />
    </Sider>
  )
}
