// ----------------------------------------------------------------------
import { Button, Layout, Menu, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import AccountDropdown from '../../components/AccountDropdown'
import LanguagePopover from '../../components/LanguagePopover'
import AuthModal from '../../pages/Landing/components/AuthModal'
import Logo from '../../pages/Landing/components/components/Logo'
import { paths } from '../../routes/paths'
import { gray, light } from '../../theme'

// ----------------------------------------------------------------------

const { Header } = Layout

export default function LandingAppBar() {
  const { isDarkMode } = useAppSelector((state) => state.main)
  const { t } = useTranslation()

  // Function to open the modal
  const openModal = () => {
    setIsLoginModalOpen(true)
  }

  const { user } = useAppSelector((state) => state.auth)
  const isAuthenticated = user !== null

  const navigate = useNavigate()
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)
  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '0px',
          position: 'fixed', // Makes it stick to the top
          top: 0, // Aligns it to the top of the viewport
          width: '100%', // Ensures it spans the full width of the screen
          zIndex: 1000, // Keeps it above other elements
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Adds a shadow for better layering
        }}
        className="App-header"
      >
        <AuthModal open={isLoginModalOpen} setOpen={setIsLoginModalOpen} />

        <Logo />

        <Menu
          mode="horizontal"
          items={[
            {
              key: '#home',
              label: (
                <a href="#home">{t('pages.landing.header.options.home')}</a>
              ),
            },
            {
              key: '#features',
              label: (
                <a href="#features">
                  {t('pages.landing.header.options.features')}
                </a>
              ),
            },
            {
              key: '#pricing',
              label: (
                <a href="#pricing">
                  {t('pages.landing.header.options.pricing')}
                </a>
              ),
            },
            {
              key: '#blog',
              label: (
                <a href="#blog">{t('pages.landing.header.options.blog')}</a>
              ),
            },
            {
              key: '#contact',
              label: (
                <a href="#contact">
                  {t('pages.landing.header.options.contactUs')}
                </a>
              ),
            },
            ...(isAuthenticated
              ? [
                  {
                    key: '/playground',
                    label: (
                      <Link to="/playground">
                        {t('pages.landing.header.options.toPlayground')}
                      </Link>
                    ),
                  },
                ]
              : []),
          ]}
          style={{ flex: 1, minWidth: 0, justifyContent: 'right' }}
        />
        <LanguagePopover
          buttonProps={{
            style: { marginRight: 30 },
          }}
        />

        {!isAuthenticated ? (
          <Button
            onClick={openModal}
            style={{
              background: 'orange',
              color: 'white',
              borderRadius: '0.375rem',
              border: 'none',
              padding: '0.5rem 1rem',
            }}
            onMouseEnter={(e) => (e.currentTarget.style.background = '#EA580C')}
            onMouseLeave={(e) => (e.currentTarget.style.background = 'orange')}
          >
            {t('pages.landing.header.buttons.sign-in-sign-up')}
          </Button>
        ) : (
          <>
            <AccountDropdown
              items={[
                {
                  key: 'profile',
                  label: (
                    <a onClick={() => navigate(paths.profile)}>
                      {t('components.navbar.accountDropdown.profile')}
                    </a>
                  ),
                },
              ]}
            />
          </>
        )}
      </Header>
      <Outlet />
      <Typography
        style={{
          textAlign: 'center',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          position: 'fixed',
          height: 'var(--global-footer-height)',
          backgroundColor: isDarkMode ? gray : light,
          alignContent: 'center',
        }}
      >
        {t('components.footer.title', {
          year: new Date().getFullYear(),
          trademark: t('trademark'),
        })}
      </Typography>
    </Layout>
  )
}
