import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../app/hooks'
import Page from '../../components/Page'

import BusinessUpdateForm from './components/BusinessUpdateForm'
import { BusinessRoute } from '../../routes'

interface Props {}
const { Title } = Typography

export default function BusinessPage({}: Props) {
  const { t } = useTranslation()
  const { selectedBusiness } = useAppSelector(
    (state) => state.business.business,
  )

  return (
    <Page title={t(BusinessRoute.title)}>
      <Title level={3}>{t(BusinessRoute.title)}</Title>
      {selectedBusiness && <BusinessUpdateForm business={selectedBusiness} />}
    </Page>
  )
}
