export function denonify<T extends { [key: string]: any }>(
  obj: T,
): { [K in keyof T]: T[K] | undefined } {
  const keys = Object.keys(obj) as (keyof T)[]
  keys.forEach((key) => {
    const value = obj[key]
    if (
      // Check for NaN (Note: NaN is the only value in JavaScript that is not equal to itself)
      value !== value ||
      value === '' ||
      value === null ||
      value === undefined ||
      // Check for an empty array
      (Array.isArray(value) && value.length === 0)
    ) {
      obj[key] = undefined as any
    }
  })
  return obj
}
