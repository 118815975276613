import { DownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Popconfirm, Row } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'
import type { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

type MyItem = Omit<ItemType, 'key'>

type Props = {
  // EDIT
  /** Is inline editing active */
  isRowEditing?: boolean

  /** Triggered when edit action is pressed */
  onEditPress?: () => void

  /** Action performed on Edit confirmation */
  onEdit?: (e: MouseEvent<HTMLElement, globalThis.MouseEvent>) => Promise<void>

  /** Cleanup callback when edit dialog is closed */
  onEditClose?: () => void

  // DELETE
  /** Is inline deletion active */
  isRowDeleting?: boolean

  /** Triggered when delete action is pressed */
  onDeletePress?: () => void

  /** Action performed on Delete confirmation */
  onDelete?: (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
  ) => Promise<void>

  /** Cleanup callback when delete dialog is closed */
  onDeleteClose?: () => void

  // REST
  /** Disables dropdown */
  dropdownDisabled?: boolean

  /** Other items for dropdown */
  items?: MyItem[] | undefined
}

export default function Actions({
  isRowEditing = false,
  onEdit,
  onEditPress,
  onEditClose,
  //
  isRowDeleting = false,
  onDelete,
  onDeletePress,
  onDeleteClose,
  //
  items,
  dropdownDisabled = false,
}: Props) {
  const { t } = useTranslation()

  const preItems: MyItem[] = []

  if (onEdit !== undefined) {
    preItems.push({
      label: (
        <div onClick={onEditPress}>
          {t('components.form.actions.pop.items.edit.label')}
        </div>
      ),
    })
  }

  if (onDelete !== undefined) {
    preItems.push({
      key: 2,
      label: t('components.form.actions.pop.items.delete.label'),
      onClick: onDeletePress,
    })
  }

  const mergedItems: ItemType[] = [
    ...preItems.map((item, index) => ({ ...item, key: index })),
    ...(items
      ? items.map((item, index) => ({ ...item, key: index + preItems.length }))
      : []),
  ]

  if (onDelete && isRowDeleting) {
    return (
      <Row>
        <Col span={11}>
          <Button
            size="small"
            type="primary"
            danger
            onClick={async (e) => {
              await onDelete(e)
              if (onDeleteClose) {
                onDeleteClose()
              }
            }}
          >
            {t('components.form.actions.pop.items.delete.confirm')}
          </Button>
        </Col>
        <Col span={2} />
        <Col span={11}>
          <Popconfirm
            title={t('components.form.actions.pop.items.cancel.confirm')}
            okText={t('components.form.actions.pop.ok')}
            cancelText={t('components.form.actions.pop.cancel')}
            onConfirm={onDeleteClose}
          >
            <a>{t('components.form.actions.pop.cancel')}</a>
          </Popconfirm>
        </Col>
      </Row>
    )
  } else if (onEdit && isRowEditing) {
    return (
      <Row>
        <Col span={11}>
          <Button
            size="small"
            type="primary"
            danger
            onClick={async (e) => {
              await onEdit(e)
              if (onEditClose) {
                onEditClose()
              }
            }}
          >
            {t('components.form.actions.pop.items.edit.submit')}
          </Button>
        </Col>
        <Col span={2} />
        <Col span={11}>
          <Popconfirm
            title={t('components.form.actions.pop.items.edit.confirm')}
            okText={t('components.form.actions.pop.ok')}
            cancelText={t('components.form.actions.pop.cancel')}
            onConfirm={onEditClose}
          >
            <a>{t('components.form.actions.pop.cancel')}</a>
          </Popconfirm>
        </Col>
      </Row>
    )
  } else {
    return (
      <div>
        <Dropdown
          menu={{
            items: mergedItems,
          }}
          disabled={dropdownDisabled}
        >
          <Button type="link" size="small">
            {t('components.form.actions.label')}
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    )
  }
}
