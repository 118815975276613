import dayjs from 'dayjs'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css'
import App from './App'
import { store } from './app/store'
import './i18n'
import './index.css'
const container = document.getElementById('root')

dayjs.locale('en')

if (container) {
  const root = createRoot(container)

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>,
  )
} else {
  throw new Error(
    "Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.",
  )
}
