import type { FormItemProps } from 'antd'
import { InputNumber as ANTDInputNumber, Form } from 'antd'
import type { InputNumberProps } from 'antd/lib/input-number'
import React, { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { FormField } from '../../utils/modelDescriptor/formFields'

export const positiveFloatProps: InputNumberProps = {
  min: 0,
  step: 0.1,
  maxLength: 8,
}

export const floatProps: InputNumberProps = {
  step: 0.1,
  maxLength: 8,
}

export const positiveIntegerProps: InputNumberProps = {
  min: 0,
  step: 1,
  maxLength: 8,
  onKeyPress: (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  },
}

interface Props {
  active?: boolean
  itemProps?: FormItemProps
  inputProps?: InputNumberProps
  iconify?: string
  formField: FormField<any>
  inactiveComponent?: React.ReactNode
}

export default function FormNumberInput({
  active = true,
  formField,
  itemProps = {},
  inputProps = {},
  iconify,
  inactiveComponent,
}: Props) {
  const ico = formField.iconify ?? iconify
  const { t } = useTranslation()
  const id = useId()
  return (
    <Form.Item
      id={id}
      name={formField.name}
      rules={formField.rules}
      hasFeedback={Boolean(formField.rules)}
      validateTrigger={formField.rules ? 'onBlur' : undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      {active ? (
        <ANTDInputNumber
          name={formField.name}
          placeholder={t(formField.placeholder)}
          {...inputProps}
          style={{
            width: '100%',
            ...inputProps.style,
          }}
        />
      ) : (
        inactiveComponent
      )}
    </Form.Item>
  )
}
