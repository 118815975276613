import type { TFunction, TOptions } from 'i18next'
import { toast } from 'react-toastify'

export class ToastDescriptor {
  domain: string
  t: TFunction
  constructor(domain: string, t: TFunction) {
    this.domain = domain
    this.t = t
  }

  updated(options?: TOptions) {
    toast.success(this.t(`${this.domain}.toast.updated`, options))
  }

  created(options?: TOptions) {
    toast.success(this.t(`${this.domain}.toast.created`, options))
  }

  deleted(options?: TOptions) {
    toast.success(this.t(`${this.domain}.toast.deleted`, options))
  }

  error(options?: TOptions) {
    toast.error(this.t(`${this.domain}.toast.error`, options))
  }

  custom(toastKey: string, options?: TOptions) {
    toast.success(this.t(`${this.domain}.toast.${toastKey}`, options))
  }
}
