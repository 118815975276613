import { Breadcrumb, Card, Layout } from 'antd'
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import type { CardProps } from 'antd/lib'
import type { CSSProperties } from 'react'
import { forwardRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
// ----------------------------------------------------------------------

interface InterfacePage extends CardProps {
  children: React.ReactNode
  title?: string
  crumbs?: ItemType[]
  layoutStyle?: CSSProperties
}

const Page = forwardRef(
  (
    {
      children,
      title = '',
      crumbs,
      style,
      styles,
      layoutStyle,
      ...props
    }: InterfacePage,
    ref,
  ) => {
    const { body, ...restStyles } = styles ? styles : { body: undefined }
    const { t } = useTranslation()
    return (
      <Layout
        className="Page"
        style={{
          marginBottom: '100px',
          ...layoutStyle,
        }}
      >
        <Helmet>
          <title>{`${title} | ${t('trademark')}`}</title>
        </Helmet>
        <Card
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            ...style,
          }}
          styles={{
            body: {
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              ...body,
            },
            ...restStyles,
          }}
          {...props}
        >
          {crumbs && <Breadcrumb items={crumbs} />}
          {children}
        </Card>
      </Layout>
    )
  },
)

export default Page
