import { Button, Col, Form, Row } from 'antd'
import type { FormItemProps } from 'antd/lib'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { IFormBody } from '../../../components/Form/helpers'
import FormInput from '../../../components/Form/Input'
import Debug from '../../../components/helpers/Debug'
import DebugFormBtn from '../../../components/helpers/DebugFormBtn'
import type {
  BusinessBody,
  BusinessResponse,
} from '../../../redux/api/business/business'
import { useBusinessUpdateMutation } from '../../../redux/api/business/business'
import { getBusinessModelDescriptor } from '../../../redux/slices/business/business'

import CountrySelect from './CountrySelect'
import IndustrySelect from './IndustrySelect'

interface Props {
  business: BusinessResponse
}

export default function BusinessUpdateForm({ business }: Props) {
  const { t } = useTranslation()
  type FormBody = IFormBody<BusinessBody, never>
  const [form] = Form.useForm<FormBody>()

  const [submittable, setSubmittable] = useState<boolean>(true)
  const values = Form.useWatch([], form)

  const businessMD = getBusinessModelDescriptor(t)
  const fields = businessMD.fields

  const [updateBusiness, { error, isSuccess }] = useBusinessUpdateMutation()

  useEffect(() => {
    if (isSuccess) {
      businessMD.toast.updated()
      cleanup()
    }
  }, [isSuccess])

  const cleanup = () => {
    setSubmittable(false)
  }

  const handleOk = async () => {
    const { name, address, country_id, description, ein, industry } =
      form.getFieldsValue()
    if (name !== undefined) {
      const {} = await updateBusiness({
        id: business.id,
        businessBody: { name, address, country_id, description, ein, industry },
      })
    }
  }

  const [serverErrors, setServerErrors] = useState<{
    [key in keyof BusinessBody]?: string
  }>({})

  const itemProps = (label: keyof BusinessBody): FormItemProps => ({
    rules: fields[label].rules,
    help: serverErrors[label],
    validateStatus: serverErrors[label] ? 'error' : '',
  })

  const { id, country, ...rest } = business

  const initialValues: BusinessBody = {
    country_id: country?.id,
    ...rest,
  }

  useEffect(() => {
    if (values) {
      if (isEqual(values, initialValues)) {
        setSubmittable(false)
      } else {
        setSubmittable(true)
      }
    }
  }, [form, values])

  return (
    <Form<BusinessBody>
      form={form}
      labelCol={{ span: 24 }}
      size="small"
      onFinish={handleOk}
      initialValues={initialValues}
    >
      <Debug
        objects={{
          values,
          submittable,
          initialValues,
          business,
        }}
        debug
      />
      <Row gutter={30}>
        <Col span={12}>
          {/* name */}
          <FormInput
            formField={fields.name}
            itemProps={{
              ...itemProps('name'),
            }}
          />
          {/* description */}
          <FormInput
            formField={fields.description}
            itemProps={{
              ...itemProps('description'),
            }}
          />
          {/* country */}
          <CountrySelect
            formField={fields.country_id}
            itemProps={{
              ...itemProps('country_id'),
            }}
            // selectProps={{
            //   style: {},
            // }}
          />
          {/* address */}
          <FormInput
            formField={fields.address}
            itemProps={{
              ...itemProps('address'),
            }}
          />
          {/* ein */}
          <FormInput
            formField={fields.ein}
            itemProps={{
              ...itemProps('ein'),
            }}
          />
          {/* industry */}
          <IndustrySelect
            formField={fields.industry}
            itemProps={{
              ...itemProps('industry'),
            }}
          />
        </Col>
        <Col span={12}></Col>
      </Row>
      <Button htmlType="submit" disabled={!submittable}>
        {businessMD.form.action.update()}
      </Button>
      <DebugFormBtn form={form} debug />
    </Form>
  )
}
