import { assistantApi as api } from './index'
export const addTagTypes = ['assistant'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      assistantCreate: build.mutation<
        AssistantCreateApiResponse,
        AssistantCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant/`,
          method: 'POST',
          body: queryArg.assistantBody,
        }),
        invalidatesTags: ['assistant'],
      }),
      assistantGetMany: build.query<
        AssistantGetManyApiResponse,
        AssistantGetManyApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant/`,
          params: { business_id: queryArg.businessId },
        }),
        providesTags: ['assistant'],
      }),
      assistantUpdate: build.mutation<
        AssistantUpdateApiResponse,
        AssistantUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant/${queryArg.id}/`,
          method: 'PUT',
          body: queryArg.assistantBody,
        }),
        invalidatesTags: ['assistant'],
      }),
      assistantBusinessFileDelete: build.mutation<
        AssistantBusinessFileDeleteApiResponse,
        AssistantBusinessFileDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant/${queryArg.id}/files/${queryArg.fileId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['assistant'],
      }),
      assistantToggleStatus: build.query<
        AssistantToggleStatusApiResponse,
        AssistantToggleStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant/${queryArg.id}/toggle_status`,
          params: { status: queryArg.status },
        }),
        providesTags: ['assistant'],
      }),
      assistantDelete: build.mutation<
        AssistantDeleteApiResponse,
        AssistantDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['assistant'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as assistantApi }
export type AssistantCreateApiResponse =
  /** status 201 Successful Response */ AssistantResponse
export type AssistantCreateApiArg = {
  assistantBody: AssistantBody
}
export type AssistantGetManyApiResponse =
  /** status 200 Successful Response */ AssistantResponse[]
export type AssistantGetManyApiArg = {
  /** UUID of the business to get assistants for */
  businessId: string
}
export type AssistantUpdateApiResponse =
  /** status 201 Successful Response */ AssistantResponse
export type AssistantUpdateApiArg = {
  id: string
  assistantBody: AssistantBody
}
export type AssistantBusinessFileDeleteApiResponse =
  /** status 204 Successful Response */ void
export type AssistantBusinessFileDeleteApiArg = {
  id: string
  fileId: string
}
export type AssistantToggleStatusApiResponse =
  /** status 200 Successful Response */
    | AssistantResponse
    | {
        [key: string]: string
      }
export type AssistantToggleStatusApiArg = {
  id: string
  status: 'active' | 'inactive'
}
export type AssistantDeleteApiResponse =
  /** status 200 Successful Response */ object
export type AssistantDeleteApiArg = {
  id: string
}
export type LanguageCode =
  | 'Kazakh'
  | 'Russian'
  | 'English'
  | 'Turkish'
  | 'Spanish'
  | 'Portuguese'
  | 'German'
  | 'French'
export type AssistantStatus =
  | 'created'
  | 'active'
  | 'inactive'
  | 'missing_payment'
  | 'deleted'
  | 'failed'
export type AssistantResponse = {
  id: string
  title: string
  min_score: number | null
  max_docs: number
  description: string | null
  language: LanguageCode
  status: AssistantStatus
  created_at: string
  updated_at: string
  thread_count: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type AssistantBody = {
  title?: string
  description?: string | null
  min_score?: number | null
  max_docs?: number
  language?: LanguageCode
  business_id: string
}
export const {
  useAssistantCreateMutation,
  useAssistantGetManyQuery,
  useLazyAssistantGetManyQuery,
  useAssistantUpdateMutation,
  useAssistantBusinessFileDeleteMutation,
  useAssistantToggleStatusQuery,
  useLazyAssistantToggleStatusQuery,
  useAssistantDeleteMutation,
} = injectedRtkApi
