import type { CascaderProps, GetProp } from 'antd'
import { Cascader } from 'antd'
import type { BaseOptionType as CascaderBaseOptionType } from 'antd/es/cascader'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import Debug from '../../../components/helpers/Debug'
import type { UserThreads } from '../../../redux/api/assistant/thread'
import { useThreadGetAssistantUsersQuery } from '../../../redux/api/assistant/thread'

type BaseOptionType = Omit<CascaderBaseOptionType, 'value'> & {
  value: string
}

type DefaultOptionType = Omit<
  GetProp<CascaderProps<BaseOptionType>, 'options'>[number],
  'value' | 'label'
> & {
  value: string
  label: string
}

interface OptionType extends DefaultOptionType {
  isLeaf?: boolean
}

export type SingleValueType = (string | null)[]

type Props = {
  assistantId: string
  onChange?: (domain: [string | undefined, string | undefined]) => void
  value?: [string | undefined, string | undefined]
} & Omit<CascaderProps<OptionType>, 'value' | 'onChange'>

export default function ThreadCascader(props: Props) {
  const debounceTimeout = 300
  const { assistantId, value, onChange, ...restProps } = props
  const [options, setOptions] = useState<OptionType[]>([])
  const [ftsSearch, setFtsSearch] = useState<string | undefined>(undefined)
  const [domainInitialized, setDomainInitialized] = useState<boolean>(false)
  const [innerValue, setInnerValue] = useState<SingleValueType>([])
  const [innerLabels, setInnerLabels] = useState<string[]>([])
  const dispatch = useDispatch()
  const { data, isFetching } = useThreadGetAssistantUsersQuery({ assistantId })
  const userId = props.value ? props.value[0] : undefined
  const threadId = props.value ? props.value[1] : undefined

  const debounceFetcher = useMemo(() => {
    const loadOptions = (ftsSearch: string) => {
      setFtsSearch(ftsSearch)
    }
    return debounce(loadOptions, debounceTimeout)
  }, [props.value, debounceTimeout])

  const initializeDomain = async (threadId: string) => {
    if (data) {
      const user = data.find(({ threads }) =>
        threads.map(({ id }) => id).includes(threadId),
      )
      if (user) {
        const thread = user.threads.find(({ id }) => id === threadId)
        if (thread) {
          handleChange(user.id, threadId, data, [user.email, thread.id])
        }
      }
    }
    setDomainInitialized(true)
  }

  useEffect(() => {
    if (threadId !== undefined && domainInitialized === false) {
      initializeDomain(threadId)
    }
  }, [threadId])

  useEffect(() => {
    if (data) {
      setOptions(
        data.map(({ id, email, threads }) => ({
          label: email,
          value: id,
          children: threads.map(({ id, name }) => ({
            value: id,
            label: `${name} (${id})`,
          })),
        })),
      )
    }
  }, [data])

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    )

  const handleChange = (
    userId: string,
    businessId: string,
    users: UserThreads[],
    labels: string[],
  ) => {
    setInnerValue([userId, businessId])
    setInnerLabels(labels)
    if (onChange !== undefined) {
      onChange([userId, businessId])
    }
    const user = users.find(({ id }) => id === userId)
    const business = user?.threads.find(({ id }) => id === businessId)
    // if (business !== undefined) {
    //   dispatch(setBusiness({ selectedBusiness: business }))
    // }
  }

  useEffect(() => {
    //clean inner value if outerValue was changed from outside
    if (value && value[1] === undefined) {
      handleClearChange()
    }
  }, [value])

  const handleClearChange = () => {
    setInnerValue([])
    setInnerLabels([])
    if (onChange !== undefined) {
      onChange([undefined, undefined])
    }
    // dispatch(setBusiness({ selectedBusiness: undefined }))
  }

  const displayRender: CascaderProps<OptionType>['displayRender'] = (
    labels,
    selectedOptions = [],
  ) =>
    labels.map((label, i) => {
      const option = selectedOptions[i]
      if (option === null || option === undefined) {
        return ''
      } else if (i === labels.length - 1) {
        return (
          <span key={option.value}>
            &nbsp;/&nbsp;
            {label}
          </span>
        )
      } else {
        return (
          <span key={option.value}>
            💬 &nbsp;
            {label}
          </span>
        )
      }
    })

  return (
    <>
      <Debug objects={{ innerValue, innerLabels }} />
      <Cascader
        options={[...options]}
        displayRender={displayRender}
        onChange={(
          val: SingleValueType | undefined,
          selectOptions: OptionType[],
        ) => {
          if (val !== undefined) {
            const [userId, businessId] = val
            if (
              typeof userId === 'string' &&
              typeof businessId === 'string' &&
              data !== undefined
            ) {
              handleChange(
                userId,
                businessId,
                data,
                selectOptions.map(({ label }) => label),
              )
            } else if (userId === null && businessId === null) {
              handleClearChange()
            }
          } else {
            handleClearChange()
          }
        }}
        expandTrigger="hover"
        value={innerValue as any}
        placeholder="Please select"
        style={{
          width: `100%`,
        }}
        showSearch={{ filter }}
        onSearch={debounceFetcher}
      />
    </>
  )
}
