import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Card, Popconfirm, Row, Typography } from 'antd'
import { useEffect, type CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppDispatch } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import type { OrderResponse } from '../../redux/api/edu/order'
import {
  useOrderDeleteMutation,
  useOrderGetQuery,
  useOrderSubmitMutation,
} from '../../redux/api/edu/order'
import { orderSetEditId } from '../../redux/slices/edu/order'
import { EduPlaygroundRoute } from '../../routes/eduRoutes'
import { formatEduPlayGroundPath } from '../../routes/paths'

const { Title } = Typography

const PlaygroundBody = ({ order }: { order: OrderResponse }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cardStyle: CSSProperties = {
    width: '100%',
    backgroundColor: 'var(--color-light-gray)',
    borderRadius: 15,
  }
  const [orderDelete] = useOrderDeleteMutation()
  const [orderSubmit] = useOrderSubmitMutation()
  return (
    <>
      <Row>
        <Title level={2} style={{ color: 'var(--color-primary-orange)' }}>
          {order.user_input_text}
        </Title>
      </Row>
      <Card style={{ ...cardStyle }}>
        <Row>
          <Title style={{ color: 'var(--violet)' }} level={3}>
            {t('edu.order.fields.user_instructions.label')}
          </Title>
        </Row>
        <Row>
          <Typography style={{ color: 'var(--violet)' }}>
            {order.user_input_text}
          </Typography>
        </Row>
      </Card>
      <Card
        style={{
          ...cardStyle,
          marginTop: 30,
        }}
      >
        <Row>
          <Title style={{ color: 'var(--color-primary-orange)' }} level={3}>
            {t('edu.order.fields.result_text.label')}
          </Title>
        </Row>
        <Row>
          <Typography style={{ color: 'var(--violet)' }}>
            {order.result_text}
          </Typography>
        </Row>
      </Card>
      <Row
        style={{
          marginTop: '15px',
          justifyContent: 'flex-end',
          display: 'grid',
          gridAutoFlow: 'column',
          gridColumnGap: '10px',
        }}
      >
        {/* // if completed => disabled */}
        <Button
          type="primary"
          onClick={() => {
            dispatch(orderSetEditId(order._id))
          }}
        >
          {t('edu.order.form.actions.edit')}
        </Button>
        <Button type="primary">{t('edu.order.form.actions.like')}</Button>
        <Button
          type="primary"
          onClick={() => {
            orderSubmit({ orderId: order._id })
          }}
        >
          {t('edu.order.form.actions.approve')}
        </Button>
        <Popconfirm
          title={t('edu.order.form.actions.delete.popConfirm.title')}
          description={t(
            'edu.order.form.actions.delete.popConfirm.description',
          )}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onConfirm={() => {
            orderDelete({ orderId: order._id })
          }}
        >
          <Button type="primary" danger>
            {t('edu.order.form.actions.delete.title')}
          </Button>
        </Popconfirm>
      </Row>
    </>
  )
}

const ASD = ({ order_id }: { order_id: string }) => {
  const { data: order, error } = useOrderGetQuery({
    orderId: order_id || 'asd',
  })
  const { teacher_discipline_id, category_id, subcategory_id } = useParams()
  const navigate = useNavigate()
  useEffect(() => {
    if (error) {
      navigate(
        formatEduPlayGroundPath(
          teacher_discipline_id,
          category_id,
          subcategory_id,
        ),
      )
    }
  }, [error])
  return (
    <>
      <Debug debug objects={{ order }} />
      {order && <PlaygroundBody order={order} />}
    </>
  )
}

export default function EduPlayground() {
  const { t } = useTranslation()
  const { teacher_discipline_id, category_id, subcategory_id, order_id } =
    useParams()

  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   if (order_id) orderGet({ orderId: order_id })
  // }, [order_id])
  return (
    <Page title={t(EduPlaygroundRoute.title)}>
      <Debug
        debug
        objects={{
          teacher_discipline_id,
          category_id,
          subcategory_id,
          order_id,
        }}
      />

      {order_id && <ASD order_id={order_id} />}
    </Page>
  )
}
