import { Form, Select, type FormItemProps } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { SelectProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { IndustryEnum } from '../../../redux/api/business/business'
import type { FormField } from '../../../utils/modelDescriptor/formFields'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
  selectProps?: Omit<SelectProps, 'options' | 'value'>
}

export default function IndustrySelect({
  formField,
  itemProps,
  selectProps,
}: Props) {
  const { t } = useTranslation()
  const id = useId()
  const anyval = 1

  // we need to require each enum
  const enims: { [key in IndustryEnum]: any } = {
    Education: anyval,
    Finance: anyval,
    Healthcare: anyval,
    Hospitality: anyval,
    Other: anyval,
    Retail: anyval,
    Sport: anyval,
    Technology: anyval,
  }
  const options: (Omit<DefaultOptionType, 'label' | 'value'> & {
    label: IndustryEnum
    value: IndustryEnum
  })[] = Object.keys(enims).map((key) => ({
    label: t(`components.select.industry.options.${key as IndustryEnum}`) as IndustryEnum,
    value: key as IndustryEnum,
  }))

  return (
    <Form.Item
      id={id}
      name={formField.name}
      rules={formField.rules}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <Select
        placeholder={formField.placeholder}
        options={options}
        allowClear
        {...selectProps}
        style={{
          alignItems: 'left',
          ...selectProps?.style,
        }}
      />
    </Form.Item>
  )
}
