import type { ChatMessage } from '@ant-design/pro-chat'
import type { ModelRoleType } from '@ant-design/pro-chat/es/ProChat/types/config'

import { setThreads } from '../../slices/assistant/thread'

import type { RunResponse } from './run'
import { runApi } from './run'
import { threadApi } from './thread'

// fact
export const runApiEnhanced = runApi.enhanceEndpoints({
  endpoints: {
    runCreate: {
      invalidatesTags: [] as any,
    },
    runCreateFeedback: {
      invalidatesTags: [] as any,
    },
  },
})

// thread
export const threadApiEnhanced = threadApi.enhanceEndpoints({
  endpoints: {
    threadGetMany: {
      onQueryStarted: async (
        { assistantId, userId },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled
        dispatch(setThreads(data))
      },
    },
    threadHistory: {
      query: (queryArg) => ({
        url: `/assistant/thread/${queryArg.assistantId}/${queryArg.threadId}/history`,
        method: 'GET',
        responseHandler: async (response) => {
          const downloadUrl = window.URL.createObjectURL(await response.blob())
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', `asd.csv`)
          document.body.appendChild(link)
          link.click()
          link.remove()
        },
        cache: 'no-cache',
      }),
    },
  },
})

export type TChat = ChatMessage<RunResponse> & {
  role: ModelRoleType
}
