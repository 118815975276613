import type { ItemType, MenuItemType } from 'antd/es/menu/interface'
import type { TFunction } from 'i18next'
import { useRoutes } from 'react-router-dom'

// ----------------------------------------------------------------------
import MeLoader from './components/MeLoader'
import PrivateRoute from './components/PrivateRoute'
import AppBarLayout from './layouts/AppBar'
import ClientAppBarLayout from './layouts/ClientAppBar'
import LandingAppBar from './layouts/LandingAppBar'
import MainLayout from './layouts/Main'
import ClientDashboardSider from './layouts/Main/ClientDashboardSider'
import ClientSettingsSider from './layouts/Main/ClientSettingsSider'
import PlayGroundAppBar from './layouts/Main/PlayGroundAppBar'
import PlayGroundSider from './layouts/Main/PlayGroundSider'
import UserChatSider from './layouts/Main/UserChatSider'
import * as PAGES from './pages'
import * as ROUTES from './routes'
import { paths } from './routes/paths'
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <MeLoader />,
      children: [
        {
          element: <LandingAppBar />,
          children: [
            {
              children: [
                {
                  path: '/',
                  element: <PAGES.Landing />,
                },
              ],
            },
          ],
        },
        // {
        //   path: '*',
        //   element: <Navigate to={DefaultPath} replace />,
        // },
        {
          path: ROUTES.AccessDeniedRoute.path,
          element: <PAGES.AccessDenied />,
        },
        {
          path: paths.activated,
          element: <PAGES.ActivatedPage />,
        },
        {
          element: <PrivateRoute />,
          path: '/',
          children: [
            {
              element: <ClientAppBarLayout />,
              children: [
                {
                  element: (
                    <MainLayout
                      sider={<PlayGroundSider />}
                      bar={<PlayGroundAppBar />}
                    />
                  ),
                  children: [
                    {
                      path: paths.eduPlayground,
                      element: <PAGES.EduPlayground />,
                    },
                    {
                      path: paths.eduPlayground1,
                      element: <PAGES.EduPlayground />,
                    },
                    {
                      path: paths.eduPlayground2,
                      element: <PAGES.EduPlayground />,
                    },
                    {
                      path: paths.eduPlayground3,
                      element: <PAGES.EduPlayground />,
                    },
                    {
                      path: paths.eduPlayground4,
                      element: <PAGES.EduPlayground />,
                    },
                    {
                      path: ROUTES.ProfileRoute.path,
                      element: <PAGES.ProfilePage />,
                    },
                    {
                      path: paths.help,
                      element: <PAGES.Help />,
                    },
                  ],
                },
              ],
            },
            {
              path: paths.apps,
              element: <PAGES.Apps />,
            },

            {
              element: <ClientAppBarLayout />,
              children: [
                {
                  element: <MainLayout sider={<ClientSettingsSider />} />,
                  path: paths.adminBase,
                  children: [
                    {
                      path: ROUTES.AdminChatRoute.path,
                      element: <PAGES.AdminUsers />,
                    },
                    {
                      path: ROUTES.AdminTenantsRoute.path,
                      element: <PAGES.AdminTenants />,
                    },
                  ],
                },
              ],
            },
            {
              element: <ClientAppBarLayout />,
              children: [
                {
                  element: <MainLayout sider={<UserChatSider />} />,
                  path: paths.userBase,
                  children: [
                    {
                      path: ROUTES.UserChatRoute.path,
                      element: <PAGES.ChatPage />,
                    },
                  ],
                },
              ],
            },
            {
              element: <AppBarLayout />,
              path: paths.clientBase,
              children: [
                {
                  element: <MainLayout sider={<ClientDashboardSider />} />,
                  path: paths.clientDashboard,
                  children: [
                    {
                      path: ROUTES.AssistantsRoute.path,
                      element: <PAGES.AssistantsPage />,
                    },
                    {
                      path: ROUTES.BusinessFilesRoute.path,
                      element: <PAGES.BusinessFilesPage />,
                    },
                  ],
                },
                {
                  path: paths.clientSettings,
                  element: <MainLayout sider={<ClientSettingsSider />} />,
                  children: [
                    {
                      path: ROUTES.OrganizationRoute.path,
                      element: <PAGES.OrganizationPage />,
                    },
                    {
                      path: ROUTES.BillingRoute.path,
                      element: <PAGES.BillingPage />,
                    },
                    {
                      path: ROUTES.BusinessRoute.path,
                      element: <PAGES.BusinessPage />,
                    },
                    {
                      path: ROUTES.MonitoringRoute.path,
                      element: <PAGES.MonitoringPage />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ])
}

export const getNavBarItems = (t: TFunction<'translation', undefined>) => {
  const items: ItemType<MenuItemType>[] = [
    {
      key: paths.eduPlayground,
      label: t('pages.playGround.title'),
    },
    {
      key: paths.profile,
      label: t('pages.profile.title'),
    },
    {
      key: paths.help,
      label: t('pages.help.title'),
    },
  ]
  return items
}
