import { Col, Result, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../app/hooks'
import Page from '../components/Page'
import { AccessDeniedRoute } from '../routes'

export default function AccessDenied() {
  const { accessToken } = useAppSelector((state) => state.auth)
  const { t } = useTranslation()

  return (
    <Page title={t(AccessDeniedRoute.title)}>
      <Row>
        <Col span={24}>
          <Result
            status="403"
            title="Извините, у вас нет доступа к этой странице."
            subTitle="Пожалуйста, свяжитесь с администратором, чтобы получить доступ."
          />
        </Col>
      </Row>
    </Page>
  )
}
