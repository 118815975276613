import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Input, Select, Space } from 'antd'
import { Header } from 'antd/es/layout/layout'
import type { ButtonProps, InputRef } from 'antd/lib'
import type { CSSProperties } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import type { CategoryResponse } from '../../redux/api/edu/category'
import {
  useCategoryCreateMutation,
  useLazyCategoryGetManyQuery,
} from '../../redux/api/edu/category'
import type { SubcategoryResponse } from '../../redux/api/edu/sub_category'
import {
  useLazySubcategoryListByCategoryQuery,
  useSubcategoryCreateMutation,
} from '../../redux/api/edu/sub_category'
import { useTeacherDisciplinesListQuery } from '../../redux/api/edu/teachers_discipline'
import { getOnSelect } from '../../utils/onSelect'

import TeacherDisciplineForm from './components/TeacherDisciplineForm'

// import TeacherDisciplineForm from './components/TeacherDisciplineForm'

type Props = {}

const AddButton = ({ ...props }: ButtonProps) => {
  return (
    <Button
      size="small"
      icon={<PlusOutlined style={{ width: 10 }} />}
      style={{ width: '20px', height: '25px', borderRadius: 5, margin: 3 }}
      {...props}
    />
  )
}

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: 10,
        border: '2px solid #d9d9d9', // Match the default antd border color
        padding: '5px',
        background: '#fff', // Match the background of the Select
        marginInline: '10px',
      }}
    >
      {children}
    </div>
  )
}

export default function PlayGroundAppBar({}: Props) {
  const handleChange = () => {}
  const [teacherDisciplineFormOpen, setTeacherDisciplineFormOpen] =
    useState(false)
  const { data: teacherDisciplines } = useTeacherDisciplinesListQuery()
  const navigate = useNavigate()
  const { teacher_discipline_id, category_id, subcategory_id, order_id } =
    useParams()

  const [categoryTitle, setCategoryTitle] = useState('')
  const [subCategoryTitle, setSubCategoryTitle] = useState('')
  const inputRef = useRef<InputRef>(null)

  const [categoryCreate] = useCategoryCreateMutation()
  const [subCategoryCreate, { isSuccess: subCategoryisSuccess }] =
    useSubcategoryCreateMutation()

  const [getCategories] = useLazyCategoryGetManyQuery()
  const [categories, setCategories] = useState<CategoryResponse[]>([])

  useEffect(() => {
    if (teacher_discipline_id) {
      getCategories({ teachersDisciplineId: teacher_discipline_id })
        .unwrap()
        .then((cats) => setCategories(cats))
    } else {
      setCategories([])
    }
  }, [teacher_discipline_id])

  const { t } = useTranslation()
  const selectStyle: CSSProperties = { width: '300px' }
  const [subCategoryGetMany] = useLazySubcategoryListByCategoryQuery()
  const [subCategories, setSubCategories] = useState<SubcategoryResponse[]>([])

  useEffect(() => {
    if (category_id) {
      subCategoryGetMany({ categoryId: category_id })
        .unwrap()
        .then((subs) => setSubCategories(subs))
    } else {
      setSubCategories([])
    }
  }, [category_id, subCategoryisSuccess])

  const onSelect = getOnSelect(
    navigate,
    teacher_discipline_id,
    category_id,
    subcategory_id,
  )

  return (
    <Header
      style={{
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between',
        padding: 0,
        margin: 0,
        // backgroundColor: 'white',
      }}
      className="App-header"
    >
      <Wrapper>
        <Select
          // defaultValue="lucy"
          style={selectStyle}
          value={teacher_discipline_id}
          allowClear
          onChange={onSelect('discipline')}
          options={teacherDisciplines?.map(
            ({
              id,
              discipline: { en, kz, ru, id: disciplineId, title },
              user_id,
              categories,
            }) => ({
              value: id,
              key: id,
              label: en,
            }),
          )}
        />
        <AddButton onClick={() => setTeacherDisciplineFormOpen(true)} />
      </Wrapper>
      <Wrapper>
        <Select
          value={category_id}
          allowClear
          onChange={onSelect('category')}
          style={selectStyle}
          options={categories?.map(({ id, title }) => ({
            value: id,
            label: title,
          }))}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space
                style={{
                  padding: '0 8px 4px',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Input
                  placeholder={t('')}
                  ref={inputRef}
                  value={categoryTitle}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCategoryTitle(event.target.value)
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
                  ) => {
                    e.preventDefault()
                    if (teacher_discipline_id)
                      categoryCreate({
                        categoryBody: {
                          teachers_discipline_id: teacher_discipline_id,
                          title: categoryTitle,
                        },
                      })
                    setCategoryTitle('')
                    setTimeout(() => {
                      inputRef.current?.focus()
                    }, 0)
                  }}
                ></Button>
              </Space>
            </>
          )}
        />
      </Wrapper>
      <Wrapper>
        <Select
          value={subcategory_id}
          onChange={onSelect('subCategory')}
          style={selectStyle}
          allowClear
          options={subCategories?.map(
            ({ category_id, id, title, teachers_discipline_id }) => ({
              value: id,
              label: title,
            }),
          )}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space
                style={{
                  padding: '0 8px 4px',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <Input
                  placeholder={t('')}
                  ref={inputRef}
                  value={subCategoryTitle}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSubCategoryTitle(event.target.value)
                  }}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button
                  type="text"
                  icon={<PlusOutlined />}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
                  ) => {
                    e.preventDefault()
                    if (category_id && teacher_discipline_id)
                      subCategoryCreate({
                        subcategoryBody: {
                          category_id,
                          teachers_discipline_id: teacher_discipline_id,
                          title: subCategoryTitle,
                        },
                      })
                    setSubCategoryTitle('')
                    setTimeout(() => {
                      inputRef.current?.focus()
                    }, 0)
                  }}
                ></Button>
              </Space>
            </>
          )}
        />
      </Wrapper>
      <TeacherDisciplineForm
        open={teacherDisciplineFormOpen}
        setOpen={setTeacherDisciplineFormOpen}
      />
      {/* <ProjectBreadcrumbs />
          <ClientNavbar /> */}
    </Header>
  )
}
