import { assistantApi as api } from './index'
export const addTagTypes = ['thread'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      threadCreate: build.mutation<ThreadCreateApiResponse, ThreadCreateApiArg>(
        {
          query: (queryArg) => ({
            url: `/assistant/thread/`,
            method: 'POST',
            body: queryArg.threadBody,
          }),
          invalidatesTags: ['thread'],
        }
      ),
      threadUpdate: build.mutation<ThreadUpdateApiResponse, ThreadUpdateApiArg>(
        {
          query: (queryArg) => ({
            url: `/assistant/thread/${queryArg.assistantId}/${queryArg.threadId}`,
            method: 'PUT',
            body: queryArg.threadUpdateBody,
          }),
          invalidatesTags: ['thread'],
        }
      ),
      threadGetChatResponse: build.query<
        ThreadGetChatResponseApiResponse,
        ThreadGetChatResponseApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/thread/${queryArg.assistantId}/${queryArg.threadId}/chat_response`,
        }),
        providesTags: ['thread'],
      }),
      threadGetMany: build.query<ThreadGetManyApiResponse, ThreadGetManyApiArg>(
        {
          query: (queryArg) => ({
            url: `/assistant/thread/${queryArg.assistantId}/${queryArg.userId}/`,
          }),
          providesTags: ['thread'],
        }
      ),
      threadHistory: build.query<ThreadHistoryApiResponse, ThreadHistoryApiArg>(
        {
          query: (queryArg) => ({
            url: `/assistant/thread/${queryArg.assistantId}/${queryArg.threadId}/history`,
          }),
          providesTags: ['thread'],
        }
      ),
      threadJson: build.query<ThreadJsonApiResponse, ThreadJsonApiArg>({
        query: (queryArg) => ({
          url: `/assistant/thread/${queryArg.assistantId}/${queryArg.threadId}/json`,
        }),
        providesTags: ['thread'],
      }),
      threadGetAssistantUsers: build.query<
        ThreadGetAssistantUsersApiResponse,
        ThreadGetAssistantUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/thread/${queryArg.assistantId}/`,
        }),
        providesTags: ['thread'],
      }),
      threadDelete: build.mutation<ThreadDeleteApiResponse, ThreadDeleteApiArg>(
        {
          query: (queryArg) => ({
            url: `/assistant/thread/${queryArg.assistantId}/${queryArg.threadId}/`,
            method: 'DELETE',
          }),
          invalidatesTags: ['thread'],
        }
      ),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as threadApi }
export type ThreadCreateApiResponse =
  /** status 200 Successful Response */ ChatThreadResponse
export type ThreadCreateApiArg = {
  threadBody: ThreadBody
}
export type ThreadUpdateApiResponse =
  /** status 200 Successful Response */ ThreadResponse
export type ThreadUpdateApiArg = {
  assistantId: string
  threadId: string
  threadUpdateBody: ThreadUpdateBody
}
export type ThreadGetChatResponseApiResponse =
  /** status 200 Successful Response */ ChatThreadResponse
export type ThreadGetChatResponseApiArg = {
  assistantId: string
  threadId: string
}
export type ThreadGetManyApiResponse =
  /** status 200 Successful Response */ ThreadResponse[]
export type ThreadGetManyApiArg = {
  assistantId: string
  userId: string
}
export type ThreadHistoryApiResponse = /** status 200 Successful Response */ any
export type ThreadHistoryApiArg = {
  assistantId: string
  threadId: string
}
export type ThreadJsonApiResponse =
  /** status 200 Successful Response */ ThreadJsonResponse
export type ThreadJsonApiArg = {
  assistantId: string
  threadId: string
}
export type ThreadGetAssistantUsersApiResponse =
  /** status 200 Successful Response */ UserThreads[]
export type ThreadGetAssistantUsersApiArg = {
  assistantId: string
}
export type ThreadDeleteApiResponse = /** status 200 Successful Response */ any
export type ThreadDeleteApiArg = {
  assistantId: string
  threadId: string
}
export type VllmParams = {
  /** Max number of tokens generated by the model */
  max_tokens?: number
  /** Model temperature */
  temperature?: number
  /** Float that controls the cumulative probability of the top tokens to consider. Must be in (0, 1]. Set to 1 to consider all tokens. */
  top_p?: number
  /** Integer that controls the number of top tokens to consider. Set to -1 to consider all tokens. */
  top_k?: number
  /** Penalizes new tokens based on their presence in generated text */
  presence_penalty?: number
  /** Penalizes new tokens based on their frequency in generated text */
  frequency_penalty?: number
  /** Penalizes new tokens based on their repetition in generated text */
  repetition_penalty?: number
  /** Whether to use beam search instead of sampling */
  use_beam_search?: boolean
  /** Penalizes sequences based on length in beam search */
  length_penalty?: number
  /** Controls beam search stopping condition */
  early_stopping?: boolean
  /** Number of output sequences that are generated from the prompt. From these best_of sequences, the top n sequences are returned. best_of must be greater than or equal to n. This is treated as the beam width when use_beam_search is True. By default, best_of is set to n. */
  best_of?: number
  /** Controls how many runs to pass */
  max_memory?: number
}
export type ChatThreadResponse = {
  name?: string
  instruction?: string
  user_id: string
  assistant_id: string
  vllm_params: VllmParams
  id: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type ThreadBody = {
  name?: string
  instruction?: string
  user_id: string
  assistant_id: string
  vllm_params?: VllmParams | null
}
export type ThreadStatus = 'ACTIVE' | 'DELETED'
export type AssistantResponse = {
  id: string
  title: string
  description: string | null
}
export type ThreadUserResponse = {
  id: string
  email: string
  username: string
}
export type ThreadResponse = {
  id: string
  name?: string
  instruction?: string
  user_id: string
  assistant_id: string
  status: ThreadStatus
  /** Assistant */
  assistant: AssistantResponse
  /** User */
  user: ThreadUserResponse
}
export type ThreadUpdateBody = {
  name?: string | null
  user_id?: string | null
  assistant_id?: string | null
  vllm_params?: VllmParams | null
}
export type JsonFeedbackResponse = {
  /** Like or dislike */
  like?: boolean | null
  /** Correction suggested by the user */
  improvement?: string | null
}
export type JsonRunResponse = {
  id: string
  /** User's input message */
  query: string
  /** Assistant's response message */
  response?: string | null
  /** Feedback response body */
  feedback?: JsonFeedbackResponse | null
}
export type ThreadJsonResponse = {
  id: string
  name?: string
  instruction?: string
  user_id: string
  assistant_id: string
  runs: JsonRunResponse[]
}
export type ThreadResponse2 = {
  id: string
  name?: string
}
export type UserThreads = {
  id: string
  email: string
  username: string
  threads: ThreadResponse2[]
}
export const {
  useThreadCreateMutation,
  useThreadUpdateMutation,
  useThreadGetChatResponseQuery,
  useLazyThreadGetChatResponseQuery,
  useThreadGetManyQuery,
  useLazyThreadGetManyQuery,
  useThreadHistoryQuery,
  useLazyThreadHistoryQuery,
  useThreadJsonQuery,
  useLazyThreadJsonQuery,
  useThreadGetAssistantUsersQuery,
  useLazyThreadGetAssistantUsersQuery,
  useThreadDeleteMutation,
} = injectedRtkApi
