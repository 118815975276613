import type { TFunction } from 'i18next'

import { ActionDescriptor } from './actionDescriptor'
import { TitleDescriptor } from './titleDescriptor'

export class FormDescriptor {
  domain: string
  t: TFunction
  formPrefix: string
  action: ActionDescriptor
  title: TitleDescriptor
  // delete: TPop

  constructor(domain: string, t: TFunction, formPrefix: string) {
    this.domain = domain
    this.t = t
    this.formPrefix = formPrefix
    this.title = new TitleDescriptor(
      `${this.domain}.${this.formPrefix}`,
      this.t,
    )
    this.action = new ActionDescriptor(
      `${this.domain}.${this.formPrefix}`,
      this.t,
    )

    // this.delete = this.describe('delete')
  }

  // translate(action: string, key: string) {
  //   return (options?: TOptions) => {
  //     return this.t(`${this.domain}.pop.${action}.${key}`, options)
  //   }
  // }

  // describe(action: string) {
  //   return {
  //     question: this.translate(action, 'question'),
  //     confirm: this.translate(action, 'confirm'),
  //     cancel: this.translate(action, 'cancel'),
  //   }
  // }
}
