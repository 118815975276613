import { Col, Form, Modal, Row } from 'antd'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useAppDispatch } from '../../../app/hooks'
import type { IFormBody } from '../../../components/Form/helpers'
import FormInput from '../../../components/Form/Input'
import FormTextArea from '../../../components/Form/TextArea'
import Debug from '../../../components/helpers/Debug'
import type { OrderBody, OrderResponse } from '../../../redux/api/edu/order'
import {
  orderApi,
  useLazyOrderGetQuery,
  useOrderCreateMutation,
  useOrderUpdateMutation,
} from '../../../redux/api/edu/order'
import { getOrderModelDescriptor } from '../../../redux/slices/edu/order'

import LevelSelect from './LevelSelect'
import TaskTypeSelect from './TaskTypeSelect'

type Props = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onClose: () => void
  orderEditId: string | undefined
}

export default function OrdersForm({
  open,
  setOpen,
  onClose,
  orderEditId,
}: Props) {
  type FormBody = IFormBody<Partial<OrderBody>, never>
  const [form] = Form.useForm<FormBody>()
  const { t } = useTranslation()
  const fmd = getOrderModelDescriptor(t)
  const [orderCreate] = useOrderCreateMutation()
  const [orderUpdate] = useOrderUpdateMutation()
  const [order, setOrder] = useState<OrderResponse | undefined>(undefined)
  const [orderGet] = useLazyOrderGetQuery()

  useEffect(() => {
    if (orderEditId) {
      orderGet({ orderId: orderEditId })
        .unwrap()
        .then((resp) => setOrder(resp))
      setOpen(true)
    }
  }, [orderEditId])

  const { teacher_discipline_id, category_id, subcategory_id, order_id } =
    useParams()
  const dispatch = useAppDispatch()

  const handleOk = async () => {
    const { title, user_instructions, user_input_text, level, task_type } =
      form.getFieldsValue()
    console.log(
      teacher_discipline_id,
      title,
      user_instructions,
      category_id,
      subcategory_id,
      task_type,
    )
    if (
      teacher_discipline_id &&
      title &&
      user_instructions &&
      category_id &&
      subcategory_id &&
      task_type
    ) {
      if (order !== undefined) {
        const { data } = await orderUpdate({
          orderId: order._id,
          orderUpdateBody: {
            teachers_discipline_id: teacher_discipline_id,
            category_id,
            subcategory_id: subcategory_id,
            level,
            task_type,
            title,
            user_instructions,
            user_input_text,
          },
        })
        dispatch(orderApi.util.invalidateTags(['order']))
      } else {
        const { data } = await orderCreate({
          orderBody: {
            teachers_discipline_id: teacher_discipline_id,
            category_id,
            subcategory_id: subcategory_id,
            //
            level,
            task_type,
            title,
            user_instructions,
            user_input_text,
          },
        })
        dispatch(orderApi.util.invalidateTags(['order']))
      }
      onClose()
    }
  }

  const handleClose = () => {
    onClose()
    setOrder(undefined)
    form.resetFields()
  }

  const values = Form.useWatch([], form)
  const initialValues: Partial<OrderBody> = {
    category_id: order?.category_id,
    level: order?.level,
    subcategory_id: order?.subcategory_id,
    task_type: order?.task_type,
    teachers_discipline_id: order?.teachers_discipline_id,
    title: order?.title,
    user_input_text: order?.user_input_text,
    user_instructions: order?.user_instructions,
  }

  return (
    <Modal
      title={
        order !== undefined ? fmd.form.title.update() : fmd.form.title.create()
      }
      open={open}
      width={'800px'}
      onOk={handleOk}
      // confirmLoading={confirmLoading}
      onCancel={handleClose}
      okText={''}
      cancelText={''}
      //   okButtonProps={{ title: createBtnText }}
      //   cancelButtonProps={{ title: cancelBtnText }}
      afterOpenChange={() => form.resetFields()}
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        size="small"
        initialValues={initialValues}
      >
        <Debug debug objects={{ initialValues }} />
        <Debug debug objects={{ values }} />
        <Row gutter={30}>
          <Col span={24}>
            <FormInput formField={fmd.fields.title} />
            <Row gutter={15}>
              <Col xs={12}>
                <TaskTypeSelect formField={fmd.fields.task_type} />
              </Col>
              <Col xs={12}>
                <LevelSelect formField={fmd.fields.level} />
              </Col>
            </Row>
            <FormTextArea formField={fmd.fields.user_instructions} />
            <FormTextArea formField={fmd.fields.user_input_text} />
            {/* <FormSelect
              formField={fmd.fields.discipline_id}
              options={
                disciplines
                  ? disciplines.map(({ title, en, id, kz, ru }) => ({
                      key: id,
                      value: id,
                      label: en,
                    }))
                  : []
              }
            /> */}
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
