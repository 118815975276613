import { Form, Select, type FormItemProps } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { SelectProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import { useCountryGetManyQuery } from '../../../redux/api/rb/country'
import type { FormField } from '../../../utils/modelDescriptor/formFields'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
  selectProps?: Omit<SelectProps, 'options' | 'value'>
}

export default function CountrySelect({
  formField,
  itemProps,
  selectProps,
}: Props) {
  const { t } = useTranslation()
  const id = useId()
  const { data } = useCountryGetManyQuery()
  const options: DefaultOptionType[] = data
    ? data.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : []
  return (
    <Form.Item
      id={id}
      name={formField.name}
      rules={formField.rules}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <Select
        placeholder={formField.placeholder}
        options={options}
        allowClear
        {...selectProps}
        style={{
          alignItems: 'left',
          ...selectProps?.style,
        }}
      />
    </Form.Item>
  )
}
