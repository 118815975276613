import { Col, Form, Modal, Row } from 'antd'
import type { FormItemProps } from 'antd/lib'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { IFormBody } from '../../../components/Form/helpers'
import FormInput from '../../../components/Form/Input'
import DebugFormBtn from '../../../components/helpers/DebugFormBtn'
import type { BusinessBody } from '../../../redux/api/business/business'
import {
  useBusinessCreateMutation,
  useBusinessUpdateMutation,
} from '../../../redux/api/business/business'
import { describeFields } from '../../../utils/modelDescriptor/formFields'

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function BusinessCreateForm({ open, setOpen }: Props) {
  const { t } = useTranslation()
  const businessFields = describeFields<keyof BusinessBody>(
    {
      address: {},
      country_id: {},
      description: {},
      ein: {},
      industry: {},
      name: {},
    },
    t,
    'form.business',
  )

  type FormBody = IFormBody<BusinessBody, never>
  const [createBusiness] = useBusinessCreateMutation()
  const [updateBusiness] = useBusinessUpdateMutation()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [form] = Form.useForm<FormBody>()
  const isCreate = true

  useEffect(() => {
    form.setFieldsValue({})
  }, [])

  const cleanup = () => {
    form.setFieldsValue({})
  }

  const handleClose = () => {
    cleanup()
    setOpen(false)
  }

  const handleOk = async () => {
    const { name } = form.getFieldsValue()
    setConfirmLoading(true)
    if (name !== undefined) {
      const {} = await createBusiness({
        businessBody: { name },
      })
      setConfirmLoading(false)
      handleClose()
    }
  }

  const [serverErrors, setServerErrors] = useState<{
    [key in keyof BusinessBody]?: string
  }>({})

  const itemProps = (label: keyof BusinessBody): FormItemProps => ({
    rules: businessFields[label].rules,
    help: serverErrors[label],
    validateStatus: serverErrors[label] ? 'error' : '',
  })

  const createBtnText = t('form.business.actions.create')

  const cancelBtnText = t('components.form.action.cancel')

  return (
    <Modal
      title={t('form.business.title.create')}
      open={open}
      width={'400px'}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleClose}
      okText={createBtnText}
      cancelText={cancelBtnText}
      okButtonProps={{ title: createBtnText }}
      cancelButtonProps={{ title: cancelBtnText }}
    >
      <Form form={form} labelCol={{ span: 24 }} size="small">
        <Row gutter={30}>
          <Col span={12}>
            {/* name */}
            <FormInput
              formField={businessFields.name}
              itemProps={{
                ...itemProps('name'),
              }}
            />
          </Col>
          <Col span={12}></Col>
        </Row>
        <DebugFormBtn form={form} />
      </Form>
    </Modal>
  )
}
