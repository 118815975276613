import { Breadcrumb } from 'antd'
import type { ItemType } from 'antd/es/breadcrumb/Breadcrumb'
import type { MenuProps } from 'antd/lib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from '../../../app/hooks'
import ProntoAvatar from '../../../components/ProntoAvatar'
import BusinessCreateForm from '../../../pages/Business/components/BusinessCreateForm'
import type { BusinessResponse } from '../../../redux/api/auth/user'
import { setBusiness } from '../../../redux/slices/business/business'
import { DefaultPath } from '../../../routes/paths'

type Props = {
  items?: ItemType[]
}

export default function ProjectBreadcrumbs({ items = [] }: Props) {
  const { t } = useTranslation()

  const [businessFormOpen, setBusinessFormOpen] = useState<boolean>(false)
  // const { data } = useBusinessGetManyQuery({ userId })
  const dispatch = useAppDispatch()
  const [businesses, setBusinesses] = useState<BusinessResponse[]>([])

  // useEffect(() => {
  //   if (data !== undefined) {
  //     setBusinesses(data)
  //     if (data.length > 0) {
  //       dispatch(setBusiness({ selectedBusiness: data[0] }))
  //     }
  //   }
  // }, [data])

  const businessesMenu: MenuProps = {
    items: [
      ...businesses.map((business, i) => ({
        key: i.toString(),
        label: (
          <a
            onClick={(e) => {
              e.preventDefault()
              dispatch(setBusiness({ selectedBusiness: business }))
            }}
          >
            {business.name}
          </a>
        ),
      })),
      {
        key: 'sep',
        type: 'divider',
      },
      {
        key: '',
        label: (
          <a
            style={{ fontWeight: 'bold' }}
            onClick={(e) => {
              setBusinessFormOpen(true)
            }}
          >
            {t('business.business.form.actions.create')}
          </a>
        ),
      },
    ],
  }

  return (
    <>
      <BusinessCreateForm
        open={businessFormOpen}
        setOpen={setBusinessFormOpen}
      />
      <Breadcrumb
        style={{
          marginLeft: 10,
        }}
        items={[
          {
            href: DefaultPath,
            title: <ProntoAvatar />,
          },
          ...items,
        ]}
      />
    </>
  )
}
