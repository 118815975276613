export function parseURLParams<T extends string>(
  searchParams: URLSearchParams,
  keys: string[],
): Record<T, string | undefined> {
  const result: Record<string, string | undefined> = {}

  keys.forEach((key) => {
    const value = searchParams.get(key)
    if (value) {
      result[key] = value
    }
  })

  return result
}
