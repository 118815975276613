import { DownOutlined } from '@ant-design/icons'
import { Drawer, Dropdown, Space } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import type {
  ChatThreadResponse,
  ThreadBody,
} from '../../../../redux/api/assistant/thread'
import type { UserResponse } from '../../../../redux/api/auth/auth'
import {
  getThreadModelDescriptor,
  getVLLMParamsModelDescriptor,
  setChatModalOpen,
  setChatModalThread,
} from '../../../../redux/slices/assistant/thread'
import VLLMParamsForm from '../../../Chat/VLLMParamsForm'

import { InfoModal } from './InfoModal'

type Props = {
  thread: ChatThreadResponse | undefined
  chatDelete: () => Promise<void>
  assistantId: string
  user: UserResponse | null
  startChat: (threadBody: ThreadBody) => Promise<ChatThreadResponse | undefined>
}

export default function ChatBage({
  thread,
  chatDelete,
  assistantId,
  user,
  startChat,
}: Props) {
  const [parametersOpen, setParametersOpen] = useState(false)
  const { t } = useTranslation()
  const vllmmd = getVLLMParamsModelDescriptor(t)
  const tmd = getThreadModelDescriptor(t)
  const itemsbtn: ItemType[] = [
    {
      key: 'about',
      label: t('assistant.thread.form.actions.about'),
      onClick: () => {
        dispatch(setChatModalOpen(true))
        dispatch(setChatModalThread(thread))
      },
    },
    {
      key: '2',
      label: vllmmd.form.action.update(),
      onClick: () => setParametersOpen(!parametersOpen),
    },
    // {
    //   key: '3',
    //   label: tmd.form.action.create(),
    //   onClick: async () => dispatch(setChatModalOpen(true)),
    // },
    {
      key: '4',
      label: tmd.form.action.delete(),
      onClick: async () => {
        await chatDelete()
      },
    },
    {
      key: '5',
      label: t('assistant.thread.info.button'),
      onClick: () => setInfoOpen(true),
    },
  ]
  const dispatch = useAppDispatch()
  const [infoOpen, setInfoOpen] = useState(false)
  return (
    <>
      <Dropdown menu={{ items: itemsbtn }}>
        <a onClick={(e) => e.preventDefault()} style={{ maxWidth: '100px' }}>
          <Space>
            {thread?.name}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
      <InfoModal open={infoOpen} setOpen={setInfoOpen} />

      <Drawer
        title="Parameters"
        placement={'right'}
        closable={false}
        onClose={() => setParametersOpen(false)}
        open={parametersOpen}
        // mask={false}
      >
        {thread && (
          <VLLMParamsForm
            vllmParams={thread.vllm_params}
            assistantId={assistantId}
            threadId={thread.id}
            open={parametersOpen}
            md={vllmmd}
            setOpen={setParametersOpen}
          />
        )}
      </Drawer>
    </>
  )
}
