import { QuestionCircleOutlined } from '@ant-design/icons'
import { Form, Modal, Row, Tooltip } from 'antd'
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../../../app/hooks'
import type { IFormBody } from '../../../../components/Form/helpers'
import FormInput from '../../../../components/Form/Input'
import FormTextArea from '../../../../components/Form/TextArea'
import Debug from '../../../../components/helpers/Debug'
import type {
  ChatThreadResponse,
  ThreadBody,
} from '../../../../redux/api/assistant/thread'
import { useThreadCreateMutation } from '../../../../redux/api/assistant/thread'
import type { ModelDescriptor } from '../../../../utils/modelDescriptor'

export const MyTooltip = ({ title }: { title: string }) => (
  <Tooltip placement="topLeft" title={title}>
    <QuestionCircleOutlined />
  </Tooltip>
)

type Props = {
  assistantId: string
  userId: string
  open: boolean
  onClose: () => void
  setOpen: Dispatch<SetStateAction<boolean>>
  md: ModelDescriptor<ThreadBody, keyof ThreadBody>
  onFinish: (values: Required<ThreadBody>) => void
}

export default function ThreadForm({
  assistantId,
  userId,
  open,
  onClose,
  setOpen,
  md,
  onFinish,
}: Props) {
  const { chatThread } = useAppSelector((state) => state.assistant.thread)
  const { t } = useTranslation()

  const initialValues: ThreadBody = {
    assistant_id: assistantId,
    user_id: userId,
    instruction: t('assistant.thread.fields.instruction.default'),
    name: t('assistant.thread.fields.name.default'),
  }
  type FormBody = IFormBody<Required<ThreadBody>, never>
  const [form] = Form.useForm<FormBody>()
  const [threadCreate, { isLoading }] = useThreadCreateMutation()

  useEffect(() => {
    form.resetFields()
  }, [open])

  const [isUpdate, setIsUpdate] = useState(false)

  const renderForm = () => (
    <Form
      initialValues={initialValues}
      form={form}
      disabled={isLoading}
      onFinish={onFinish}
    >
      <Debug objects={{ initialValues }} />
      <FormInput formField={md.fields.name} />
      <FormTextArea formField={md.fields.instruction} />
      <Form.Item name="assistant_id" hidden />
      <Form.Item name="user_id" hidden />
    </Form>
  )

  const renderInfo = (thread: ChatThreadResponse) => <>{thread.instruction}</>

  const updating = chatThread !== undefined && isUpdate
  const creating = chatThread === undefined
  const observing = chatThread !== undefined && !isUpdate

  return (
    <>
      <Modal
        open={open}
        title={
          <Row style={{ justifyContent: 'space-between', marginRight: '30px' }}>
            {chatThread !== undefined
              ? isUpdate
                ? md.form.action.update()
                : chatThread.name
              : md.form.action.create()}
          </Row>
        }
        onOk={updating || creating ? () => form.submit() : () => setOpen(false)}
        onCancel={() => {
          setOpen(false)
          onClose()
          setIsUpdate(false)
        }}
      >
        <Debug objects={{ chatThread, open }} debug />
        {chatThread !== undefined
          ? isUpdate
            ? renderForm()
            : renderInfo(chatThread)
          : renderForm()}
      </Modal>
    </>
  )
}
