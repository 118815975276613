import { DownOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Col, Dropdown, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAssistantCreateMutation } from '../../../redux/api/assistant/assistant'
import type { AssistantResponse } from '../../../redux/api/business/business'
import { getAssistantModelDescriptor } from '../../../redux/slices/assistant/assistant'
import { formatDate } from '../../../utils/time'

type Props = {
  onClick: (assistantId: string | undefined) => void
  assistant: AssistantResponse
  assistants: AssistantResponse[]
  businessId: string
}

export default function AssistantList({
  assistant,
  assistants,
  onClick,
  businessId,
}: Props) {
  const { t } = useTranslation()

  const setAssistant = (key: string) => {
    onClick(key)
  }

  const items: MenuProps['items'] = assistants.map((assistant) => ({
    key: assistant.id,
    onClick: ({ key }) => {
      setAssistant(key)
    },
    label: (
      <a style={{ width: '100%' }}>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              justifyContent: 'flex-start',
            }}
            span={16}
          >
            {assistant.title}
          </Col>
          <Col
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            span={8}
          >
            {formatDate(assistant.created_at)}
          </Col>
        </Row>
      </a>
    ),
  }))
  const [createAssistant] = useAssistantCreateMutation()
  const amd = getAssistantModelDescriptor(t)

  return (
    <Dropdown
      menu={{
        items: [
          ...items,
          {
            key: 'create',
            label: amd.form.action.create(),
            onClick: async () => {
              const { data } = await createAssistant({
                assistantBody: { business_id: businessId },
              })
              if (data !== undefined) {
                setAssistant(data.id)
              }
            },
            style: {
              fontWeight: 'bold',
            },
          },
        ],
      }}
      trigger={['click']}
    >
      <Button
        style={{
          width: '100%',
          justifyContent: 'space-between',
        }}
        type="text"
      >
        <Space style={{ textAlign: 'left' }}>{assistant.title}</Space>
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}
