// ----------------------------------------------------------------------
import { Layout, Menu, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import AccountDropdown from '../../components/AccountDropdown'
import Debug from '../../components/helpers/Debug'
import LanguagePopover from '../../components/LanguagePopover'
import ProntoAvatar from '../../components/ProntoAvatar'
import { getNavBarItems } from '../../Router'
import { gray, light } from '../../theme'

// ----------------------------------------------------------------------

const { Header } = Layout

export default function ClientAppBarLayout() {
  const { isDarkMode } = useAppSelector((state) => state.main)
  const { t } = useTranslation()

  const items = new Array(15).fill(null).map((_, index) => ({
    key: index + 1,
    label: `nav ${index + 1}`,
  }))
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '0px',
          // backgroundColor: 'white',
        }}
        className="App-header"
      >
        <Debug objects={{ pathname }} />
        {/* <ProjectBreadcrumbs /> */}
        <ProntoAvatar style={{ marginLeft: 15 }} />

        <div
          style={{
            display: 'flex',
            gap: '8px',
            flex: 1,
            justifyContent: 'right',
            alignItems: 'center',
          }}
        >
          <Menu
            mode="horizontal"
            selectedKeys={[pathname]}
            items={getNavBarItems(t)}
            onClick={(e) => {
              navigate(e.key)
            }}
            style={{ flex: 1, minWidth: 0, justifyContent: 'right' }}
          />
          <LanguagePopover />
          <AccountDropdown />
        </div>
      </Header>
      <Outlet />
      <Typography
        style={{
          textAlign: 'center',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          position: 'fixed',
          height: 'var(--global-footer-height)',
          backgroundColor: isDarkMode ? gray : light,
          alignContent: 'center',
        }}
      >
        {t('components.footer.title', {
          year: new Date().getFullYear(),
          trademark: t('trademark'),
        })}
      </Typography>
    </Layout>
  )
}
