import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type { OrderBody, OrderResponse } from '../../api/edu/order'
export const getOrderModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<OrderBody & OrderResponse>(
    {
      // id: {},
      title: {},
      teachers_discipline_id: {},
      category_id: {},
      subcategory_id: {},
      task_type: {},
      level: {},
      user_instructions: {},
      user_input_text: {},
      result_text: {},
      completed_at: {},
      status: {},
      _id: {},
      user_id: {},
      response_language: {},
    },
    t,
    'edu.order',
  )
  return nd
}

export interface OrderState {
  editId?: string
}

const initialState: OrderState = {
  editId: undefined,
}

export const orderSlice = createSlice({
  name: 'edu/order',
  initialState,
  reducers: {
    orderSetEditId: (
      state,
      { payload: editId }: PayloadAction<string | undefined>,
    ) => {
      state.editId = editId
    },
  },
})

export const { orderSetEditId } = orderSlice.actions
export default orderSlice.reducer
