import { Button, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography
function CollaborationSection() {
  const { t } = useTranslation()
  return (
    <section
      id="collaboration"
      style={{
        padding: '5rem 0',
        background: 'var(--violet)',
        color: 'white',
        textAlign: 'center',
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 1.5rem',
        }}
      >
        <Title
          level={2}
          style={{
            fontWeight: 'bold',
            fontSize: '1.875rem',
            marginBottom: '1rem',
            color: 'white',
          }}
        >
          {t('pages.landing.collaboration.title')}
        </Title>
        <Paragraph
          style={{ fontSize: '1.125rem', marginBottom: '2rem', color: 'white' }}
        >
          {t('pages.landing.collaboration.description')}
        </Paragraph>
        <Button
          type="primary"
          style={{
            background: 'orange',
            border: 'none',
            padding: '1rem 2rem',
            fontSize: '1.125rem',
            borderRadius: '0.375rem',
          }}
          href="#contact"
          onMouseEnter={(e) => (e.currentTarget.style.background = '#EA580C')}
          onMouseLeave={(e) => (e.currentTarget.style.background = 'orange')}
        >
          {t('pages.landing.collaboration.action')}
        </Button>
      </div>
    </section>
  )
}

export default CollaborationSection
