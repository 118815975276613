import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  TeachersDisciplineBody,
  TeachersDisciplineResponse,
} from '../../api/edu/teachers_discipline'
export const getTeacherDisciplineModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<
    TeachersDisciplineBody & TeachersDisciplineResponse
  >(
    {
      id: {},
      user_id: {},
      discipline_id: {},
      categories: {},
      discipline: {},
    },
    t,
    'edu.teacherDiscipline',
  )
  return nd
}
