import type { TFunction, TOptions } from 'i18next'

export class ActionDescriptor {
  domain: string
  t: TFunction
  constructor(domain: string, t: TFunction) {
    this.domain = domain
    this.t = t
  }

  update(options?: TOptions) {
    return this.t(`${this.domain}.actions.update`, options)
  }

  create(options?: TOptions) {
    return this.t(`${this.domain}.actions.create`, options)
  }

  delete(options?: TOptions) {
    return this.t(`${this.domain}.actions.delete`, options)
  }

  describe(action: string, options?: TOptions) {
    return this.t(`${this.domain}.actions.${action}`, options)
  }
}
