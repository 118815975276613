import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { FormInstance } from 'antd/lib'
import type { TFunction } from 'i18next'

import type { IFormBody } from '../../components/Form/helpers'
import { is400Error } from '../form'
import { parseBool, parseDate } from '../parsers'
import { parseURLParams } from '../url'

import { FormDescriptor } from './formDescriptor'
import type { FormField, FormFields, TDesc } from './formFields'
import { FieldDescriptor } from './formFields'
import { PopDescriptor } from './popDescriptor'
import { ToastDescriptor } from './toastDescriptor'

type Filters<T extends Record<string, any>> = {
  [K in keyof T]: T[K] | undefined
} & {
  page: number | undefined
}

export class ModelDescriptor<
  TBody extends Record<string, any>,
  T extends string = Extract<keyof TBody, string>,
> {
  fields: FormFields<T>
  domain: string
  toast: ToastDescriptor
  pop: PopDescriptor
  form: FormDescriptor
  t: TFunction
  keys: TDesc<T>
  formPrefix: string
  tablePrefix: string
  name: string

  constructor(
    keys: TDesc<T>,
    t: TFunction,
    domain: string,
    formPrefix: string = 'form',
    tablePrefix: string = 'table',
  ) {
    this.fields = this.describeFields<T>(keys, t, domain)
    this.toast = new ToastDescriptor(`${domain}.${formPrefix}`, t)
    this.domain = domain
    this.pop = new PopDescriptor(domain, t)
    this.form = new FormDescriptor(domain, t, formPrefix)
    this.formPrefix = formPrefix
    this.tablePrefix = tablePrefix
    this.name = t(`${domain}.name`)

    this.t = t
    this.keys = keys
  }

  handleErrors(
    form: FormInstance<IFormBody<any>>,
    queryErrors: FetchBaseQueryError | SerializedError | undefined,
  ) {
    const ibr = is400Error<Object>(queryErrors, Object.keys(this.fields))
    console.log(ibr)
    if (ibr) {
      for (const [name, errorKeys] of Object.entries(
        queryErrors.data.message,
      )) {
        form.setFields([
          {
            name,
            errors: errorKeys.map((errorKey: string) =>
              this.fields[name as T].get_error(errorKey),
            ),
          },
        ])
      }
    }
  }

  useFilters(searchParams: URLSearchParams): Filters<TBody> {
    const parsedFilters = parseURLParams<T | 'page'>(searchParams, [
      'page',
      Object.keys(this.fields) as any,
    ]) as any

    var filters: Filters<TBody> = {
      page:
        parsedFilters.page !== undefined ? parseInt(parsedFilters.page, 10) : 1,
    } as any

    for (const [key, value] of Object.entries(this.fields)) {
      const fRule = value as FormField<T>
      const urlVal: string | undefined = parsedFilters[key]
      var v
      switch (fRule.filter.type) {
        case 'boolean':
          v = urlVal !== undefined ? parseBool(urlVal) : fRule.filter.default
        case 'date':
          v = urlVal !== undefined ? parseDate(urlVal) : fRule.filter.default
        case 'float':
          v = urlVal !== undefined ? parseFloat(urlVal) : fRule.filter.default
        case 'int':
          v = urlVal !== undefined ? parseInt(urlVal) : fRule.filter.default
        case 'string':
          v = urlVal !== undefined ? urlVal : fRule.filter.default
      }
      filters[key as T] = v as any
    }

    return filters as Filters<TBody>
  }

  describeFields<T extends string>(
    keys: TDesc<T>,
    t: TFunction,
    domain: string,
  ): FormFields<T> {
    const fieldDescriptor = new FieldDescriptor<T>(t, domain)

    return Object.keys(keys).reduce(
      (acc, key) => {
        acc[key as T] = fieldDescriptor.description(
          key as T,
          {
            required: keys[key as T].rules?.required,
            email: keys[key as T].rules?.email,
          },
          keys[key as T].filter || {},
        )
        return acc
      },
      {} as { [key in T]: FormField<key> },
    )
  }
}
