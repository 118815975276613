import { Button, Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography

function SubscriptionPlans() {
  const { t } = useTranslation()

  const plans = [
    {
      name: t('pages.landing.subscription.plans.basic.name'),
      price: t('pages.landing.subscription.plans.basic.name'),
      features: t('pages.landing.subscription.plans.basic.features', {
        returnObjects: true,
      }) as string[],
      cta: t('pages.landing.subscription.plans.basic.cta'),
    },
    {
      name: t('pages.landing.subscription.plans.plus.name'),
      price: t('pages.landing.subscription.plans.plus.name'),
      features: t('pages.landing.subscription.plans.plus.features', {
        returnObjects: true,
      }) as string[],
      cta: t('pages.landing.subscription.plans.plus.cta'),
    },
  ]
  return (
    <section
      id="pricing"
      style={{
        padding: '5rem 0', // Approximate Tailwind py-20
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 1.5rem', // Approx. px-6
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
          <Title
            level={2}
            style={{
              color: 'rgb(85 37 130 / var(--tw-bg-opacity, 1))',
              fontWeight: 'bold',
              fontSize: '1.875rem', // Approx. text-3xl
              marginBottom: 0,
            }}
          >
            {t('pages.landing.subscription.title')}
          </Title>
          <Paragraph style={{ color: '#4B5563', marginTop: '1rem' }}>
            {t('pages.landing.subscription.description')}
          </Paragraph>
        </div>

        <Row gutter={[24, 32]} justify="center">
          {plans.map((plan, index) => (
            <Col key={index} xs={24} md={8}>
              <div
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '0.5rem',
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    padding: '1.5rem',
                    background: 'var(--violet)',
                    color: 'white',
                    textAlign: 'center',
                  }}
                >
                  <Title
                    level={3}
                    style={{
                      fontWeight: '600',
                      fontSize: '1.5rem', // Approx. text-2xl
                      marginBottom: '0.5rem',
                      color: 'white',
                    }}
                  >
                    {plan.name}
                  </Title>
                  <Paragraph
                    style={{
                      fontSize: '1.25rem', // Approx. text-xl
                      margin: 0,
                      color: 'white',
                    }}
                  >
                    {plan.price}
                  </Paragraph>
                </div>
                <ul
                  style={{
                    padding: '1.5rem',
                    listStyle: 'none',
                    margin: 0,
                  }}
                >
                  {plan.features.map((feature, idx) => (
                    <li
                      key={idx}
                      style={{
                        marginBottom: '0.5rem',
                        color: '#374151', // Approx. text-gray-700
                      }}
                    >
                      {feature}
                    </li>
                  ))}
                </ul>
                <div style={{ padding: '1.5rem', textAlign: 'center' }}>
                  <Button
                    style={{
                      background: 'orange',
                      color: 'white',
                      padding: '0.5rem 1.5rem',
                      borderRadius: '0.375rem',
                      border: 'none',
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.background = '#EA580C')
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.background = 'orange')
                    }
                  >
                    {plan.cta}
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  )
}

export default SubscriptionPlans
