import type { IconProps, IconifyIcon } from '@iconify/react'
import { Icon } from '@iconify/react'
// ----------------------------------------------------------------------

interface Props extends IconProps {
  icon: string | IconifyIcon
}

// https://icon-sets.iconify.design/
export default function Iconify({ icon, style, ...other }: Props) {
  return <Icon icon={icon} style={{ ...style }} {...other} />
}
