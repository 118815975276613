import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  AssistantBody,
  AssistantResponse,
} from '../../api/assistant/assistant'

export const getAssistantModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<AssistantBody & AssistantResponse>(
    {
      min_score: {},
      business_id: {},
      description: {},
      language: {},
      title: {},
      created_at: {},
      thread_count: {},
      id: {},
      status: {},
      updated_at: {},
      max_docs: {},
    },
    t,
    'assistant.assistant',
  )
  return nd
}
