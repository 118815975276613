import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  ChatThreadResponse,
  ThreadBody,
  ThreadResponse,
  VllmParams,
} from '../../api/assistant/thread'
export const getThreadModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<ThreadBody & ThreadResponse>(
    {
      id: {},
      user_id: {},
      assistant_id: {},
      assistant: {},
      user: {},
      name: {},
      status: {},
      vllm_params: {},
      instruction: {},
    },
    t,
    'assistant.thread',
  )
  return nd
}

export const getVLLMParamsModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<VllmParams>(
    {
      best_of: {},
      max_tokens: {},
      temperature: {},
      top_p: {},
      top_k: {},
      presence_penalty: {},
      frequency_penalty: {},
      repetition_penalty: {},
      use_beam_search: {},
      length_penalty: {},
      early_stopping: {},
      max_memory: {},
    },
    t,
    'assistant.vllm_params',
  )
  return nd
}

export interface AuthState {
  chatModalOpen: boolean
  threads: ThreadResponse[]
  chatThread: ChatThreadResponse | undefined
}

const initialState: AuthState = {
  threads: [],
  chatThread: undefined,
  chatModalOpen: false,
}

export const authSlice = createSlice({
  name: 'auth/auth',
  initialState,
  reducers: {
    setThreads: (
      state,
      { payload: threads }: PayloadAction<ThreadResponse[]>,
    ) => {
      state.threads = threads
    },
    setChatModalThread: (
      state,
      { payload: thread }: PayloadAction<ChatThreadResponse | undefined>,
    ) => {
      state.chatThread = thread
    },
    setChatModalOpen: (state, { payload: open }: PayloadAction<boolean>) => {
      state.chatModalOpen = open
    },
  },
})

export const { setThreads, setChatModalThread, setChatModalOpen } =
  authSlice.actions
export default authSlice.reducer
