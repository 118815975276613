import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

function PromotionalBanner() {
  const { t } = useTranslation()
  return (
    <div
      style={{
        backgroundColor: 'orange',
        color: 'white',
        textAlign: 'center',
        padding: '16px',
      }}
    >
      <p style={{ fontSize: '18px', fontWeight: '600' }}>
        {t('pages.landing.promotion.offer')}
      </p>
      <Button
        href="#"
        style={{
          backgroundColor: 'white',
          color: 'orange',
          padding: '8px 24px',
          borderRadius: '4px',
          marginTop: '8px',
          display: 'inline-block',
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = '#f5f5f5')
        }
        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
      >
        {t('pages.landing.promotion.action')}
      </Button>
    </div>
  )
}

export default PromotionalBanner
