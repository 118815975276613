import { Button, Col, Form, Input, Row } from 'antd'
import type { FormInstance } from 'antd/lib'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
// ----------------------------------------------------------------------

import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import type { IFormBody } from '../../../components/Form/helpers'
import Debug from '../../../components/helpers/Debug'
// import ProntoTitle from '../../components/ProntoTitle'
import type { BodyLoginAuthAuthLoginPost } from '../../../redux/api/auth/auth'
import { useLoginMutation } from '../../../redux/api/auth/auth'
import {
  getUserModelDescriptor,
  setUser,
} from '../../../redux/slices/auth/userSlice'
import { DefaultPath, paths } from '../../../routes/paths'
import { buildFormDataBody } from '../../../utils/form'
// ----------------------------------------------------------------------

type Props = {
  form: FormInstance<IFormBody<Required<BodyLoginAuthAuthLoginPost>, never>>
  onSuccess: () => void
  onSignUp: () => void
}

export default function LoginForm({ form, onSuccess, onSignUp }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { accessToken, user } = useAppSelector((state) => state.auth)
  const [login, { error, isLoading }] = useLoginMutation()
  const dispatch = useAppDispatch()

  type FormBody = IFormBody<Required<BodyLoginAuthAuthLoginPost>, never>
  // const [form] = Form.useForm<FormBody>()

  const loginMD = getUserModelDescriptor(t, 'loginForm')

  useEffect(() => {
    if (error !== undefined) {
      // console.log(error)
      loginMD.handleErrors(form as any, error)
    }
  }, [error])

  // if (accessToken) {
  //   return <Navigate to={DefaultPath} />
  // }

  const onFinish = async (values: Required<BodyLoginAuthAuthLoginPost>) => {
    const bodyLoginAuthAuthLoginPost =
      buildFormDataBody<BodyLoginAuthAuthLoginPost>({
        username: values.username,
        password: values.password,
      })

    try {
      const { access_token, refresh_token, user } = await login({
        bodyLoginAuthAuthLoginPost,
      }).unwrap()
      dispatch(
        setUser({
          accessToken: access_token,
          refreshToken: refresh_token,
          user,
        }),
      )

      navigate(
        location.state?.from.pathname !== DefaultPath
          ? location.state?.from.pathname
          : DefaultPath,
      )
      onSuccess()
      navigate(paths.eduPlayground)
    } catch (error) {
      // toast.error('Не правильно введен логин или пароль')
      console.error((error as any).data.detail)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Form
      name="basic"
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      disabled={isLoading}
      initialValues={{
        username: '',
        password: '',
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row>
        <Debug
          debug
          objects={{
            accessToken,
            user,
            accessTokenLocalStorage: localStorage.getItem('accessToken'),
          }}
        />
        <Col span={24}>
          <div style={{}}>
            <Form.Item
              name={loginMD.fields.username.name}
              wrapperCol={{
                style: {
                  justifyContent: 'center',
                },
              }}
              rules={[...loginMD.fields.username.rules]}
            >
              <Input placeholder={t(loginMD.fields.email.placeholder)} />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                style: {
                  justifyContent: 'center',
                },
              }}
              name={loginMD.fields.password.name}
              rules={loginMD.fields.password.rules}
            >
              <Input.Password
                placeholder={t(loginMD.fields.password.placeholder)}
              />
            </Form.Item>

            {/* <Button
                type="primary"
                htmlType="submit"
                block
                loading={isLoading}
                style={{
                  justifyContent: 'center',
                }}
              >
                {t(`${loginMD.form.action.describe('submit')}`)}
              </Button> */}

            {/* <Button type="link" size="small">
                {t(`${loginMD.form.action.describe('forgot')}`)}
              </Button> */}
          </div>
        </Col>
        <Col span={24}>
          {t(`${loginMD.form.action.domain}.actions.signUp.question`)}
          <Button
            type="link"
            size="small"
            disabled={isLoading}
            onClick={onSignUp}
          >
            {t(`${loginMD.form.action.domain}.actions.signUp.button`)}
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
