import { Button, Typography } from 'antd'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import heroBackground from '../assets/back-image-logo.png'

const { Title, Paragraph } = Typography

interface Fractal {
  x: number
  y: number
  vx: number
  vy: number
  size: number
  color: string
}

function HeroSection() {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    const canvas = canvasRef.current
    if (!canvas) return

    const ctx = canvas.getContext('2d')
    if (!ctx) return

    let width = (canvas.width = window.innerWidth)
    let height = (canvas.height = window.innerHeight)

    const colors = ['#FFFFFF', '#F58220', '#552582', '#FF5733']
    let fractals: Fractal[] = []

    function createFractals() {
      fractals = Array.from({ length: 30 }, () => ({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: Math.random() * 2 - 1,
        vy: Math.random() * 2 - 1,
        size: Math.random() * 3 + 1,
        color: colors[Math.floor(Math.random() * colors.length)],
      }))
    }

    const animate = (ctx: CanvasRenderingContext2D) => () => {
      ctx.clearRect(0, 0, width, height)

      for (let i = 0; i < fractals.length; i++) {
        const f = fractals[i]

        // Draw the fractal point
        ctx.beginPath()
        ctx.arc(f.x, f.y, f.size, 0, Math.PI * 2, false)
        ctx.fillStyle = f.color
        ctx.fill()

        // Update position
        f.x += f.vx
        f.y += f.vy

        // Bounce from edges
        if (f.x < 0 || f.x > width) f.vx *= -1
        if (f.y < 0 || f.y > height) f.vy *= -1

        // Draw connecting lines
        for (let j = i + 1; j < fractals.length; j++) {
          const other = fractals[j]
          const dist = Math.hypot(f.x - other.x, f.y - other.y)
          if (dist < 150) {
            ctx.beginPath()
            ctx.moveTo(f.x, f.y)
            ctx.lineTo(other.x, other.y)
            ctx.strokeStyle = f.color
            ctx.lineWidth = 0.2
            ctx.stroke()
          }
        }
      }

      requestAnimationFrame(animate(ctx))
    }

    createFractals()
    animate(ctx)()

    const handleResize = () => {
      width = window.innerWidth
      height = window.innerHeight
      canvas.width = width
      canvas.height = height
      fractals = []
      createFractals()
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const { t } = useTranslation()

  return (
    <>
      <section
        id="home"
        style={{
          position: 'relative',
          backgroundImage: `url(${heroBackground})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
      >
        {/* Static Gradient Overlay */}
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: 'linear-gradient(to right, violet, orange)',
            opacity: 0.9,
          }}
        />

        {/* Canvas for Fractals */}
        <canvas
          ref={canvasRef}
          style={{
            position: 'absolute',
            inset: 0,
            pointerEvents: 'none',
            zIndex: 0,
          }}
        />

        {/* Content */}
        <div
          style={{
            position: 'relative',
            zIndex: 10,
            textAlign: 'center',
            color: '#ffffff',
            padding: '10rem 1.5rem', // Approximate py-40 px-6 from Tailwind
            maxWidth: '1200px',
            margin: '0 auto',
          }}
        >
          <Title
            level={1}
            style={{
              fontSize: '3rem', // Approx. "text-4xl/md:text-6xl"
              fontWeight: 'bold',
              color: 'white',
              marginBottom: '1rem',
            }}
          >
            {t('pages.landing.hero.title')}
          </Title>
          <Paragraph
            style={{
              color: 'white',
              fontSize: '1.25rem', // Approx. "text-xl"
              marginBottom: '2rem', // Approx. "mb-8"
            }}
          >
            {t('pages.landing.hero.subTitle')}
          </Paragraph>
          <Button type="primary" size="large">
            {t('pages.landing.hero.action')}
          </Button>
        </div>
      </section>
    </>
  )
}

export default HeroSection
