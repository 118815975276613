export default {
  fields: {
    id: {
      label: 'Чат идентификаторы',
      placeholder: 'Чаттың бірегей идентификаторын енгізіңіз',
    },
    user_id: {
      label: 'Пайдаланушы идентификаторы',
      placeholder: 'Пайдаланушының UUID-ін енгізіңіз',
    },
    name: {
      label: 'Атауы',
      placeholder: 'Чаттың атауын енгізіңіз',
      default: 'Сіздің чатыңыз',
    },
    instruction: {
      label: 'Нұсқаулық',
      placeholder: 'Чатқа арналған нұсқаулықты енгізіңіз',
      default:
        'Сіз сұрақтарға жауап беретін және ақпарат беретін сенімді AI көмекшісісіз.',
    },
    assistant_id: {
      label: 'Көмекші идентификаторы',
      placeholder: 'Көмекшінің UUID-ін енгізіңіз',
    },
    assistant: {
      label: 'Көмекші',
      placeholder: 'Көмекшінің аты немесе идентификаторын енгізіңіз',
    },
    user: {
      label: 'Пайдаланушы',
      placeholder: 'Пайдаланушының аты немесе идентификаторын енгізіңіз',
    },
    status: {
      label: 'Статус',
      placeholder: 'Чаттың ағымдағы статусын енгізіңіз',
    },
    max_tokens: {
      label: 'Максималды токендер',
      placeholder: 'Генерацияға арналған токендер санын енгізіңіз',
    },
    temperature: {
      label: 'Температура',
      placeholder:
        'Кездейсоқтық деңгейін енгізіңіз (мысалы, 0.0-ден 1.0-ге дейін)',
    },
    top_p: {
      label: 'Top P',
      placeholder:
        'Top-p таңдау мәнін енгізіңіз (мысалы, 0.0-ден 1.0-ге дейін)',
    },
    top_k: {
      label: 'Top K',
      placeholder: 'Top-k шектеуін енгізіңіз',
    },
    presence_penalty: {
      label: 'Қатысу жазасы',
      placeholder: 'Қатысу жазасының коэффициентін енгізіңіз',
    },
    frequency_penalty: {
      label: 'Қайталану жазасы',
      placeholder: 'Қайталану жазасының коэффициентін енгізіңіз',
    },
    repetition_penalty: {
      label: 'Қайталану жазасы',
      placeholder: 'Қайталану жазасының коэффициентін енгізіңіз',
    },
    use_beam_search: {
      label: 'Beam Search пайдалану',
      placeholder: 'Beam Search пайдалану керек пе екенін таңдаңыз',
    },
    length_penalty: {
      label: 'Ұзындық жазасы',
      placeholder: 'Ұзындық жазасының коэффициентін енгізіңіз',
    },
    early_stopping: {
      label: 'Ерте тоқтату',
      placeholder: 'Генерацияны ерте тоқтату критерийлерін көрсетіңіз',
    },
  },
  form: {
    create: {
      title: 'Жаңа чат құру',
    },
    update: {
      title: 'Чатты жаңарту',
    },
    actions: {
      create: 'Жаңа чат құру',
      update: 'Чатты жаңарту',
      delete: 'Чатты жою',
      about: 'Чат туралы',
    },
    pop: {
      delete: {
        question: 'Сіз шынымен {{ title }} ({{ id }}) чатын жойғыңыз келе ме?',
        confirm: 'Иә',
        cancel: 'Бас тарту',
      },
    },
  },
  toast: {
    updated: 'Чат сәтті жаңартылды',
    created: 'Чат сәтті құрылды',
    deleted: 'Чат сәтті жойылды',
    error: 'Қате пайда болды',
  },
  info: {
    title: 'Нұсқаулықтар',
    button: 'Нұсқаулықтар',
  },
}
