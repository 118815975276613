import { t } from '../i18n'

import type { InterfaceRouteConfig } from './paths'
import { paths } from './paths'

export const AssistantsRoute: InterfaceRouteConfig = {
  title: t('pages.assistants.title'),
  path: paths.clientAssistants,
}

export const BusinessFilesRoute: InterfaceRouteConfig = {
  title: t('pages.business_files.title'),
  path: paths.clientBuisnessFiles,
}

// settings
export const ProfileRoute: InterfaceRouteConfig = {
  title: t('pages.profile.title'),
  path: paths.profile,
}
export const HelpRoute: InterfaceRouteConfig = {
  title: t('pages.help.title'),
  path: paths.help,
}
export const OrganizationRoute: InterfaceRouteConfig = {
  title: t('pages.organization.title'),
  path: paths.clientOrganization,
}
export const BillingRoute: InterfaceRouteConfig = {
  title: t('pages.billing.title'),
  path: paths.clientBilling,
}
export const BusinessRoute: InterfaceRouteConfig = {
  title: t('pages.business.title'),
  path: paths.clientBusiness,
}
export const MonitoringRoute: InterfaceRouteConfig = {
  title: t('pages.monitoring.title'),
  path: paths.clientMonitoring,
}
// admin
export const AdminUsersRoute: InterfaceRouteConfig = {
  title: t('pages.adminUsers.title'),
  path: paths.adminUsers,
}
