import { eduApi as api } from './index'
export const addTagTypes = ['order'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      orderCreate: build.mutation<OrderCreateApiResponse, OrderCreateApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/`,
          method: 'POST',
          body: queryArg.orderBody,
        }),
        invalidatesTags: ['order'],
      }),
      orderList: build.query<OrderListApiResponse, OrderListApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/`,
          params: {
            subcategory_id: queryArg.subcategoryId,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ['order'],
      }),
      orderGet: build.query<OrderGetApiResponse, OrderGetApiArg>({
        query: (queryArg) => ({ url: `/edu/order/${queryArg.orderId}` }),
        providesTags: ['order'],
      }),
      orderUpdate: build.mutation<OrderUpdateApiResponse, OrderUpdateApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}`,
          method: 'PUT',
          body: queryArg.orderUpdateBody,
        }),
        invalidatesTags: ['order'],
      }),
      orderDelete: build.mutation<OrderDeleteApiResponse, OrderDeleteApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['order'],
      }),
      orderSubmit: build.mutation<OrderSubmitApiResponse, OrderSubmitApiArg>({
        query: (queryArg) => ({
          url: `/edu/order/${queryArg.orderId}/submit`,
          method: 'POST',
        }),
        invalidatesTags: ['order'],
      }),
      orderSoftDelete: build.mutation<
        OrderSoftDeleteApiResponse,
        OrderSoftDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/order/soft/${queryArg.orderId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['order'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as orderApi }
export type OrderCreateApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderCreateApiArg = {
  orderBody: OrderBody
}
export type OrderListApiResponse =
  /** status 200 Successful Response */ OrderResponse[]
export type OrderListApiArg = {
  subcategoryId: string
  limit?: number
  offset?: number
}
export type OrderGetApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderGetApiArg = {
  orderId: string
}
export type OrderUpdateApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderUpdateApiArg = {
  orderId: string
  orderUpdateBody: OrderUpdateBody
}
export type OrderDeleteApiResponse = /** status 200 Successful Response */ any
export type OrderDeleteApiArg = {
  orderId: string
}
export type OrderSubmitApiResponse =
  /** status 200 Successful Response */ OrderResponse
export type OrderSubmitApiArg = {
  orderId: string
}
export type OrderSoftDeleteApiResponse =
  /** status 200 Successful Response */ any
export type OrderSoftDeleteApiArg = {
  orderId: string
}
export type TaskType =
  | 'Questions to be researched'
  | 'Test generation'
  | 'Assignment'
  | 'Essay writing'
  | 'Quiz creation'
  | 'Material preparation'
  | 'Lesson planning'
  | 'Analysis and estimation'
  | 'Exam preparation'
  | 'Pedagogical advice'
export type ComplexityLevel =
  | 'Beginner'
  | 'Intermediate'
  | 'Advanced'
  | 'Expert'
export type Language = 'Kazakh' | 'Russian' | 'English'
export type OrderResponse = {
  title: string
  /** uuid as a string */
  teachers_discipline_id: string
  /** uuid as a string */
  category_id: string
  /** uuid as a string */
  subcategory_id: string
  task_type: TaskType
  level: ComplexityLevel
  user_instructions: string
  user_input_text: string
  /** uuid as a string */
  user_id?: string | null
  result_text?: string | null
  completed_at?: string | null
  status?: string
  /** MongoDB uuid as a string */
  _id: string
  response_language?: Language
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type OrderBody = {
  /** A concise but descriptive name for the order. */
  title: string
  /** The unique identifier of the teacher's associated discipline. */
  teachers_discipline_id: string
  /** The unique identifier of the category within the chosen discipline. */
  category_id: string
  /** The unique identifier of the subcategory within the chosen category. */
  subcategory_id: string
  /** The type of task to be performed (e.g., QUESTION, ASSIGNMENT). */
  task_type: TaskType
  /** The complexity level of the order (e.g., BASIC, INTERMEDIATE, ADVANCED). */
  level?: ComplexityLevel
  response_language?: Language
  /** Instructions provided by the user for this order. */
  user_instructions: string
  /** Optional input text content relevant to the order. */
  user_input_text?: string | null
}
export type OrderStatus =
  | 'Draft'
  | 'Pending'
  | 'In Progress'
  | 'Completed'
  | 'Failed'
  | 'Deleted'
export type OrderUpdateBody = {
  /** A concise but descriptive name for the order. */
  title?: string | null
  /** The unique identifier of the teacher's associated discipline. */
  teachers_discipline_id?: string | null
  /** The unique identifier of the category within the chosen discipline. */
  category_id?: string | null
  /** The unique identifier of the subcategory within the chosen category. */
  subcategory_id?: string | null
  /** The type of task to be performed (e.g., QUESTION, ASSIGNMENT). */
  task_type?: TaskType | null
  /** The complexity level of the order (e.g., BASIC, INTERMEDIATE, ADVANCED). */
  level?: ComplexityLevel | null
  /** Updated instructions provided by the user for this order. */
  user_instructions?: string | null
  /** Optional input text content relevant to the order. */
  user_input_text?: string | null
  /** The updated status of the order (e.g., PENDING, IN_PROGRESS, COMPLETED). */
  status?: OrderStatus | null
  response_language?: Language | null
  /** The output or result produced after processing the order. */
  result_text?: string | null
  /** A system-generated prompt or template guiding the solution of the order. */
  system_prompt?: string | null
}
export const {
  useOrderCreateMutation,
  useOrderListQuery,
  useLazyOrderListQuery,
  useOrderGetQuery,
  useLazyOrderGetQuery,
  useOrderUpdateMutation,
  useOrderDeleteMutation,
  useOrderSubmitMutation,
  useOrderSoftDeleteMutation,
} = injectedRtkApi
