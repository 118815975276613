// ----------------------------------------------------------------------
import { Layout, Typography } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import { gray, light } from '../../theme'

import BusinessCascader from './components/BusinessCascader'
import Navbar from './components/Navbar'
import ProjectBreadcrumbs from './components/ProjectBreadcrumbs'
// ----------------------------------------------------------------------

const { Header } = Layout

export default function AppBarLayout() {
  const { isDarkMode } = useAppSelector((state) => state.main)
  const { t } = useTranslation()
  const [domain, setDomain] = useState<
    [string | undefined, string | undefined]
  >([undefined, localStorage.getItem('businessId') ?? undefined])

  return (
    <Layout>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '0px',
          // backgroundColor: 'white',
        }}
        className="App-header"
      >
        <ProjectBreadcrumbs
          items={[
            {
              title: (
                <BusinessCascader
                  value={domain}
                  onChange={(domain) => {
                    localStorage.setItem('businessId', domain[1] || '')
                    setDomain(domain)
                  }}
                />
              ),
            },
          ]}
        />
        <Navbar />
      </Header>
      <Outlet />

      <Typography
        style={{
          textAlign: 'center',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          position: 'fixed',
          height: 'var(--global-footer-height)',
          backgroundColor: isDarkMode ? gray : light,
          alignContent: 'center',
        }}
      >
        {t('components.footer.title', {
          year: new Date().getFullYear(),
          trademark: t('trademark'),
        })}
      </Typography>
    </Layout>
  )
}
