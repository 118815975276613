import { Avatar } from 'antd'
import type { AvatarProps } from 'antd/lib'
import { Link } from 'react-router-dom'

import logo from '../logo.jpeg'
import { paths } from '../routes/paths'

type Props = AvatarProps

export default function ProntoAvatar({ ...props }: Props) {
  return (
    <Link to={paths.home}>
      <Avatar
        size="small"
        src={logo}
        style={{
          verticalAlign: 'middle',
        }}
        gap={5}
        {...props}
      />
    </Link>
  )
}
