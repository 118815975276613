import type { FormItemProps } from 'antd'
import { Input as ANTDInput, Form } from 'antd'
import type { InputProps } from 'antd/lib/input'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { FormField } from '../../utils/modelDescriptor/formFields'

interface Props {
  itemProps?: FormItemProps
  inputProps?: InputProps
  // https://icon-sets.iconify.design/
  formField: FormField<any>
}

export default function FormInput({
  formField,
  itemProps = {},
  inputProps = {},
}: Props) {
  const { t } = useTranslation()
  const id = useId()
  return (
    <Form.Item
      id={id}
      name={formField?.name}
      rules={formField?.rules}
      hasFeedback={formField?.rules !== undefined}
      // validateTrigger={formField?.rules !== undefined ? 'validateTrigger' : undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <ANTDInput
        name={formField.name}
        placeholder={t(formField.placeholder)}
        {...inputProps}
      />
    </Form.Item>
  )
}
