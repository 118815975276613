import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
export function InfoModal({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const showModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setOpen(false)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const { t } = useTranslation()

  return (
    <Modal
      // title={t('assistant.thread.info.title')}
      open={open}
      onOk={handleOk}
      width={'80vw'}
      onCancel={handleCancel}
    >
      <p>
        <Markdown>{t('assistant.thread.info.content')}</Markdown>
      </p>
    </Modal>
  )
}
