export function updateSearchParams(
  params: Record<string, string | number | string | undefined | null>,
  searchParams: URLSearchParams,
  setSearchParams: (params: URLSearchParams) => void,
) {
  const nv = Object.fromEntries(
    Object.entries(params).map(([key, value]) => [
      key,
      value ? value.toString() : '',
    ]),
  )

  // Clone the current URLSearchParams
  const newSearchParams = new URLSearchParams(searchParams.toString())

  Object.entries(nv).forEach(([key, value]) => {
    // Delete existing values for the key
    newSearchParams.delete(key)

    if (Array.isArray(value)) {
      // If the value is an array, append each item
      value.forEach((val) => newSearchParams.append(key, val))
    } else if (value !== '') {
      // If the value is a string and not empty, set it
      newSearchParams.set(key, value)
    }
  })

  // Update the URLSearchParams using the provided function
  setSearchParams(newSearchParams)
}

// export const onFilterChange = (
//   kv: {
//     [key: string]: number | string | undefined | null
//   },
//   searchParams: URLSearchParams,
//   setSearchParams: (params: URLSearchParams) => void,
// ) => {
//   const nv = Object.fromEntries(
//     Object.entries(kv).map(([key, value]) => [
//       key,
//       value ? value.toString() : '',
//     ]),
//   )
//   updateSearchParams({ ...nv }, searchParams, setSearchParams)
// }
