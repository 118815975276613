import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../app/hooks'
import { useLazyUserGetMeQuery } from '../redux/api/auth/user'

type Props = {}

export default function MeLoader({}: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const { accessToken, user } = useAppSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [getMe] = useLazyUserGetMeQuery()

  const fetchMe = async () => {
    await getMe()
  }

  useEffect(() => {
    if (user === null && accessToken !== null) {
      fetchMe()
    }
  }, [accessToken])

  // Once user is fetched (not null and not undefined), check if we came from somewhere
  useEffect(() => {
    if (user && location.state && location.state.from) {
      // Navigate back to the page user initially wanted
      navigate(location.state.from.pathname, { replace: true })
    }
  }, [user, location.state, navigate])

  return <Outlet />
}
