import { PullRequestOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Row, Tooltip } from 'antd'
import { useEffect, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import type { IFormBody } from '../../components/Form/helpers'
import FormNumberInput, {
  floatProps,
  positiveFloatProps,
  positiveIntegerProps,
} from '../../components/Form/NumberInput'
import FormSwitch from '../../components/Form/Switch'
import Debug from '../../components/helpers/Debug'
import Iconify from '../../components/Iconify'
import type { VllmParams } from '../../redux/api/assistant/thread'
import { useThreadUpdateMutation } from '../../redux/api/assistant/thread'
import type { ModelDescriptor } from '../../utils/modelDescriptor'
import type { FormField } from '../../utils/modelDescriptor/formFields'

type Props = {
  vllmParams: VllmParams
  assistantId: string
  threadId: string
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  md: ModelDescriptor<VllmParams, keyof VllmParams>
}

export const MyTooltip = ({ title }: { title: string }) => (
  <Tooltip placement="topLeft" title={title}>
    <QuestionCircleOutlined />
  </Tooltip>
)

export default function VLLMParamsForm({
  assistantId,
  threadId,
  vllmParams,
  open,
  setOpen,
  md,
}: Props) {
  const { t } = useTranslation()
  const initialValues: VllmParams = {
    ...vllmParams,
  }
  const defaultValues: VllmParams = {
    max_tokens: 256,
    temperature: 0.4,
    top_p: 0.9,
    top_k: 40,
    best_of: 1,
    presence_penalty: 0.5,
    frequency_penalty: 0.5,
    repetition_penalty: 1.2,
    use_beam_search: false,
    length_penalty: 1.0,
    early_stopping: false,
    max_memory: 0,
  }
  type FormBody = IFormBody<Required<VllmParams>, never>
  const [form] = Form.useForm<FormBody>()
  const [threadUpdateVLLMParams, { isLoading }] = useThreadUpdateMutation()

  const onFinish = async (values: Required<VllmParams>) => {
    console.log(values)
    try {
      const {} = await threadUpdateVLLMParams({
        assistantId,
        threadId,
        threadUpdateBody: {
          vllm_params: values,
        },
      })
      md.toast.updated()
    } catch (error) {
      toast.error('Не правильно введен логин или пароль')
      console.error((error as any).data.detail)
    }
    setOpen(false)
  }
  const gets = (field: FormField<string>) => (
    <label style={{ fontWeight: 'bold' }}>
      {t(field.label)}
      &nbsp;
      <MyTooltip title={field.tooltip} />
    </label>
  )

  useEffect(() => {
    form.resetFields()
  }, [open])

  return (
    <>
      <Debug objects={{ open }} />
      {/* <Modal
        open={open}
        title={
          <Row style={{ justifyContent: 'space-between', marginRight: '30px' }}>
            {md.form.action.update()}
            <Tooltip
              placement="topLeft"
              title={md.form.action.describe('reset')}
            >
              <Button
                icon={<Iconify icon={'carbon:reset'} />}
                onClick={() => {
                  form.setFieldsValue(defaultValues)
                }}
              />
            </Tooltip>
          </Row>
        }
        onOk={() => form.submit()}
        onCancel={() => setOpen(false)}
      > */}
      <Form
        initialValues={initialValues}
        form={form}
        disabled={isLoading}
        onFinish={onFinish}
      >
        <Tooltip placement="topLeft" title={md.form.action.describe('reset')}>
          <Button
            icon={<Iconify icon={'carbon:reset'} />}
            onClick={() => {
              form.setFieldsValue(defaultValues)
            }}
          />
        </Tooltip>
        <Debug objects={{ vllmParams }} />
        <FormNumberInput
          inputProps={{ ...positiveFloatProps, min: 0 }}
          formField={md.fields.length_penalty}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: 0,
                message: 'Length penalty must be a non-negative number',
              },
            ],
            label: gets(md.fields.length_penalty),
          }}
        />
        <FormSwitch
          formField={md.fields.early_stopping}
          itemProps={{
            rules: [
              {
                type: 'boolean',
                message: 'Early stopping must be a boolean',
              },
            ],
            label: gets(md.fields.early_stopping),
          }}
        />
        <FormNumberInput
          inputProps={{ ...floatProps, min: -2, max: 2 }}
          formField={md.fields.frequency_penalty}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: -2,
                max: 2,
                message: 'Frequency penalty must be between -2 and 2',
              },
            ],
            label: gets(md.fields.frequency_penalty),
          }}
        />
        <FormNumberInput
          inputProps={{ ...positiveIntegerProps, min: 1 }}
          formField={md.fields.max_tokens}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: 1,
                message: 'Max tokens must be a positive integer',
              },
              { type: 'integer', message: 'Max tokens must be an integer' },
            ],
            label: gets(md.fields.max_tokens),
          }}
        />
        <FormNumberInput
          inputProps={{ ...floatProps, min: -2, max: 2 }}
          formField={md.fields.presence_penalty}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: -2,
                max: 2,
                message: 'Presence penalty must be between -2 and 2',
              },
            ],
            label: gets(md.fields.presence_penalty),
          }}
        />
        <FormNumberInput
          inputProps={{ ...floatProps, min: 0.5, max: 1.5 }}
          formField={md.fields.repetition_penalty}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: 0.5,
                max: 1.5,
                message: 'Repetition penalty must be between 0.5 and 1.5',
              },
            ],
            label: gets(md.fields.repetition_penalty),
          }}
        />
        <FormNumberInput
          inputProps={{ ...positiveIntegerProps, min: 1 }}
          formField={md.fields.best_of}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: 1,
                message: 'Best of must be 1 or a positive integer',
              },
              { type: 'integer', message: 'Best of must be an integer' },
            ],
            label: gets(md.fields.best_of),
          }}
        />
        <FormNumberInput
          inputProps={{ ...positiveIntegerProps, min: 0 }}
          formField={md.fields.max_memory}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: 0,
                message: 'Max memory should be at least 0',
              },
              { type: 'integer', message: 'Max memory must be an integer' },
            ],
            label: gets(md.fields.max_memory),
          }}
        />
        <FormNumberInput
          inputProps={{ ...positiveIntegerProps, min: -1 }}
          formField={md.fields.top_k}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: -1,
                message: 'Top K must be -1 or a positive integer',
              },
              { type: 'integer', message: 'Top K must be an integer' },
            ],
            label: gets(md.fields.top_k),
          }}
        />
        <FormNumberInput
          inputProps={{ ...positiveIntegerProps, min: 0.01, max: 1.0 }}
          formField={md.fields.top_p}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: 0.01,
                max: 1,
                message: 'Top P must be between 0.01 and 1',
              },
            ],
            label: gets(md.fields.top_p),
          }}
        />
        <FormNumberInput
          inputProps={{ ...positiveFloatProps, min: 0 }}
          formField={md.fields.temperature}
          itemProps={{
            rules: [
              {
                type: 'number',
                min: 0,
                message: 'Temperature must be more than 0',
              },
            ],
            label: gets(md.fields.temperature),
          }}
        />
        <FormSwitch
          formField={md.fields.use_beam_search}
          itemProps={{
            rules: [
              {
                type: 'boolean',
                message: 'Use beam search must be a boolean',
              },
            ],
            label: gets(md.fields.use_beam_search),
          }}
        ></FormSwitch>
        <Button htmlType="submit">{md.form.action.update()}</Button>
      </Form>
    </>
  )
}
