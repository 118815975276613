import type { TFunction, TOptionsBase } from 'i18next'
import type { $Dictionary } from 'i18next/typescript/helpers'
type TFunc = (option?: TOptions) => string
type TPop = {
  question: TFunc
  confirm: TFunc
  cancel: TFunc
}
type TOptions = Partial<TOptionsBase & $Dictionary & { defaultValue: string }>
export class PopDescriptor {
  domain: string
  t: TFunction
  delete: TPop

  constructor(domain: string, t: TFunction) {
    this.domain = domain
    this.t = t
    this.delete = this.describe('delete')
  }

  translate(action: string, key: string) {
    return (options?: TOptions) => {
      return this.t(`${this.domain}.pop.${action}.${key}`, options)
    }
  }

  describe(action: string) {
    return {
      question: this.translate(action, 'question'),
      confirm: this.translate(action, 'confirm'),
      cancel: this.translate(action, 'cancel'),
    }
  }
}
