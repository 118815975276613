import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import { HelpRoute } from '../../routes/clientRoutes'

interface Props {}
const { Title } = Typography

export default function HelpPage({}: Props) {
  const { t } = useTranslation()
  const { user } = useAppSelector((state) => state.auth)

  return (
    <Page title={t(HelpRoute.title)}>
      <Debug debug objects={{ user }} />
      <Title level={3}>{t(HelpRoute.title)}</Title>
    </Page>
  )
}
