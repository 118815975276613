export default {
  fields: {
    id: {
      label: 'Идентификатор потока',
      placeholder: 'Введите уникальный идентификатор чата',
    },
    user_id: {
      label: 'Идентификатор пользователя',
      placeholder: 'Введите UUID пользователя',
    },
    name: {
      label: 'Название',
      placeholder: 'Введите название чата',
      default: 'Ваш чат',
    },
    instruction: {
      label: 'Инструкция',
      placeholder: 'Заполните инструкцию для чата',
      default:
        'Сіз сұрақтарға жауап беретін және ақпарат беретін сенімді AI көмекшісісіз.',
    },
    assistant_id: {
      label: 'Идентификатор помощника',
      placeholder: 'Введите UUID помощника',
    },
    assistant: {
      label: 'Помощник',
      placeholder: 'Введите имя или идентификатор помощника',
    },
    user: {
      label: 'Пользователь',
      placeholder: 'Введите имя или идентификатор пользователя',
    },
    status: {
      label: 'Статус',
      placeholder: 'Введите текущий статус чата',
    },
    max_tokens: {
      label: 'Максимум токенов',
      placeholder: 'Введите максимальное количество генерируемых токенов',
    },
    temperature: {
      label: 'Температура',
      placeholder: 'Введите уровень случайности (например, от 0.0 до 1.0)',
    },
    top_p: {
      label: 'Top P',
      placeholder: 'Введите значение top-p выборки (например, от 0.0 до 1.0)',
    },
    top_k: {
      label: 'Top K',
      placeholder: 'Введите ограничение top-k выборки',
    },
    presence_penalty: {
      label: 'Штраф за присутствие',
      placeholder: 'Введите коэффициент штрафа за присутствие',
    },
    frequency_penalty: {
      label: 'Штраф за частоту',
      placeholder: 'Введите коэффициент штрафа за частоту',
    },
    repetition_penalty: {
      label: 'Штраф за повторения',
      placeholder: 'Введите коэффициент штрафа за повторения',
    },
    use_beam_search: {
      label: 'Использовать Beam Search',
      placeholder: 'Выберите, использовать ли Beam Search',
    },
    length_penalty: {
      label: 'Штраф за длину',
      placeholder: 'Введите коэффициент штрафа за длину',
    },
    early_stopping: {
      label: 'Раннее завершение',
      placeholder: 'Укажите критерии раннего завершения генерации',
    },
  },
  form: {
    create: {
      title: 'Создать новый чат',
    },
    update: {
      title: 'Обновить чат',
    },
    actions: {
      create: 'Создать новый чат',
      update: 'Обновить чат',
      delete: 'Удалить чат',
      about: 'О чате',
    },
    pop: {
      delete: {
        question: 'Вы действительно хотите удалить {{ title }} ({{ id }}) чат?',
        confirm: 'Да',
        cancel: 'Отмена',
      },
    },
  },
  toast: {
    updated: 'Поток успешно обновлен',
    created: 'Поток успешно создан',
    deleted: 'Поток успешно удален',
    error: 'Что-то пошло не так',
  },
  info: {
    title: 'Инструкции',
    button: 'Инструкции',
  },
}
