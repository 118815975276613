import type { TFunction } from 'i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { resources } from './locales'
const languageState = localStorage.getItem('LanguageState') ?? 'kk-KZ'
document.querySelector('html')?.setAttribute('lang', languageState)

export type TSupportedLangs = 'en-US' | 'ru-RU' | 'kk-KZ'

const supportedLngs: TSupportedLangs[] = ['en-US', 'ru-RU', 'kk-KZ']

export const t: TFunction = ((path: string) => {
  return path
}) as any

void i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: false,
    supportedLngs,
    resources,
    lng: languageState,
    fallbackLng: 'en-US',
    detection: {
      order: [
        'htmlTag',
        'cookie',
        'localStorage',
        'sessionStorage',
        'path',
        'subdomain',
      ],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  })

export default i18n
