import { SettingOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'

import AccountDropdown from '../../../components/AccountDropdown'
import LanguagePopover from '../../../components/LanguagePopover'
import { AssistantsRoute } from '../../../routes'
import { paths } from '../../../routes/paths'

interface InterfaceDashboardNavbar {}

export default function Navbar({}: InterfaceDashboardNavbar) {
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const items: MenuProps['items'] = [
    {
      label: (
        <RouterLink
          to={AssistantsRoute.path}
          style={{ textDecoration: 'none' }}
        >
          {t(AssistantsRoute.title)}
        </RouterLink>
      ),
      key: AssistantsRoute.path,
    },
  ]

  const navigate = useNavigate()

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        flex: 1,
        alignItems: 'center',
      }}
    >
      <Menu
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'end',
        }}
        // inlineCollapsed={false}
        selectedKeys={[pathname]}
        mode="horizontal"
        items={items}
      />
      <Button
        type="link"
        icon={<SettingOutlined />}
        size="large"
        style={{ paddingInline: '10px' }}
        href={paths.clientOrganization}
      />
      <LanguagePopover />
      <AccountDropdown
        items={[
          {
            key: 'profile',
            label: (
              <a onClick={() => navigate(paths.profile)}>
                {t('components.navbar.accountDropdown.profile')}
              </a>
            ),
          },
        ]}
      />
    </div>
  )
}
