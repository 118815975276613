import type { DropDownProps, MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import type { ButtonProps } from 'antd/lib'
import { useTranslation } from 'react-i18next'

import type { TSupportedLangs } from '../i18n'

import Iconify from './Iconify'
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

interface InterfaceLangs {
  value: TSupportedLangs
  label: string
  icon: string
}

const LANGS: InterfaceLangs[] = [
  {
    value: 'kk-KZ',
    label: 'Қазақша',
    icon: 'twemoji:flag-kazakhstan',
  },
  {
    value: 'ru-RU',
    label: 'Русский',
    icon: 'twemoji:flag-russia',
  },
  {
    value: 'en-US',
    label: 'English',
    icon: 'twemoji:flag-united-states',
  },
]

export default function LanguagePopover({
  dropdownProps,
  buttonProps,
}: {
  buttonProps?: ButtonProps
  dropdownProps?: DropDownProps
}) {
  const { i18n } = useTranslation()
  const { t } = useTranslation()

  const items: MenuProps['items'] = LANGS.map(({ value, label, icon }, i) => ({
    key: i,
    label: (
      <div onClick={() => handleChangeLanguage(value)}>
        <Iconify icon={icon} style={{ width: 28 }} />
        {label}
      </div>
    ),
  }))

  const handleChangeLanguage = (localeCode: TSupportedLangs) => {
    document.querySelector('html')?.setAttribute('lang', localeCode)
    localStorage.setItem('LanguageState', localeCode)
    void i18n.changeLanguage(localeCode)
  }

  return (
    <Dropdown menu={{ items }} trigger={['click']} {...dropdownProps}>
      <Button
        onClick={(e) => e.preventDefault()}
        type="link"
        style={{ paddingInline: '5px' }}
        {...buttonProps}
      >
        {/* <Space>{t('components.navbar.language.title')}</Space> */}
        <Iconify
          icon={'material-symbols:language'}
          width={24}
          style={{ verticalAlign: 'middle' }}
        />
      </Button>
    </Dropdown>
  )
}
