import type { FormInstance } from 'antd'
import { Button } from 'antd'

import { GlobalDebug } from '../../config'

type Props = {
  form: FormInstance<any>
  data?: any
  debug?: boolean
}

export default function DebugFormBtn({ form, data, debug = false }: Props) {
  return (
    <>
      {debug && GlobalDebug ? (
        <Button
          onClick={() => {
            console.log(form.getFieldsValue())
            console.log(data)
          }}
        >
          Debug fields
        </Button>
      ) : null}
    </>
  )
}
