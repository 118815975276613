// ----------------------------------------------------------------------
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Menu } from 'antd'
import Sider from 'antd/es/layout/Sider'
import type { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Iconify from '../../components/Iconify'
import {
  AdminTenantsRoute,
  BillingRoute,
  BusinessRoute,
  MonitoringRoute,
  OrganizationRoute,
  ProfileRoute,
} from '../../routes'
import { AdminUsersRoute } from '../../routes/clientRoutes'
import { paths } from '../../routes/paths'
import { gray } from '../../theme'
// ----------------------------------------------------------------------
type Props = {}

type MenuItem = Required<MenuProps>['items'][number]
export default function ClientSettingsSider({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isDarkMode } = useAppSelector((state) => state.main)

  const style: CSSProperties = {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '12px',
  }

  const childStyle: CSSProperties = {
    ...style,
    paddingLeft: '35px',
  }

  const items: MenuItem[] = [
    {
      key: paths.profile,
      label: (
        <a onClick={() => navigate(paths.profile)} style={style}>
          {t(ProfileRoute.title)}
        </a>
      ),
      icon: <MailOutlined />,
    },
    {
      key: 'organization',
      label: (
        <div style={style}>
          <AppstoreOutlined />
          &nbsp; &nbsp; &nbsp;
          {t(OrganizationRoute.title)}
        </div>
      ),
      type: 'group',
      children: [
        {
          key: paths.clientOrganization,
          label: (
            <a
              onClick={() => navigate(paths.clientOrganization)}
              style={childStyle}
            >
              {t('layouts.settings.sider.organization.organization')}
            </a>
          ),
        },
        {
          key: paths.clientBilling,
          label: (
            <a onClick={() => navigate(paths.clientBilling)} style={childStyle}>
              {t(BillingRoute.title)}
            </a>
          ),
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      key: 'business',
      label: (
        <div style={style}>
          <SettingOutlined />
          &nbsp; &nbsp; &nbsp;
          {t(BusinessRoute.title)}
        </div>
      ),
      type: 'group',
      children: [
        {
          key: paths.clientBusiness,
          label: (
            <a
              onClick={() => navigate(paths.clientBusiness)}
              style={childStyle}
            >
              {t('layouts.settings.sider.business.business')}
            </a>
          ),
        },
      ],
    },
    {
      key: paths.clientMonitoring,
      label: (
        <a onClick={() => navigate(paths.clientMonitoring)} style={style}>
          {t(MonitoringRoute.title)}
        </a>
      ),
      icon: <Iconify icon={'eos-icons:monitoring'} />,
    },
    {
      key: paths.adminUsers,
      label: (
        <a onClick={() => navigate(paths.adminUsers)} style={style}>
          {t(AdminUsersRoute.title)}
        </a>
      ),
      icon: <Iconify icon={'eos-icons:monitoring'} />,
    },
    {
      key: paths.adminTenants,
      label: (
        <a onClick={() => navigate(paths.adminTenants)} style={style}>
          {t(AdminTenantsRoute.title)}
        </a>
      ),
      icon: <Iconify icon={'eos-icons:monitoring'} />,
    },
  ]

  return (
    <Sider
      width={256}
      style={{
        // minHeight: '100vh',
        backgroundColor: isDarkMode ? gray : undefined,
      }}
    >
      <Menu
        mode="inline"
        items={items}
        selectedKeys={[pathname]}
        style={{ backgroundColor: isDarkMode ? gray : undefined }}
      />
    </Sider>
  )
}
