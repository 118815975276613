import type { Rule } from 'antd/es/form'
import type { TFunction } from 'i18next'

type DType = 'int' | 'float' | 'string' | 'boolean' | 'date'
type TDefault = string | boolean | number | null | undefined
type TRules = {
  required?: boolean
  email?: boolean
}
type TFilter = {
  default?: TDefault
  type?: DType
}

export type TDesc<T extends string> = {
  [key in T]: {
    rules?: {
      required?: boolean
      email?: boolean
    }
    filter?: {
      type: DType
      default: TDefault
    }
  }
}

export interface FormField<T extends string> {
  name: T
  label: string
  tooltip: string
  placeholder: string
  iconify?: string
  default?: null | string | number | boolean
  fieldDomain: string
  rules: Rule[]
  get_error: (errorName: string) => string
  filter: TFilter
}

export type FormFields<T extends string> = { [key in T]: FormField<key> }

export class FieldDescriptor<T extends string> {
  domain: string | undefined
  t: TFunction
  baseTPath: string
  constructor(t: TFunction, domain: string | undefined) {
    this.t = t
    this.domain = domain
    this.baseTPath = `${this.domain}.fields`
  }

  name(fieldName: T) {
    return `${fieldName}`
  }

  required(fieldName: T) {
    return this.t(`${this.baseTPath}.${fieldName}.required`)
  }

  rules(fieldName: T, { required, email }: TRules): Rule[] {
    const rules: Rule[] = []
    if (required) {
      rules.push({
        message: this.t(`${this.baseTPath}.${fieldName}.required`),
        required,
      })
    }
    if (email) {
      rules.push({
        message: this.t(`${this.baseTPath}.${fieldName}.emailNotValid`),
        type: 'email',
      })
    }
    return rules
  }

  label(fieldName: T) {
    return this.t(`${this.baseTPath}.${fieldName}.label`)
  }

  tooltip(fieldName: T) {
    return this.t(`${this.baseTPath}.${fieldName}.tooltip`)
  }

  get_error_method(fieldName: T) {
    const t = this.t
    const baseTPath = this.baseTPath
    function getError(errorName: string) {
      return t(`${baseTPath}.${fieldName}.errors.${errorName}`)
    }
    return getError
  }

  placeholder(fieldName: T) {
    return this.t(`${this.baseTPath}.${fieldName}.placeholder`)
  }

  description(
    fieldName: T,
    tRules: TRules,
    filterRules: TFilter,
  ): FormField<T> {
    return {
      name: fieldName as any,
      label: this.label(fieldName),
      tooltip: this.tooltip(fieldName),
      placeholder: this.placeholder(fieldName),
      rules: this.rules(fieldName, tRules),
      get_error: this.get_error_method(fieldName),
      fieldDomain: `${this.domain}.fields.${fieldName}`,
      filter: filterRules,
    }
  }
}

export function describeFields<T extends string>(
  keys: TDesc<T>,
  t: TFunction,
  domain: string,
): FormFields<T> {
  const fieldDescriptor = new FieldDescriptor<T>(t, domain)

  return Object.keys(keys).reduce(
    (acc, key) => {
      acc[key as T] = fieldDescriptor.description(
        key as T,
        {
          required: keys[key as T].rules?.required,
          email: keys[key as T].rules?.email,
        },
        keys[key as T].filter || {},
      )
      return acc
    },
    {} as { [key in T]: FormField<key> },
  )
}
