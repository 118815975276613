import { Card, Spin } from 'antd'
import Meta from 'antd/es/card/Meta'
import type { CSSProperties } from 'react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Iconify from '../../components/Iconify'
import { params, paths } from '../../routes/paths'
import useHasPermission from '../../utils/permissions'

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.05)',
  borderRadius: 4,
}

const content = <div style={contentStyle} />
type Props = {}

export default function Apps({}: Props) {
  const { user, accessToken } = useAppSelector((state) => state.auth)

  const hasAdmPerm = useHasPermission('admin')
  const hasClientPerm = useHasPermission('client')
  const hasUserPerm = useHasPermission('user')
  const cardStyle: CSSProperties = {
    width: '100%',
    textAlign: 'center',
  }
  const navigate = useNavigate()
  const containerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    gap: '50px',
  }

  const squareStyle: CSSProperties = {
    width: '200px',
    height: '400px',
  }
  const assistant = user?.user_assistants[0]
  const { t } = useTranslation()
  return (
    <>
      <Debug
        debug
        objects={{
          hasAdmPerm,
          hasClientPerm,
          hasUserPerm,
          user,
          accessToken,
          a: localStorage.getItem('accessToken'),
        }}
      />

      {user === null ? (
        <Spin
          size="large"
          style={{ height: '500px', paddingTop: '200px', width: '100%' }}
          tip="Wait..."
        >
          ""
        </Spin>
      ) : (
        <div style={containerStyle}>
          {hasAdmPerm && (
            <div style={squareStyle}>
              <RouterLink
                to={paths.clientDashboard}
                style={{ textDecoration: 'none' }}
              >
                <Card
                  hoverable
                  style={cardStyle}
                  cover={
                    <Iconify
                      icon={'clarity:administrator-solid'}
                      style={{ width: '100%', height: '100px' }}
                    />
                  }
                >
                  <Meta
                    title={t('pages.apps.cards.admin.title')}
                    description={t('pages.apps.cards.admin.description')}
                  />
                </Card>
              </RouterLink>
            </div>
          )}
          {hasUserPerm && assistant && (
            <div style={squareStyle}>
              <RouterLink
                to={paths.userChat
                  .replace(params.assistant_id, assistant.id)
                  .replace(params.thread_id, '')}
                style={{ textDecoration: 'none' }}
              >
                <Card
                  style={cardStyle}
                  aria-disabled
                  cover={
                    <Iconify
                      icon={'token:chat'}
                      style={{ width: '100%', height: '100px' }}
                    />
                  }
                >
                  <Meta
                    title={t('pages.apps.cards.user.title')}
                    description={t('pages.apps.cards.user.description')}
                  />
                </Card>
              </RouterLink>
            </div>
          )}
          {hasClientPerm && (
            <div style={squareStyle}>
              <RouterLink
                to={paths.clientDashboard}
                style={{ textDecoration: 'none' }}
              >
                <Card
                  hoverable
                  style={cardStyle}
                  cover={
                    <Iconify
                      icon={'material-symbols:business-center-outline'}
                      style={{ width: '100%', height: '100px' }}
                    />
                  }
                >
                  <Meta
                    title={t('pages.apps.cards.business.title')}
                    description={t('pages.apps.cards.business.description')}
                  />
                </Card>
              </RouterLink>
            </div>
          )}
        </div>
      )}
    </>
  )
}
