import { removeUser, setUser } from '../../slices/auth/userSlice'

import { userApi } from './user'

// fact
export const userApiEnhanced = userApi.enhanceEndpoints({
  endpoints: {
    userGetMe: {
      query: () => ({
        url: `/auth/user/me`,
      }),
      providesTags: ['/user'],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            setUser({
              user: data,
              accessToken: undefined,
              refreshToken: undefined,
            }),
          )
        } catch (e: any) {
          if (e.error.status === 401) {
            dispatch(removeUser())
          }
          console.error(e.error)
        }
      },
    },
  },
})
