import { Form, Select } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { FormItemProps, SelectProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { ComplexityLevel } from '../../../redux/api/edu/order'
import type { FormField } from '../../../utils/modelDescriptor/formFields'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
} & SelectProps

export default function LevelSelect({ itemProps, formField, ...props }: Props) {
  const id = useId()
  const { t } = useTranslation()
  const objs: ComplexityLevel[] = [
    'Advanced',
    'Beginner',
    'Expert',
    'Intermediate',
  ]
  const options: DefaultOptionType[] = objs.map((value) => ({
    value,
    label: t(`edu.order.fields.level.options.${value}`),
  }))

  return (
    <Form.Item
      id={id}
      name={formField?.name}
      rules={formField?.rules}
      hasFeedback={formField?.rules !== undefined}
      // validateTrigger={formField?.rules !== undefined ? 'validateTrigger' : undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <Select
        {...props}
        placeholder={t(formField.placeholder)}
        allowClear
        options={options}
      />
    </Form.Item>
  )
}
