export default {
  fields: {
    id: {
      label: 'Thread ID',
      placeholder: 'Enter the unique ID of the chat',
    },
    user_id: {
      label: 'User ID',
      placeholder: 'Enter the UUID of the user',
    },
    name: {
      label: 'Name',
      placeholder: 'Enter name of the chat',
      default: 'Your chat',
    },
    instruction: {
      label: 'Instruction',
      placeholder: 'Fills chat instruction',
      default:
        'Сіз сұрақтарға жауап беретін және ақпарат беретін сенімді AI көмекшісісіз.',
    },
    assistant_id: {
      label: 'Assistant ID',
      placeholder: 'Enter the UUID of the assistant',
    },
    assistant: {
      label: 'Assistant',
      placeholder: "Enter the assistant's name or identifier",
    },
    user: {
      label: 'User',
      placeholder: "Enter the user's name or identifier",
    },
    status: {
      label: 'Status',
      placeholder: 'Enter the current status of the chat',
    },
    max_tokens: {
      label: 'Max Tokens',
      placeholder: 'Enter the maximum number of tokens to generate',
    },
    temperature: {
      label: 'Temperature',
      placeholder: 'Enter the randomness level (e.g., 0.0 to 1.0)',
    },
    top_p: {
      label: 'Top P',
      placeholder: 'Enter the top-p sampling value (e.g., 0.0 to 1.0)',
    },
    top_k: {
      label: 'Top K',
      placeholder: 'Enter the top-k sampling limit',
    },
    presence_penalty: {
      label: 'Presence Penalty',
      placeholder: 'Enter the presence penalty factor',
    },
    frequency_penalty: {
      label: 'Frequency Penalty',
      placeholder: 'Enter the frequency penalty factor',
    },
    repetition_penalty: {
      label: 'Repetition Penalty',
      placeholder: 'Enter the repetition penalty factor',
    },
    use_beam_search: {
      label: 'Use Beam Search',
      placeholder: 'Select if beam search should be used',
    },
    length_penalty: {
      label: 'Length Penalty',
      placeholder: 'Enter the length penalty factor',
    },
    early_stopping: {
      label: 'Early Stopping',
      placeholder: 'Specify early stopping criteria for generation',
    },
  },
  form: {
    create: {
      title: 'Create new chat',
    },
    update: {
      title: 'Update chat',
    },
    actions: {
      create: 'Create new chat',
      update: 'Update chat',
      delete: 'Delete chat',
      about: 'About the chat',
    },
    pop: {
      delete: {
        question: 'Do you really want to delete {{ title }} ({{ id }}) chat?',
        confirm: 'Yes',
        cancel: 'Cancel',
      },
    },
  },
  toast: {
    updated: 'Thread was successfully updated',
    created: 'Thread was successfully created',
    deleted: 'Thread was successfully deleted',
    error: 'Something gone wrong',
  },
  info: {
    title: 'Instructions',
    content: `
# Инструкция для команды по сбору данных для LLM

## Обзор

Этот документ предоставляет руководство по сбору высококачественных данных взаимодействия между пользователями и развернутой на платформе большой языковой моделью (LLM). Все взаимодействия должны проводиться на казахском языке как основном языке для набора данных. Пользователи могут создавать несколько потоков (чатов) для взаимодействия с моделью. Собранные данные будут использованы для улучшения производительности модели в различных категориях. Каждый пользователь должен создать не менее 5 чатов для каждой категории. Пользователи могут иногда допускать небольшие опечатки в запросах (вопросах), но ответы, исправляющие ошибки или дополняющие информацию, не должны содержать опечаток.

## Основные функции для использования

При взаимодействии с моделью пользователи должны использовать следующие функции:

- **Переименовать чат**: Каждый поток чата должен быть переименован в соответствии с определенным шаблоном для организации данных.
- **Исправление сгенерированного ответа**: Пользователи должны исправлять ответы модели, если это необходимо.
- **Лайк**: Указывать, когда ответ структурно правильный, но содержит фактические ошибки.
- **Дизлайк**: Указывать, когда ответ полностью бессмысленный или не по теме.
- **Параметры генерации**: Использовать настройки по умолчанию; нет необходимости изменять такие параметры, как температура или top-k.

## Руководство по сбору данных

### 1. Языковое требование
- **Казахский язык**: Все запросы пользователей и взаимодействия с моделью должны быть на казахском языке. Убедитесь, что и запросы, и ответы написаны на казахском.

### 2. Переименование чатов
- **После создания чата** пользователь должен сразу же переименовать его.
  - **Шаблон**: \`<CATEGORY_ID>-<username>-<number from 0 to N>\`
  - **Пример**: \`NLU-QA-Asan-1\`
  - **Цель**: Это помогает категоризировать данные и связывать их с определенными пользователями и случаями взаимодействия.

### 3. Поддержание контекстуальной непрерывности
- **Согласованность**: Каждый чат должен поддерживать непрерывный контекст и не переходить на несвязанные темы.
  - **Пример**: Чат о событиях истории не должен внезапно переключаться на проверку орфографии.
- **Глубина взаимодействия**: Поощряйте глубокие обсуждения в рамках выбранной темы.

### 4. Создание минимум 5 чатов для каждой категории

### 5. Обработка ответов модели
- **a. Бессмысленные ответы**
  - **Действие**: Если ответ модели полностью бессмысленный или не по теме, нажмите "Не нравится" и предоставьте ожидаемый правильный ответ.
  - **Пример**:
    - **Запрос пользователя**: "Қазақ халқының ұлттық бірегейлігі туралы не ойлайсыз?"
    - **Ответ модели**: "Ұлттық бірегейлік барлық елдер үшін маңызды."
    - **Исправление**: Отметьте ответ как "Не нравится" и предоставьте более релевантный ответ, сосредоточенный на казахской национальной идентичности.
- **b. Структурно правильные, но фактически ошибочные ответы (Fake-True)**
  - **Действие**: Если ответ модели структурно правильный, но содержит фактические ошибки, нажмите "Нравится" и предложите правильный ответ.
  - **Пример**:
    - **Запрос пользователя**: "Қазақстанның астанасы қай қала?"
    - **Ответ модели**: "Қазақстанның астанасы Тараз қаласы."
    - **Исправление**: Отметьте ответ как "Нравится" и предложите правильный ответ: "Қазақстанның астанасы Астана қаласы."

### 6. Формулировка пользовательских запросов
- **Вариативность**: Избегайте использования шаблонных запросов; формулируйте запросы свободным форматом с разнообразной структурой.
- **Длина**: Делайте запросы как можно более детальными, чтобы стимулировать модель к развёрнутым ответам.

### 7. Длина взаимодействия
В зависимости от ожидаемой длины ответов и запросов пользователей, соблюдайте следующие рекомендации по количеству пар «запрос-ответ» в каждой категории, с максимумом 25 пар в чате:
- **Категории с короткими ответами**: 15-25 пар «запрос-ответ».
  - **Категории**: \`NLU-QA\`, \`CSR\`, \`KR\`
- **Категории со средними ответами**: 10-20 пар «запрос-ответ».
  - **Категории**: \`DCAI\`, \`CGNR\`
- **Категории с длинными ответами**: 5-15 пар «запрос-ответ».
  - **Категории**: \`LGS\`, \`CLG\`, \`CIU\`, \`EFB\`

### 8. Динамика рассуждений и обсуждений
- **Взаимодействие**: Включайте согласие и несогласие в диалог, чтобы смоделировать реалистичные обсуждения.
  - **Пример**:
    - Пользователь: "Менің ойымша, жаңартылатын энергия болашақтың кілті."
    - Модель: "Жаңартылатын энергия үлкен әлеуетке ие, бірақ кейбір қиындықтар бар."
    - Пользователь: "Қиындықтарға қарамастан, оның ұзақ мерзімді пайдасы басымырақ."

## Категории для покрытия
Пользователи должны генерировать взаимодействия, охватывающие следующие категории и подкатегории. Каждая категория включает примеры с запросами и ответами на казахском языке.

### 1. Понимание естественного языка и ответы на вопросы (NLU-QA)
- **Количество пар вопросов-ответов**: 15-25 на чат.
- **Подкатегории**:
  - **a. CATEGORY_ID**: \`NLU-QA-SQuAD\`
    - **Пример**:
      - Пользователь: "Абылай хан XVIII ғасырда Қазақ хандығын басқарған. Сұрақ: Абылай хан қашан Қазақ хандығын басқарды?"
      - Модель: "XVIII ғасырда."
  - **b. CATEGORY_ID**: \`NLU-QA-TriviaQA\`
    - **Пример**:
      - Пользователь: "Сұрақ: Әлемдегі ең ұзын өзен қайсы?"
      - Модель: "Ніл өзені."
  - **c. CATEGORY_ID**: \`NLU-QA-NQ\` (Natural Questions)
    - **Пример**:
      - Пользователь: "Алматы Қазақстанның ең үлкен қаласы. Сұрақ: Қазақстанның ең үлкен қаласы қайсы?"
      - Модель: "Алматы."
  - **d. CATEGORY_ID**: \`NLU-QA-MMLU\`
    - **Пример**:
      - Пользователь: "Сұрақ: Химияда су молекуласының формуласы қандай?"
      - Модель: "H₂O."

## Заключительные замечания

- **Качество важнее количества**: Сосредоточьтесь на создании высококачественных взаимодействий, которые помогут улучшить понимание и возможности модели.
- **Этические соображения**: Избегайте неподобающего или запрещенного содержания как в запросах, так и в ответах.
- **Механизм обратной связи**: Используйте функции "Лайк" и "Дизлайк" для предоставления обратной связи о производительности модели.
- **Культурная чувствительность**: Убедитесь, что контент уважителен и соответствует казахскому культурному контексту.
    `,
    button: 'Instructions',
  },
}
