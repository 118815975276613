import { ProChatProvider } from '@ant-design/pro-chat'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// ----------------------------------------------------------------------
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import {
  getThreadModelDescriptor,
  setChatModalOpen,
  setChatModalThread,
} from '../../redux/slices/assistant/thread'
import { LoginRoute, UserChatRoute } from '../../routes'
import Chat from '../Assistants/components/Chat'
import { useThreadAPI } from '../Assistants/components/Chat/funcs'
import ThreadForm from '../Assistants/components/Chat/ThreadForm'

// ----------------------------------------------------------------------

export default function ChatPage({}: {}) {
  const { t } = useTranslation()
  const { accessToken, user } = useAppSelector((state) => state.auth)
  const { threads } = useAppSelector((state) => state.assistant.thread)
  const { assistant_id, thread_id } = useParams()
  const user_id = user?.id
  const tmd = getThreadModelDescriptor(t)
  const dispatch = useAppDispatch()
  const { chatModalOpen } = useAppSelector((state) => state.assistant.thread)

  const {
    thread,
    ask,
    chats,
    areRunsLoading,
    setChats,
    setThread,
    regenerate,
    msgId,
    setMsgId,
    threadId,
    startChat,
    formatRunId,
    chatDelete,
  } = useThreadAPI({
    user_id: user?.id,
    assistantId: assistant_id,
    urlThreadId: thread_id,
    accessToken,
  })

  return (
    <Page
      title={t(UserChatRoute.title)}
      layoutStyle={{
        height: '100%',
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <Debug objects={{ assistant_id, thread_id, threads }} />
      {user && assistant_id && (
        <ThreadForm
          assistantId={assistant_id}
          userId={user.id}
          md={tmd}
          open={chatModalOpen}
          onClose={() => dispatch(setChatModalThread(undefined))}
          setOpen={(open) => dispatch(setChatModalOpen(open as any))}
          onFinish={async (values) => {
            console.log(values)
            const { instruction, name, assistant_id, user_id } = values
            try {
              const thread = await startChat({
                assistant_id,
                user_id,
                instruction,
                name,
              })
              tmd.toast.created()
            } catch (error) {
              toast.error('Не правильно введен логин или пароль')
              console.error((error as any).data.detail)
            }
            dispatch(setChatModalOpen(false))
          }}
        />
      )}

      <ProChatProvider>
        {assistant_id && user_id && thread_id && (
          <Chat
            assistantId={assistant_id}
            urlThreadId={thread_id}
            user_id={user_id}
            chats={chats}
            formatRunId={formatRunId}
            msgId={msgId}
            threadId={threadId}
            ask={ask}
            regenerate={regenerate}
            thread={thread}
            chatDelete={chatDelete}
            startChat={startChat}
            setMsgId={setMsgId}
            areRunsLoading={areRunsLoading}
            setChats={setChats}
          />
        )}
      </ProChatProvider>
    </Page>
  )
}
