import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import Page from '../../components/Page'
import { BillingRoute } from '../../routes'

interface Props {}
const { Title } = Typography

export default function BillingPage({}: Props) {
  const { t } = useTranslation()

  return (
    <Page title={t(BillingRoute.title)}>
      <Title level={3}>{t(BillingRoute.title)}</Title>
    </Page>
  )
}
