import en from './en'
import kk from './kk'
import ru from './ru'

export const resources = {
  'ru-RU': {
    translation: ru,
  },
  'en-US': {
    translation: en,
  },
  'kk-KZ': {
    translation: kk,
  },
}

export const defaultNS = 'translation'
