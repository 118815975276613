// ----------------------------------------------------------------------
import { Avatar, Button, Divider, Dropdown, Typography, theme } from 'antd'
import type { MenuProps } from 'antd/lib'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../app/hooks'
import { removeUser } from '../redux/slices/auth/userSlice'
import { gray, light } from '../theme'

// ----------------------------------------------------------------------
const { useToken } = theme
const { Text } = Typography

type Props = {
  items?: MenuProps['items']
}

export default function AccountDropdown({ items = [] }: Props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state) => state.auth)

  const handleLogout = () => {
    dispatch(removeUser())
  }
  const items2: MenuProps['items'] = [
    ...items,
    {
      key: '2',
      label: (
        <a onClick={handleLogout}>{t('components.navbar.popover.logout')}</a>
      ),
    },
  ]

  const { token } = useToken()
  const width = 200
  const { isDarkMode } = useAppSelector((state) => state.main)
  return (
    <>
      <Dropdown
        dropdownRender={(menu) => (
          <div
            style={{
              borderRadius: token.borderRadiusLG,
              boxShadow: token.boxShadowSecondary,
              backgroundColor: isDarkMode ? gray : light,
              padding: 5,
            }}
          >
            <div
              style={{
                marginTop: 2.5,
                marginBottom: 2.5,
                paddingLeft: 2.5,
                paddingRight: 2.5,
                display: 'flex', // Use flexbox to control layout
                flexDirection: 'column', // Arrange children in a column
                maxWidth: `${width}px`,
              }}
            >
              {user?.username}
              <Text type="secondary" style={{ fontSize: 12 }}>
                {user?.email || t('components.navbar.popover.login')}
              </Text>
              <Divider style={{ margin: 5 }} />
            </div>
            {/* <span
              style={{
                display: 'flex',
                marginLeft: '5px',
                marginBottom: '5px',
                justifyContent: 'space-between',
              }}
            >
              {t('components.navbar.accountDropdown.theme')}
              <Switch
                checkedChildren={
                  <Iconify
                    icon={'material-symbols-light:dark-mode-outline-rounded'}
                  />
                }
                unCheckedChildren={
                  <Iconify
                    icon={'material-symbols-light:light-mode-outline-rounded'}
                  />
                }
                defaultChecked={isDarkMode}
                onChange={(isDarkMode, e) => {
                  e.preventDefault()
                  dispatch(setIsDarkMode({ isDarkMode }))
                }}
              />
            </span> */}
            {React.cloneElement(menu as React.ReactElement, {
              style: {
                boxShadow: 'none',
              },
            })}
            <Divider style={{ margin: 0 }} />
          </div>
        )}
        menu={{ items: items2 }}
      >
        <Button type="link" size="small">
          <Avatar style={{ verticalAlign: 'middle' }} size="small" gap={4}>
            {user?.username[0]}
          </Avatar>
        </Button>
      </Dropdown>
    </>
  )
}
