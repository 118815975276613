import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

// // Function overloads
// export function formatDate(
//   date: Dayjs | null | string | undefined,
//   format: string,
//   def: string,
// ): null
// export function formatDate(
//   date: Dayjs | null | string | undefined,
//   format: string,
//   def: string,
// ): string
// export function formatDate(
//   date: Dayjs | null | string | undefined,
//   format?: string,
//   def?: string,
// ): string
// export function formatDate(
//   date: Dayjs | null | string | undefined,
//   format?: string,
//   def?: null,
// ): string | null

// Implementation
export function formatDate(
  date: Dayjs | null | string | undefined,
  format = 'YYYY-MM-DD',
  def: null | string = null,
): string | null {
  if (!dayjs(date).isValid() || date === undefined) {
    return def
  }
  return dayjs(date).format(format)
}
