import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  AssistantFileBody,
  AssistantFileResponse,
} from '../../api/assistant/assistant_file'

export const getAssistantFileModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<AssistantFileBody & AssistantFileResponse>(
    {
      assistant_id: {},
      business_file_id: {},
      business_file_ids: {},
      id: {},
    },
    t,
    'assistant.assistant_file',
  )
  return nd
}
