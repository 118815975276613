import { Collapse } from 'antd'
// ----------------------------------------------------------------------

export const ExpandWrapper = ({
  content,
  label,
}: {
  content: JSX.Element
  label?: JSX.Element
}) => {
  const items: any = [
    {
      key: 0,
      label,
      children: content,
    },
  ]
  return <Collapse defaultActiveKey={undefined} items={items} />
}
