import type { TableProps } from 'antd/lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Actions from '../../../components/Actions'
import { onPageChange } from '../../../components/Form/helpers'
import Debug from '../../../components/helpers/Debug'
import MyTable from '../../../components/MyTable'
import type { BusinessFileResponse } from '../../../redux/api/business/business_file'
import {
  useBusinessFileDeleteMutation,
  useBusinessFilePageQuery,
} from '../../../redux/api/business/business_file'
import {
  getBusinessFileModelDescriptor,
  setBusinessFileDeleteId,
} from '../../../redux/slices/business/businessFile'

import UploadButton from './UploadButton'

type Props = {
  businessId: string
}

interface DataType {
  key: string
  name: string
  age: number
  address: string
  tags: string[]
}

export default function BusinessFileTable({ businessId }: Props) {
  const { t } = useTranslation()
  const [businessFiles, setBusinessFiles] = useState<BusinessFileResponse[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [pageSize, setPageSize] = useState<number>(20)
  const bfmd = getBusinessFileModelDescriptor(t)
  const { page } = bfmd.useFilters(searchParams)
  const dispatch = useAppDispatch()
  const [businessFileDelete] = useBusinessFileDeleteMutation()
  const { deleteId, editId } = useAppSelector(
    (state) => state.business.businessFile,
  )

  const { data, isFetching: getManyIsFetching } = useBusinessFilePageQuery({
    businessId,
    page,
    pageSize,
  })
  const columns: TableProps<BusinessFileResponse>['columns'] = [
    {
      title: bfmd.fields.file_name.label,
      dataIndex: bfmd.fields.file_name.name,
      key: bfmd.fields.file_name.name,
      render: (text) => <>{text}</>,
    },
    {
      title: bfmd.fields.passage_count.label,
      dataIndex: bfmd.fields.passage_count.name,
      key: bfmd.fields.passage_count.name,
      render: (text) => <>{text}</>,
    },
    {
      title: bfmd.fields.content_type.label,
      dataIndex: bfmd.fields.content_type.name,
      key: bfmd.fields.content_type.name,
      render: (text) => <>{text}</>,
    },
    {
      title: t('components.form.actions.label'),
      width: 400,
      render: (value, obj, index) => (
        <Actions
          isRowDeleting={deleteId === obj.id}
          onDelete={async () => {
            await businessFileDelete({ id: obj.id })
          }}
          onDeleteClose={() =>
            dispatch(setBusinessFileDeleteId({ deleteId: undefined }))
          }
          onDeletePress={() =>
            dispatch(setBusinessFileDeleteId({ deleteId: obj.id }))
          }
          // onEditPress={() => {
          //   dispatch(setBusinessFileEditId({ editId: obj.id }))
          //   setOpen(true)
          // }}
          // dropdownDisabled={deleteId !== undefined}
        />
      ),
    },
  ]
  useEffect(() => {
    if (data) {
      setBusinessFiles(data.results)
    }
  }, [data])

  const isFetching = getManyIsFetching

  return (
    <>
      <Debug debug objects={{ page }} />

      <MyTable
        // orderer={
        //   <CompanyOrderer
        //     ordering={ordering}
        //     setOrdering={setOrdering}
        //     selectProps={{
        //       disabled: isFetching,
        //     }}
        //   />
        // }
        btns={[<UploadButton businessId={businessId} />]}
        columns={columns}
        loading={isFetching}
        dataSource={businessFiles}
        // expandable={expandable}
        pagination={{
          position: ['bottomRight'],
          total: data?.count,
          current: page,
          pageSize,
          disabled: isFetching,
          pageSizeOptions: [2, 5, 10, 15, 20, 50, 100],
          showSizeChanger: true,
          onChange: onPageChange(searchParams, setSearchParams, setPageSize),
        }}
      />
    </>
  )
}
