// ----------------------------------------------------------------------

import { Button, Card, List, Typography } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import { useOrderListQuery } from '../../redux/api/edu/order'
import {
  getOrderModelDescriptor,
  orderSetEditId,
} from '../../redux/slices/edu/order'
import { getOnSelect } from '../../utils/onSelect'

import OrdersForm from './components/OrdersForm'
type Props = {
  subcategory_id: string
}

export default function PlayGroundSider() {
  const { teacher_discipline_id, category_id, subcategory_id, order_id } =
    useParams()
  const { t } = useTranslation()

  const [ordersFormOpen, setOrdersFormOpen] = useState(false)
  const { user } = useAppSelector((state) => state.auth)

  const { editId } = useAppSelector((state) => state.edu.order)
  const dispatch = useDispatch()

  const fmd = getOrderModelDescriptor(t)
  return (
    <>
      <Sider
        width={400}
        style={{
          borderRadius: 10,
          marginInline: '15px',
        }}
      >
        <div
          style={{
            borderColor: 'var(--color-light-gray)',
            border: '10',
            borderRadius: '12px',
            borderWidth: '1px',
            background: 'var(--color-white)',
            padding: '15px',
          }}
        >
          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              // marginInline: 20,
            }}
          >
            <Typography.Title level={3} style={{ paddingLeft: '5px' }}>
              {t('layouts.playground.sider.title')}
            </Typography.Title>
            <Button
              type="primary"
              onClick={() => setOrdersFormOpen(true)}
              disabled={
                teacher_discipline_id === undefined ||
                category_id === undefined ||
                subcategory_id === undefined
              }
            >
              {fmd.form.action.create()}
            </Button>
          </div>
          {subcategory_id && <Wrapper subcategory_id={subcategory_id} />}

          <OrdersForm
            open={ordersFormOpen}
            setOpen={setOrdersFormOpen}
            orderEditId={editId}
            onClose={() => {
              setOrdersFormOpen(false)
              dispatch(orderSetEditId(undefined))
            }}
          />
        </div>
      </Sider>
    </>
  )
}

const Wrapper = ({ subcategory_id }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { teacher_discipline_id, category_id } = useParams()

  const { data: orders } = useOrderListQuery({ subcategoryId: subcategory_id })

  const onSelect = getOnSelect(
    navigate,
    teacher_discipline_id,
    category_id,
    subcategory_id,
  )
  return (
    <List
      dataSource={orders}
      renderItem={(item) => (
        <List.Item
          style={{
            padding: 0,
            paddingBlock: 5,
          }}
        >
          <Card
            title={
              <Typography.Title
                level={3}
                style={{ fontSize: '1.5rem', color: 'white' }}
              >
                {item.title}
              </Typography.Title>
            }
            hoverable
            style={{
              width: '100%',
              borderRadius: 12,
              outlineColor: 'var(--color-accent-blue)',
              outlineWidth: '2.4px',
              outlineStyle: 'auto',
              background:
                'linear-gradient(135deg, var(--color-primary-purple), var(--color-primary-orange))',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            }}
            styles={{
              body: {
                transform: 'translateY(0)',
              },
            }}
            onClick={() => {
              onSelect('order')(item._id)
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-5px)'
              e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.2)'
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'translateY(0)'
              e.currentTarget.style.boxShadow = 'none'
            }}
          >
            {item.user_instructions}
          </Card>
        </List.Item>
      )}
      split={false}
    />
  )
}
