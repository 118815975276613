import { eduApi as api } from './index'
export const addTagTypes = ['sub_category'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      subcategoryGet: build.query<
        SubcategoryGetApiResponse,
        SubcategoryGetApiArg
      >({
        query: (queryArg) => ({ url: `/edu/sub_category/${queryArg.id}` }),
        providesTags: ['sub_category'],
      }),
      subcategoryUpdate: build.mutation<
        SubcategoryUpdateApiResponse,
        SubcategoryUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/sub_category/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.subcategoryBody,
        }),
        invalidatesTags: ['sub_category'],
      }),
      subcategoryCreate: build.mutation<
        SubcategoryCreateApiResponse,
        SubcategoryCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/sub_category/`,
          method: 'POST',
          body: queryArg.subcategoryBody,
        }),
        invalidatesTags: ['sub_category'],
      }),
      subcategoryListByCategory: build.query<
        SubcategoryListByCategoryApiResponse,
        SubcategoryListByCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/edu/sub_category/`,
          params: { category_id: queryArg.categoryId },
        }),
        providesTags: ['sub_category'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as sub_categoryApi }
export type SubcategoryGetApiResponse =
  /** status 200 Successful Response */ SubcategoryResponse
export type SubcategoryGetApiArg = {
  id: string
}
export type SubcategoryUpdateApiResponse =
  /** status 201 Successful Response */ SubcategoryResponse
export type SubcategoryUpdateApiArg = {
  id: string
  subcategoryBody: SubcategoryBody
}
export type SubcategoryCreateApiResponse =
  /** status 201 Successful Response */ SubcategoryResponse
export type SubcategoryCreateApiArg = {
  subcategoryBody: SubcategoryBody
}
export type SubcategoryListByCategoryApiResponse =
  /** status 200 Successful Response */ SubcategoryResponse[]
export type SubcategoryListByCategoryApiArg = {
  /** Category UUID */
  categoryId: string
}
export type SubcategoryResponse = {
  /** The name of the subcategory. Defaults to 'Untitled Subcategory' if not provided. */
  title?: string | null
  /** The UUID of the related Discipline. */
  teachers_discipline_id: string
  /** The UUID of the related Category. */
  category_id: string
  /** The unique identifier of the subcategory. */
  id: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type SubcategoryBody = {
  /** The name of the subcategory. Defaults to 'Untitled Subcategory' if not provided. */
  title?: string | null
  /** The UUID of the related Discipline. */
  teachers_discipline_id: string
  /** The UUID of the related Category. */
  category_id: string
}
export const {
  useSubcategoryGetQuery,
  useLazySubcategoryGetQuery,
  useSubcategoryUpdateMutation,
  useSubcategoryCreateMutation,
  useSubcategoryListByCategoryQuery,
  useLazySubcategoryListByCategoryQuery,
} = injectedRtkApi
