import { t } from '../i18n'

import type { InterfaceRouteConfig } from './paths'
import { paths } from './paths'

export const AdminChatRoute: InterfaceRouteConfig = {
  title: t('pages.adminUsers.title'),
  path: paths.adminUsers,
}
export const AdminTenantsRoute: InterfaceRouteConfig = {
  title: t('pages.adminTenants.title'),
  path: paths.adminTenants,
}
