import { message } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import type { TChat } from '../../../../redux/api/assistant/enhanced'
import type { RunBody, RunResponse } from '../../../../redux/api/assistant/run'
import { useLazyRunGetManyQuery } from '../../../../redux/api/assistant/run'
import type {
  ChatThreadResponse,
  ThreadBody,
} from '../../../../redux/api/assistant/thread'
import {
  useLazyThreadGetChatResponseQuery,
  useThreadCreateMutation,
  useThreadDeleteMutation,
} from '../../../../redux/api/assistant/thread'
import { baseUrl } from '../../../../redux/baseQuery'
import { params, paths } from '../../../../routes/paths'

let abortController: AbortController | undefined
export const userSuffix = '_a'
export const assistantSuffix = '_b'

interface Chunk {
  text?: string
  type?: 'end'
  run?: RunResponse
}

function parseDataFromChunk(chunk: string):
  | {
      message_id?: string
      message?: string
      type?: 'end'
      extra?: RunResponse
    }
  | undefined {
  const result: {
    message_id?: string
    message?: string
    type?: 'end'
    extra?: RunResponse
  } = { message: '' }

  const parseChunk = (chunk: string): Chunk | undefined => {
    try {
      const splat = chunk.split('data: ')
      const data = splat[1]
      return data ? (JSON.parse(data) as Chunk) : undefined
    } catch {
      return undefined
    }
  }

  chunk.split('\n\n').forEach((el) => {
    const data = parseChunk(el)
    if (data?.text) {
      result.message += data.text
    } else if (data?.type) {
      result.type = data.type
      result.extra = data.run
    }
  })

  return result
}

const streamQuery = async ({
  runBody,
  assistantId,
  threadId,
  signal,
  accessToken,
}: {
  runBody: RunBody
  assistantId: string
  threadId: string
  signal: AbortSignal | undefined
  accessToken: string | null
}) => {
  const url = `${baseUrl}/assistant/run/${assistantId}/${threadId}/stream`
  console.log(url, baseUrl)
  return fetch(url, {
    method: 'POST',
    signal,
    headers: {
      Accept: '*/*',
      'Accept-Language': 'en-US,en;q=0.9',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(runBody),
  })
}

const regenerateQuery = async ({
  assistantId,
  threadId,
  runId,
  signal,
  accessToken,
}: {
  assistantId: string
  threadId: string
  runId: string
  signal: AbortSignal | undefined
  accessToken: string | null
}) => {
  const url = `${baseUrl}/assistant/run/${assistantId}/${threadId}/${runId}/regenerate`
  console.log(url, baseUrl)
  return fetch(url, {
    method: 'POST',
    signal,
    headers: {
      Accept: '*/*',
      'Accept-Language': 'en-US,en;q=0.9',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

const transform = (run: RunResponse): TChat[] => {
  const { id, query, response } = run
  return [
    {
      id: `${id}${userSuffix}`,
      role: 'user',
      createAt: 1,
      updateAt: 2,
      content: query,
      extra: run,
    },
    {
      id: `${id}${assistantSuffix}`,
      role: 'assistant',
      createAt: 1,
      updateAt: 2,
      content: response,
      extra: run,
    },
  ]
}

export const useThreadAPI = ({
  user_id,
  assistantId,
  urlThreadId,
  accessToken,
}: {
  user_id?: string
  assistantId?: string
  urlThreadId?: string
  accessToken: string | null
}) => {
  const [getThread, { data: threadResponse }] =
    useLazyThreadGetChatResponseQuery()

  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >(undefined)

  const [thread, setThread] = useState<ChatThreadResponse | undefined>(
    undefined,
  )

  useEffect(() => {
    if (threadResponse) {
      setThread(threadResponse)
    }
  }, [threadResponse])

  useEffect(() => {
    if (urlThreadId && assistantId)
      getThread({ assistantId, threadId: urlThreadId })
  }, [urlThreadId])
  const [runGetMany, { data: runs, isFetching: areRunsLoading }] =
    useLazyRunGetManyQuery()

  const [threadDelete, {}] = useThreadDeleteMutation()

  useEffect(() => {
    setThreadId(urlThreadId)
  }, [urlThreadId])

  const cleanUp = () => {
    setChats([])
    if (assistantId)
      navigate(
        paths.userChat
          .replace(params.assistant_id, assistantId)
          .replace(params.thread_id, ''),
      )
    setThread(undefined)
    setThreadId(undefined)
  }

  const chatDelete = async () => {
    if (threadId && assistantId) {
      await threadDelete({ assistantId, threadId: threadId })
      cleanUp()
    }
  }

  useEffect(() => {
    if (urlThreadId && assistantId)
      runGetMany({ assistantId, threadId: urlThreadId })
  }, [assistantId, urlThreadId])

  const [chats, setChats] = useState<TChat[]>([])
  const [msgId, setMsgId] = useState<string | undefined>(undefined)

  const [feedbackChat, setFeedbackChat] = useState<TChat | undefined>(undefined)
  const [threadId, setThreadId] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const startChat = async (threadBody: ThreadBody) => {
    console.log(threadBody)
    const { data } = await threadCreate({
      threadBody,
    })
    if (!data) {
      return undefined
    }
    const threadId = data.id

    appendThreadId(threadId)
    setThreadId(threadId)
    setChats([])
    setThread(data)
    return data
  }

  const { assistant_id } = useParams()
  const navigate = useNavigate()

  const appendThreadId = (thread_id: string) => {
    if (assistant_id)
      navigate(
        paths.userChat
          .replace(params.assistant_id, assistant_id)
          .replace(params.thread_id, thread_id),
      )
  }

  useEffect(() => {
    if (runs) {
      runs.forEach((run) => {
        setChats((prev) => [...prev].concat(transform(run)))
      })
    }
  }, [runs])

  const handleResponse = async (response: Response) => {
    if (!response.body) return

    if (!response.ok) {
      const errorResponse = (await response.json()) as { message?: string }
      const errorMessage =
        errorResponse.message ?? `HTTP error! status: ${response.statusText}`
      void message.error(errorMessage)
      throw new Error(errorMessage)
    }
    const reader = response.body.getReader()
    const decoder = new TextDecoder()

    void reader.read().then(function processText({ done, value }) {
      if (done) {
        setIsLoading(false)
        return
      }
      const chunk = decoder.decode(value, { stream: true })
      const nextResult = parseDataFromChunk(chunk)

      const handlesh = (item: TChat, index: number, prev: TChat[]) => {
        if (![prev.length - 1, prev.length - 2].includes(index)) return item
        // console.log(item)
        // console.log(nextResult)
        let ret: TChat
        if (nextResult?.type === 'end' && nextResult.extra) {
          // console.log(nextResult)
          setIsLoading(false)
          ret = {
            ...item,
            extra: nextResult.extra,
          }
          if (index === prev.length - 1) {
            ret.id = `${nextResult.extra.id}${assistantSuffix}`
          } else {
            ret.id = `${nextResult.extra.id}${userSuffix}`
          }
        } else ret = { ...item }
        if (index === prev.length - 1) {
          ret.content = (item.content ?? '') + (nextResult?.message ?? '')
        }
        return ret
      }

      setChats((prev) => {
        return prev.map((item, index) => handlesh(item, index, prev))
      })

      void reader.read().then(processText)
    })
  }

  const ask = async ({
    runBody,
    threadId,
  }: {
    runBody: Omit<RunBody, 'vllm_params'>
    threadId?: string
  }) => {
    if (thread && assistantId) {
      try {
        const ac = startNew()
        setIsLoading(true)
        const artId = crypto.randomUUID()
        setChats((prev) => [
          ...prev,
          {
            content: runBody.query,
            id: `${artId}${userSuffix}`,
            role: 'user',
            createAt: 1,
            updateAt: 2,
          },
          {
            content: '',
            id: `${artId}${assistantSuffix}`,
            role: 'assistant',
            createAt: 1,
            updateAt: 2,
          },
        ])
        const threadId: string = thread.id

        const response = await streamQuery({
          runBody: { ...runBody, vllm_params: thread.vllm_params },
          assistantId,
          threadId,
          signal: ac.signal,
          accessToken,
        })

        handleResponse(response)
      } catch (error) {
        //
      } finally {
        setIsLoading(false)
      }
    }
  }

  const startNew = () => {
    if (abortController !== undefined) {
      abortController.abort()
    }
    const nac = new AbortController()
    setAbortController(nac)
    return nac
  }

  const regenerate = async () => {
    const lastRun = chats[chats.length - 1]
    const runId = lastRun.extra?.id
    const ac = startNew()
    console.log(thread, runId, assistantId)
    if (thread && runId && assistantId) {
      try {
        setIsLoading(true)
        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === `${runId}${assistantSuffix}`
              ? { ...chat, content: '' }
              : chat,
          ),
        )
        const threadId: string = thread.id

        const response = await regenerateQuery({
          assistantId,
          threadId,
          runId,
          signal: ac.signal,
          accessToken,
        })

        handleResponse(response)
      } catch (error) {
        //
      } finally {
        setIsLoading(false)
      }
    }
  }
  const [threadCreate] = useThreadCreateMutation()

  const abortStream = () => {
    setIsLoading(false)
  }

  const reset = () => {
    setChats([])
  }
  const formatRunId = (initialId: string | undefined) =>
    initialId ? initialId.slice(0, initialId.length - 2) : undefined

  return {
    thread,
    setThread,
    formatRunId,
    ask,
    regenerate,
    msgId,
    abortStream,
    isLoading,
    areRunsLoading,
    chats,
    setChats,
    reset,
    feedbackChat,
    setMsgId,
    threadId,
    startChat,
    chatDelete,
  }
}
