import { Col, Row, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

const { Title, Paragraph } = Typography

function BlogSection() {
  const { t } = useTranslation()
  const blogPosts = [
    {
      title: t('pages.landing.blog.posts.toolsInTeaching.title'),
      description: t('pages.landing.blog.posts.toolsInTeaching.description'),
      link: '#',
    },
    {
      title: t('pages.landing.blog.posts.stories.title'),
      description: t('pages.landing.blog.posts.stories.description'),
      link: '#',
    },
  ]
  return (
    <section
      id="blog"
      style={{
        padding: '5rem 0', // Approximate py-20
        backgroundColor: '#f9fafb', // Similar to bg-gray-50
      }}
    >
      <div
        style={{
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 1.5rem', // Approx. px-6
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
          <Title
            level={2}
            style={{
              fontWeight: 'bold',
              fontSize: '1.875rem', // Approx. text-3xl
              color: 'var(--violet)',
              marginBottom: 0,
            }}
          >
            {t('pages.landing.blog.title')}
          </Title>
          <Paragraph style={{ color: '#4B5563', marginTop: '1rem' }}>
            {t('pages.landing.blog.description')}
          </Paragraph>
        </div>

        <Row gutter={[24, 32]}>
          {blogPosts.map((post, index) => (
            <Col key={index} xs={24} md={12}>
              <div
                style={{
                  background: '#ffffff',
                  padding: '1.5rem', // Approx. p-6
                  borderRadius: '0.5rem',
                  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                }}
              >
                <Title
                  level={3}
                  style={{
                    fontSize: '1.5rem', // Approx. text-2xl
                    fontWeight: '600',
                    color: 'var(--violet)',
                    marginBottom: '0.5rem', // Approx. mb-2
                  }}
                >
                  {post.title}
                </Title>
                <Paragraph
                  style={{
                    color: '#4B5563', // Approx. text-gray-600
                    marginBottom: '1rem', // Approx. mb-4
                  }}
                >
                  {post.description}
                </Paragraph>
                <a
                  href={post.link}
                  style={{
                    fontWeight: '600',
                    color: 'orange',
                    textDecoration: 'none',
                  }}
                >
                  {t('pages.landing.blog.more')}
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  )
}

export default BlogSection
