import { assistantApi as api } from './index'
export const addTagTypes = ['assistant_file'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      assistantFileCreate: build.mutation<
        AssistantFileCreateApiResponse,
        AssistantFileCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant_file/`,
          method: 'POST',
          body: queryArg.assistantFileBody,
        }),
        invalidatesTags: ['assistant_file'],
      }),
      assistantFileGetMany: build.query<
        AssistantFileGetManyApiResponse,
        AssistantFileGetManyApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant_file/`,
          params: { assistant_id: queryArg.assistantId },
        }),
        providesTags: ['assistant_file'],
      }),
      assistantFileDelete: build.mutation<
        AssistantFileDeleteApiResponse,
        AssistantFileDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/assistant/assistant_file/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['assistant_file'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as assistant_fileApi }
export type AssistantFileCreateApiResponse =
  /** status 201 Successful Response */ AssistantFileResponse[]
export type AssistantFileCreateApiArg = {
  assistantFileBody: AssistantFileBody
}
export type AssistantFileGetManyApiResponse =
  /** status 200 Successful Response */ AssistantFileResponse[]
export type AssistantFileGetManyApiArg = {
  /** UUID of the business to get assistants for */
  assistantId: string
}
export type AssistantFileDeleteApiResponse =
  /** status 200 Successful Response */ object
export type AssistantFileDeleteApiArg = {
  id: number
}
export type AssistantFileResponse = {
  id: number
  assistant_id: string
  business_file_id: string
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type AssistantFileBody = {
  assistant_id: string
  business_file_ids: string[]
}
export const {
  useAssistantFileCreateMutation,
  useAssistantFileGetManyQuery,
  useLazyAssistantFileGetManyQuery,
  useAssistantFileDeleteMutation,
} = injectedRtkApi
