import { Flex, Layout, Typography } from 'antd'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Page from '../../components/Page'
import { OrganizationRoute } from '../../routes'

const { Header, Footer, Sider, Content } = Layout

interface Props {}
const { Title } = Typography

export default function OrganizationPage({}: Props) {
  const { t } = useTranslation()
  const [contentWidth, setContentWidth] = useState(600) // Initial width
  const resizerRef = useRef<HTMLDivElement>(null)

  const startResize = (e: React.MouseEvent) => {
    const startX = e.clientX
    const startWidth = contentWidth

    const onMouseMove = (event: MouseEvent) => {
      const newWidth = startWidth + (event.clientX - startX)
      const maxWidth = window.innerWidth - 600
      setContentWidth(
        newWidth > 300 ? (newWidth > maxWidth ? maxWidth : newWidth) : 300,
      ) // Set minimum width
    }

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }

    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
  }

  const [collapsed, setCollapsed] = useState(false)

  return (
    <Page title={t(OrganizationRoute.title)}>
      <Title level={3}>{t(OrganizationRoute.title)}</Title>

      <Flex gap="middle" wrap style={{ width: '100%', height: '100%' }}>
        <Layout
          style={{
            borderRadius: 8,
            overflow: 'hidden',
            // width: 'calc(50% - 8px)',
            // maxWidth: 'calc(50% - 8px)',
          }}
        >
          <Sider
            width={contentWidth}
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            style={{
              textAlign: 'center',
              lineHeight: '120px',
              // color: '#fff',
              // backgroundColor: '#1677ff',
            }}
          >
            Sider
          </Sider>
          {!collapsed && (
            <div
              ref={resizerRef}
              onMouseDown={startResize}
              style={{
                width: '15px',
                cursor: 'ew-resize',
                backgroundColor: '#ddd',
              }}
            />
          )}
          <Layout>
            <Header
              style={{
                textAlign: 'center',
                color: '#fff',
                height: 64,
                paddingInline: 48,
                lineHeight: '64px',
                backgroundColor: '#4096ff',
              }}
            >
              Header
            </Header>
            <Content
              style={{
                textAlign: 'center',
                minHeight: 120,
                lineHeight: '120px',
                color: '#fff',
                backgroundColor: '#0958d9',
              }}
            >
              Content
            </Content>
            <Footer
              style={{
                textAlign: 'center',
                color: '#fff',
                backgroundColor: '#4096ff',
              }}
            >
              Footer
            </Footer>
          </Layout>
        </Layout>
      </Flex>
    </Page>
  )
}
