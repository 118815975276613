import { t } from '../i18n'

import type { InterfaceRouteConfig } from './paths'
import { paths } from './paths'

export const AccessDeniedRoute: InterfaceRouteConfig = {
  title: t('pages.accessDenied.title'),
  path: paths.accessDenied,
}

export const LoginRoute: InterfaceRouteConfig = {
  title: t('pages.login.title'),
  path: paths.login,
}

export const SignUpRoute: InterfaceRouteConfig = {
  title: t('pages.signUp.title'),
  path: paths.signUp,
}
