import type { TransferProps } from 'antd'
import { Button, Transfer } from 'antd'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Debug from '../../../components/helpers/Debug'
import { useAssistantFileGetManyQuery } from '../../../redux/api/assistant/assistant_file'
import type { BusinessFileResponse } from '../../../redux/api/business/business_file'
import { useBusinessFileGetManyQuery } from '../../../redux/api/business/business_file'
import { getAssistantFileModelDescriptor } from '../../../redux/slices/assistant/assistant_file'

type Props = {
  businessId: string
  assistantId: string
  targetKeys: string[] | undefined
  setTargetKeys: Dispatch<SetStateAction<string[] | undefined>>
}

export default function AssistantFileTransfer({
  businessId,
  assistantId,
  targetKeys,
  setTargetKeys,
}: Props) {
  const [businessFiles, setBusinessFiles] = useState<BusinessFileResponse[]>([])

  const { data } = useBusinessFileGetManyQuery({ businessId })
  const { data: assistantFiles } = useAssistantFileGetManyQuery({ assistantId })

  useEffect(() => {
    if (data) {
      setBusinessFiles(data)
    }
  }, [data])

  useEffect(() => {
    if (assistantFiles) {
      setTargetKeys(
        assistantFiles.map(({ business_file_id }) => business_file_id),
      )
    }
  }, [assistantFiles])

  const handleChange: TransferProps['onChange'] = (newTargetKeys) => {
    setTargetKeys(newTargetKeys.filter((v) => typeof v === 'string'))
  }

  const renderFooter: TransferProps['footer'] = (_, info) => {
    if (info?.direction === 'left') {
      return (
        <Button size="small" style={{ float: 'left', margin: 5 }}>
          Left button reload
        </Button>
      )
    }
    return (
      <Button size="small" style={{ float: 'right', margin: 5 }}>
        Right button reload
      </Button>
    )
  }

  const { t } = useTranslation()
  const afmd = getAssistantFileModelDescriptor(t)

  return (
    <>
      <Debug objects={{ businessFiles, targetKeys, assistantFiles }} />
      <Transfer
        dataSource={businessFiles}
        showSearch
        listStyle={{
          width: '100%',
          height: '100%',
        }}
        operations={[
          afmd.form.action.describe('append'),
          afmd.form.action.describe('remove'),
        ]}
        targetKeys={targetKeys}
        onChange={handleChange}
        render={(item) => `${item.file_name}`}
        // footer={renderFooter}
        rowKey={(obj) => obj.id}
        pagination={{
          pageSize: 10,
        }}
      />
    </>
  )
}
