import { businessApi as api } from './index'
export const addTagTypes = ['business_file'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      businessFileCreate: build.mutation<
        BusinessFileCreateApiResponse,
        BusinessFileCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business_file/`,
          method: 'POST',
          body: queryArg.body,
          params: { business_id: queryArg.businessId },
        }),
        invalidatesTags: ['business_file'],
      }),
      businessFileGetMany: build.query<
        BusinessFileGetManyApiResponse,
        BusinessFileGetManyApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business_file/`,
          params: { business_id: queryArg.businessId },
        }),
        providesTags: ['business_file'],
      }),
      businessFilePage: build.query<
        BusinessFilePageApiResponse,
        BusinessFilePageApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business_file/page/`,
          params: {
            business_id: queryArg.businessId,
            page_size: queryArg.pageSize,
            page: queryArg.page,
          },
        }),
        providesTags: ['business_file'],
      }),
      businessFileDelete: build.mutation<
        BusinessFileDeleteApiResponse,
        BusinessFileDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/business/business_file/${queryArg.id}/`,
          method: 'DELETE',
        }),
        invalidatesTags: ['business_file'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as business_fileApi }
export type BusinessFileCreateApiResponse =
  /** status 200 Successful Response */ BusinessFileResponse
export type BusinessFileCreateApiArg = {
  businessId: string
  body: BodyBusinessFileCreateBusinessBusinessFilePost
}
export type BusinessFileGetManyApiResponse =
  /** status 200 Successful Response */ BusinessFileResponse[]
export type BusinessFileGetManyApiArg = {
  /** UUID of the business to get assistants for */
  businessId: string
}
export type BusinessFilePageApiResponse =
  /** status 200 Successful Response */ BusinessFilePageResponse
export type BusinessFilePageApiArg = {
  /** UUID of the business to get files for */
  businessId: string
  /** Number of files per page */
  pageSize?: number
  /** Page number */
  page?: number
}
export type BusinessFileDeleteApiResponse =
  /** status 200 Successful Response */ any
export type BusinessFileDeleteApiArg = {
  id: string
}
export type ContentType = 'text/plain' | 'application/pdf'
export type BusinessFileResponse = {
  id: string
  business_id: string
  content_type: ContentType
  file_name: string
  passage_count: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type BodyBusinessFileCreateBusinessBusinessFilePost = {
  file?: Blob
}
export type BusinessFilePageResponse = {
  count: number
  page: number
  meta: object
  results: BusinessFileResponse[]
}
export const {
  useBusinessFileCreateMutation,
  useBusinessFileGetManyQuery,
  useLazyBusinessFileGetManyQuery,
  useBusinessFilePageQuery,
  useLazyBusinessFilePageQuery,
  useBusinessFileDeleteMutation,
} = injectedRtkApi
