import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { useAppSelector } from '../../app/hooks'
import Page from '../../components/Page'

import BusinessFileTable from './components/BusinessFileTable'
import { AssistantsRoute } from '../../routes'

interface Props {}

const { Title } = Typography

export default function BusinessFilesPage({}: Props) {
  const { t } = useTranslation()

  const [searchParams, setSearchParams] = useSearchParams()
  const { selectedBusiness } = useAppSelector(
    (state) => state.business.business,
  )

  const assistantId = searchParams.get('assistant')

  return (
    <Page
      title={t(AssistantsRoute.title)}
      styles={{ body: { padding: '10px' } }}
    >
      {selectedBusiness && (
        <>
          <BusinessFileTable businessId={selectedBusiness.id} />
        </>
      )}
    </Page>
  )
}
