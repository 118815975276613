import { QuestionCircleOutlined } from '@ant-design/icons'
import type { PopconfirmProps } from 'antd'
import {
  Button,
  Popconfirm,
  Progress,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import type { TableProps } from 'antd/lib'
import { round } from 'lodash'
import { useTranslation } from 'react-i18next'

import Debug from '../../components/helpers/Debug'
import MyTable from '../../components/MyTable'
import Page from '../../components/Page'
import type { UserAdminResponse } from '../../redux/api/auth/user'
import {
  useUserActivateMutation,
  useUserDeleteMutation,
  useUserGetManyQuery,
} from '../../redux/api/auth/user'
import { getUserModelDescriptor } from '../../redux/slices/auth/userSlice'
import { AdminTenantsRoute } from '../../routes'

type Props = {}

export default function AdminUsers({}: Props) {
  const { data } = useUserGetManyQuery({})
  const { t } = useTranslation()
  const umd = getUserModelDescriptor(t)
  const [activate] = useUserActivateMutation()
  const [deleteUser] = useUserDeleteMutation()
  const confirm: PopconfirmProps['onConfirm'] = (e) => {
    console.log(e)
  }

  const cancel: PopconfirmProps['onCancel'] = (e) => {
    console.log(e)
  }
  const columns: TableProps<UserAdminResponse>['columns'] = [
    {
      title: umd.fields.email.label,
      dataIndex: umd.fields.email.name,
      key: umd.fields.email.name,
      render: (text) => <>{text}</>,
    },
    {
      title: umd.fields.username.label,
      dataIndex: umd.fields.username.name,
      key: umd.fields.username.name,
      render: (text) => <>{text}</>,
    },
    {
      title: umd.fields.is_admin.label,
      dataIndex: umd.fields.is_admin.name,
      key: umd.fields.is_admin.name,
      render: (text) => <>{JSON.stringify(text)}</>,
    },
    {
      title: umd.fields.tenant.label,
      dataIndex: umd.fields.tenant.name,
      key: umd.fields.tenant.name,
      render: (text) => <>{JSON.stringify(text)}</>,
    },
    {
      title: umd.fields.roles.label,
      dataIndex: umd.fields.roles.name,
      key: umd.fields.roles.name,
      render: (text, record) => (
        <>
          {record.roles.map(({ name }) => (
            <Tag>{name}</Tag>
          ))}
        </>
      ),
    },
    {
      title: umd.fields.statistics.label,
      dataIndex: umd.fields.statistics.name,
      key: umd.fields.statistics.name,
      render: (text, record) => (
        <>
          {record.statistics.map(
            ({
              assistant_id,
              bad_run_count,
              bad_thread_count,
              good_run_count,
              good_thread_count,
            }) => (
              <div style={{ display: 'grid' }}>
                <Progress
                  type="line"
                  format={(percent, succ) => {
                    return (
                      <>
                        {`${percent}% (${good_run_count}/${bad_run_count + good_run_count}) good runs`}
                        &nbsp;
                        <Tooltip
                          placement="topLeft"
                          title={'good == has feedback'}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    )
                  }}
                  percent={
                    round(
                      good_run_count / (bad_run_count + good_run_count),
                      2,
                    ) * 100
                  }
                  style={{ maxWidth: '300px' }}
                  size={'small'}
                  strokeLinecap="butt"
                />
                <Progress
                  type="line"
                  format={(percent, succ) => {
                    return (
                      <>
                        {`${percent}% (${good_thread_count}/${bad_thread_count + good_thread_count}) good threads`}
                        &nbsp;
                        <Tooltip
                          placement="topLeft"
                          title={'good == more than 5 runs in it'}
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </>
                    )
                  }}
                  percent={
                    round(
                      good_thread_count /
                        (bad_thread_count + good_thread_count),
                      2,
                    ) * 100
                  }
                  style={{ maxWidth: '300px' }}
                  size={'small'}
                  strokeLinecap="butt"
                />
              </div>
            ),
          )}
        </>
      ),
    },
    {
      title: 'Delete',
      dataIndex: 'Delete',
      key: 'Delete',
      render: (text, record) => (
        <Popconfirm
          title="Delete the task"
          description="Are you sure to delete this task?"
          onConfirm={async () => {
            await deleteUser({ id: record.id })
          }}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      ),
    },
    {
      title: 'Activate',
      dataIndex: 'Activate',
      key: 'Activate',
      render: (text, record) => (
        <Switch
          defaultChecked
          value={record.is_activated}
          onChange={async () => {
            console.log(record.id)
            await activate({
              email: record.email,
              activatedRequest: { is_activated: !record.is_activated },
            })
          }}
        />
      ),
    },
  ]

  // Assuming data is the array of UserAdminResponse items
  const groupedData = data?.reduce(
    (acc, user) => {
      const tenant = user.tenant || 'No Tenant' // Group users with no tenant

      // Initialize tenant group if it doesn't exist
      if (!acc[tenant]) {
        acc[tenant] = {
          tenant,
          goodThreadCount: 0,
          goodRunCount: 0,
        }
      }

      // Aggregate goodThreadCount and goodRunCount for the tenant
      user.statistics.forEach((stat) => {
        acc[tenant].goodThreadCount += stat.good_thread_count
        acc[tenant].goodRunCount += stat.good_run_count
      })

      return acc
    },
    {} as Record<
      string,
      { tenant: string; goodThreadCount: number; goodRunCount: number }
    >,
  )

  const toxicColumns = [
    {
      title: 'Tenant',
      dataIndex: 'tenant',
      key: 'tenant',
    },
    {
      title: 'Good Threads',
      dataIndex: 'goodThreadCount',
      key: 'goodThreadCount',
    },
    {
      title: 'Good Runs',
      dataIndex: 'goodRunCount',
      key: 'goodRunCount',
    },
  ]

  const tableData = Object.values(groupedData ? groupedData : [])

  return (
    <Page
      title={t(AdminTenantsRoute.title)}
      layoutStyle={{ overflow: 'auto', height: '100%' }}
    >
      <Debug debug objects={{ data }} />
      <Debug debug objects={{ tableData, groupedData }} />
      <Typography.Title level={3}>Toxicity as a virtue</Typography.Title>
      <Table
        columns={toxicColumns}
        dataSource={tableData}
        rowKey="tenant"
        pagination={{
          pageSize: 50,
        }}
      />
    </Page>
  )
}
