import type { DescriptionsProps } from 'antd'
import { Descriptions } from 'antd'
import { useTranslation } from 'react-i18next'

import type { UserResponse } from '../../../redux/api/auth/auth'

type Props = { user: UserResponse }
export default function ProfileCard({ user }: Props) {
  const { t } = useTranslation()
  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: t('pages.profile.user.descriptions.username'),
      children: user.username,
    },
    {
      key: '2',
      label: t('pages.profile.user.descriptions.email'),
      children: user.email,
    },
    {
      key: '3',
      label: t('pages.profile.user.descriptions.id'),
      children: user.id,
    },
    {
      key: '3',
      label: t('pages.profile.user.descriptions.roles'),
      children: user.roles.map(({ name }) => name).join(', '),
    },
  ]
  return (
    <Descriptions
      // title={t('pages.profile.user.descriptions.title')}
      items={items}
      bordered
      column={1}
    />
  )
}
