import { ConfigProvider, theme } from 'antd'
import enUS from 'antd/locale/en_US'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import { useAppSelector } from './app/hooks'
import './i18n'
import './index.css'
import Router from './Router'
import { darkThemeConfig, lightThemeConfig } from './theme'

const App = () => {
  const { defaultAlgorithm, darkAlgorithm } = theme
  const { isDarkMode } = useAppSelector((state) => state.main)
  const { accessToken } = useAppSelector((state) => state.auth)
  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        ...(isDarkMode ? darkThemeConfig : lightThemeConfig),
      }}
    >
      <ToastContainer />
      <div className="App">
        <Router />
      </div>
    </ConfigProvider>
  )
}

export default App
