import { Col, Form, Input, Modal, Row } from 'antd'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import type { IFormBody } from '../../../components/Form/helpers'
import Debug from '../../../components/helpers/Debug'
import DebugFormBtn from '../../../components/helpers/DebugFormBtn'
import type { TChat } from '../../../redux/api/assistant/enhanced'
import {
  useRunCreateFeedbackMutation,
  type FeedbackBody,
  type FeedbackResponse,
} from '../../../redux/api/assistant/run'
import type { BusinessBody } from '../../../redux/api/business/business'
import { getFeedbackModelDescriptor } from '../../../redux/slices/assistant/feedback'

import LikeDislikeComponent from './LikeDislikeComponent'

interface Props {
  open: boolean
  response?: string | null
  setOpen: Dispatch<SetStateAction<boolean>>
  chat: TChat
  onSuccess: (feedback: FeedbackResponse) => Promise<void>
}

export default function FeedbackForm({
  open,
  setOpen,
  onSuccess,
  response,
  chat,
}: Props) {
  const { t } = useTranslation()
  const fmd = getFeedbackModelDescriptor(t)

  type FormBody = IFormBody<FeedbackBody, never>
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [form] = Form.useForm<FormBody>()

  useEffect(() => {
    form.setFieldsValue({})
  }, [])

  const cleanup = () => {
    form.setFieldsValue({
      improvement: undefined,
      like: undefined,
    })
  }

  const handleClose = () => {
    cleanup()
    setOpen(false)
  }

  const handleOk = async () => {
    const { like, improvement } = form.getFieldsValue()
    setConfirmLoading(true)
    console.log(like, improvement, chat)
    if (chat.extra) {
      const { data } = await feedbackCreate({
        assistantId: chat.extra.assistant_id,
        feedbackBody: { improvement, like },
        runId: chat.extra.id,
        threadId: chat.extra.thread_id,
      })
      if (data) {
        await onSuccess(data)
        fmd.toast.created()
        form.resetFields()
        setOpen(false)
      }
    }
  }

  const [serverErrors, setServerErrors] = useState<{
    [key in keyof BusinessBody]?: string
  }>({})

  const createBtnText = t('components.form.control.submit')

  const cancelBtnText = t('components.form.control.cancel')

  const values = Form.useWatch([], form)

  const [feedbackCreate] = useRunCreateFeedbackMutation()

  const initialValues: FeedbackBody = {
    improvement: chat?.extra?.feedback?.improvement || response,
    like: chat?.extra?.feedback?.like,
  }

  // useEffect(() => {
  //   console.log(initialValues)
  //   if (open) form.resetFields()
  // }, [chat, open])

  return (
    <Modal
      title={fmd.form.action.create()}
      open={open}
      width={'800px'}
      onOk={handleOk}
      // confirmLoading={confirmLoading}
      onCancel={handleClose}
      okText={createBtnText}
      cancelText={cancelBtnText}
      okButtonProps={{ title: createBtnText }}
      cancelButtonProps={{ title: cancelBtnText }}
      afterOpenChange={() => form.resetFields()}
    >
      <Form
        form={form}
        labelCol={{ span: 24 }}
        size="small"
        initialValues={initialValues}
      >
        <Debug debug objects={{ initialValues }} />
        <Debug debug objects={{ values, chat }} />
        <Row gutter={30}>
          <Col span={24}>
            <Form.Item
              name="improvement"
              label={t('assistant.feedback.fields.improvement.label')}
            >
              <Input.TextArea
                allowClear
                style={{ width: '100%' }}
                rows={7}
                placeholder={t(
                  'assistant.feedback.fields.improvement.placeholder',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            {/* like */}
            <Form.Item name="like">
              <LikeDislikeComponent />
            </Form.Item>
          </Col>
        </Row>
        <DebugFormBtn form={form} />
      </Form>
    </Modal>
  )
}
