export enum params {
  assistant_id = ':assistant_id',
  thread_id = ':thread_id',
  teacher_discipline_id = ':teacher_discipline_id',
  category_id = ':category_id',
  subcategory_id = ':subcategory_id',
  order_id = ':order_id',
}

export enum paths {
  // - general - //
  accessDenied = '/access-denied',
  home = '/',
  login = '/login',
  signUp = '/sign-up',
  about = '/about',
  activated = '/activated',

  apps = '/apps',

  // - admin - //
  adminBase = '/admin',
  adminUsers = '/admin/users',
  adminTenants = '/admin/tenants',

  // - edu - //
  eduPlayground = `/playground`,
  eduPlayground1 = `/playground/${params.teacher_discipline_id}`,
  eduPlayground2 = `/playground/${params.teacher_discipline_id}/${params.category_id}`,
  eduPlayground3 = `/playground/${params.teacher_discipline_id}/${params.category_id}/${params.subcategory_id}`,
  eduPlayground4 = `/playground/${params.teacher_discipline_id}/${params.category_id}/${params.subcategory_id}/${params.order_id}`,
  profile = '/profile',
  help = '/help',
  // - user - //
  userBase = '/user',
  userChat = `/user/chat/${params.assistant_id}/${params.thread_id}?`,

  // - client - //
  clientBase = '/client',
  //   clientHome = '/client/home',
  // dashboard
  clientDashboard = '/client/dashboard',
  clientAssistants = '/client/dashboard/assistants',
  clientBuisnessFiles = '/client/dashboard/files',
  // settings
  clientSettings = '/client/settings',
  clientOrganization = '/client/settings/organization',
  clientBilling = '/client/settings/billing',
  clientBusiness = '/client/settings/business',
  clientMonitoring = '/client/settings/monitoring',
}

export const formatEduPlayGroundPath = (
  discipline_id?: string,
  category?: string,
  sub_category?: string,
  order_id?: string,
) => {
  let path = paths.eduPlayground4
    .replace(params.teacher_discipline_id, discipline_id || '')
    .replace(params.category_id, category || '')
    .replace(params.subcategory_id, sub_category || '')
    .replace(params.order_id, order_id || '')

  // Remove trailing slashes if no parameters were replaced
  path = path.replace(/\/{2,}/g, '/').replace(/\/\?$/, '?')

  return path
}

export const DefaultPath = paths.home

export interface InterfaceRouteConfig {
  title: string
  path: paths
  icon?: JSX.Element
}
