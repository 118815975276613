// ----------------------------------------------------------------------
import {
  DownOutlined,
  EditOutlined,
  MoreOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Col, Dropdown, Form, Input, Menu, Row, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import Sider from 'antd/es/layout/Sider'
import Typography from 'antd/es/typography/Typography'
import { useEffect, useState, type CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../app/hooks'
import type { IFormBody } from '../../components/Form/helpers'
import FormInput from '../../components/Form/Input'
import Debug from '../../components/helpers/Debug'
import Iconify from '../../components/Iconify'
import type {
  ThreadBody,
  ThreadUpdateBody,
} from '../../redux/api/assistant/thread'
import {
  useLazyThreadGetManyQuery,
  useThreadUpdateMutation,
} from '../../redux/api/assistant/thread'
import {
  getThreadModelDescriptor,
  setChatModalOpen,
} from '../../redux/slices/assistant/thread'
import { params, paths } from '../../routes/paths'
import { gray } from '../../theme'
// ----------------------------------------------------------------------
type Props = {}

type MenuItem = Required<MenuProps>['items'][number]

export default function UserChatSider({}: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isDarkMode } = useAppSelector((state) => state.main)
  const { user } = useAppSelector((state) => state.auth)

  const userId = user?.id
  const { assistant_id, thread_id } = useParams()
  const [threadGetMany, { data }] = useLazyThreadGetManyQuery()

  useEffect(() => {
    if (assistant_id && userId) {
      threadGetMany({ assistantId: assistant_id, userId })
    }
  }, [assistant_id, userId])

  const style: CSSProperties = {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '12px',
  }

  const childStyle: CSSProperties = {
    ...style,
    paddingLeft: '35px',
  }

  type FormBody = IFormBody<ThreadUpdateBody, never>
  const [form] = Form.useForm<FormBody>()
  const initialValues = {
    assistant_id: undefined,
    user_id: undefined,
    name: undefined,
  }

  const [editId, setEditId] = useState<undefined | string>(undefined)

  const [threadUpdate, {}] = useThreadUpdateMutation()

  const tmd = getThreadModelDescriptor(t)

  const dispatch = useAppDispatch()

  const items: MenuItem[] = [
    {
      key: '1',
      label: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {t('layouts.user.sider.chats.chats')}
          </Typography>
          <Button
            onClick={() => dispatch(setChatModalOpen(true))}
            icon={<PlusCircleOutlined />}
          ></Button>
        </div>
      ),
      style: {},
      type: 'group',
    } as MenuItem,
  ].concat(
    (data || []).map(({ id, name, assistant_id, user_id }) => ({
      key: id,
      style: {
        // pointerEvents: 'none',
        // paddingTop: '100px',
        // paddingBottom: '100px',
        cursor: 'default',
      },
      label: (
        <div>
          {id === editId ? (
            <Row style={{ paddingTop: '15px' }}>
              <Col xs={15}>
                <FormInput
                  itemProps={{
                    style: {
                      marginTop: '4px',
                    },
                    layout: 'horizontal',
                    label: undefined,
                  }}
                  inputProps={{
                    style: {
                      width: '100%',
                    },
                  }}
                  formField={tmd.fields.name}
                />
              </Col>
              <Col xs={6}>
                <Button
                  icon={<Iconify icon={'el:ok'} />}
                  style={{ marginLeft: '10px' }}
                  size="small"
                  htmlType="submit"
                />
                <Button
                  icon={<Iconify icon={'healthicons:no-outline'} />}
                  size="small"
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    setEditId(undefined)
                  }}
                />
              </Col>
            </Row>
          ) : (
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                padding: 0,
                margin: 0,
              }}
            >
              <a
                href={paths.userChat
                  .replace(params.assistant_id, assistant_id || '')
                  .replace(params.thread_id, id)}
              >
                {name}
              </a>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      disabled: editId !== undefined,
                      onClick: () => {
                        setEditId(id)
                        form.setFieldsValue({
                          name,
                          assistant_id,
                          user_id,
                        })
                      },
                      label: <Typography>Edit</Typography>,
                    },
                  ],
                }}
                trigger={['click']}
              >
                <Button
                  size="small"
                  type="text"
                  style={{ margin: '10px', marginRight: '0', padding: 0 }}
                >
                  <Space>
                    <MoreOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          )}
        </div>
      ),
    })),
  )

  return (
    <Sider
      width={256}
      style={{
        // minHeight: '100vh',
        height: '100%',
        backgroundColor: isDarkMode ? gray : undefined,
      }}
    >
      <Debug
        debug
        objects={{
          data,
          pathname,
        }}
      />
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={async ({ assistant_id, name }) => {
          console.log(name, assistant_id, editId)
          if (name && assistant_id && editId) {
            const { data } = await threadUpdate({
              assistantId: assistant_id,
              threadId: editId,
              threadUpdateBody: { name },
            })
            if (data) {
              setEditId(undefined)
            }
          }
        }}
      >
        <Form.Item hidden name={tmd.fields.id.name} />
        <Form.Item hidden name={tmd.fields.assistant_id.name} />
        <Form.Item hidden name={tmd.fields.user_id.name} />
        <Menu
          mode="inline"
          items={items}
          selectedKeys={[...(thread_id ? [thread_id] : [])]}
          style={{ backgroundColor: isDarkMode ? gray : undefined }}
        />
      </Form>
    </Sider>
  )
}
