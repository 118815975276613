import assistant from './en-US/assistant.json'
import assistant_file from './en-US/assistant_file.json'
import business from './en-US/business.json'
import business_file from './en-US/business_file.json'
import feedback from './en-US/feedback.json'
import footer from './en-US/footer.json'
import form from './en-US/form.json'
import language_selector from './en-US/language_selector.json'
import layouts from './en-US/layouts.json'
import navbar from './en-US/navbar.json'
import order from './en-US/order.json'
import pages from './en-US/pages.json'
import select from './en-US/select.json'
import teacherDiscipline from './en-US/teacherDiscipline.json'
import thread from './en-US/thread'
import user from './en-US/user.json'
import vllm_params from './en-US/vllm_params.json'

export default {
  trademark: 'ProntoEdu',
  business: {
    business,
    business_file,
  },
  edu: {
    teacherDiscipline,
    order,
  },
  assistant: {
    assistant,
    assistant_file,
    thread,
    feedback,
    vllm_params,
  },
  auth: {
    user,
  },
  components: {
    footer,
    form,
    language_selector,
    navbar,
    select,
  },
  layouts,
  pages,
}
