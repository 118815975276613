import { Form, Select } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { FormItemProps, SelectProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { LanguageCode } from '../../redux/api/assistant/assistant'
import type { FormField } from '../../utils/modelDescriptor/formFields'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
  options: DefaultOptionType[]
} & SelectProps

export default function FormSelect({
  itemProps,
  formField,
  options,
  ...props
}: Props) {
  const id = useId()
  const { t } = useTranslation()
  type RequiredOptions = {
    [K in LanguageCode]: string
  }

  return (
    <Form.Item
      id={id}
      name={formField?.name}
      rules={formField?.rules}
      hasFeedback={formField?.rules !== undefined}
      // validateTrigger={formField?.rules !== undefined ? 'validateTrigger' : undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <Select
        {...props}
        placeholder={t(formField.placeholder)}
        allowClear
        options={options}
      />
    </Form.Item>
  )
}
