import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { TFunction } from 'i18next'

import { ModelDescriptor } from '../../../utils/modelDescriptor'
import type {
  BodyBusinessFileCreateBusinessBusinessFilePost,
  BusinessFileResponse,
} from '../../api/business/business_file'

export interface BusinessFileState {
  editId: string | undefined
  deleteId: string | undefined
}

const initialState: BusinessFileState = {
  editId: undefined,
  deleteId: undefined,
}

export const businessFileSlice = createSlice({
  name: 'business/business_file',
  initialState,
  reducers: {
    setBusinessFileEditId: (
      state,
      action: PayloadAction<{ editId: string | undefined }>,
    ) => {
      state.editId = action.payload.editId
    },
    setBusinessFileDeleteId: (
      state,
      action: PayloadAction<{ deleteId: string | undefined }>,
    ) => {
      state.deleteId = action.payload.deleteId
    },
  },
})

export const { setBusinessFileDeleteId, setBusinessFileEditId } =
  businessFileSlice.actions
export default businessFileSlice.reducer

export const getBusinessFileModelDescriptor = (t: TFunction) => {
  const nd = new ModelDescriptor<
    BodyBusinessFileCreateBusinessBusinessFilePost & BusinessFileResponse,
    | keyof BodyBusinessFileCreateBusinessBusinessFilePost
    | keyof BusinessFileResponse
  >(
    {
      id: {},
      file: {},
      file_name: {},
      business_id: {},
      passage_count: {},
      content_type: {},
    },
    t,
    'business.business_file',
  )
  return nd
}
