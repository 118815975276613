import { Form, Select } from 'antd'
import type { DefaultOptionType } from 'antd/es/select'
import type { FormItemProps, SelectProps } from 'antd/lib'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { TaskType } from '../../../redux/api/edu/order'
import type { FormField } from '../../../utils/modelDescriptor/formFields'

type Props = {
  formField: FormField<any>
  itemProps?: FormItemProps
} & SelectProps

export default function TaskTypeSelect({
  itemProps,
  formField,
  ...props
}: Props) {
  const id = useId()
  const { t } = useTranslation()
  const domain = 'edu.order.fields.task_type.options'
  const objs: { [key in TaskType]: string } = {
    'Analysis and estimation': `${domain}.Analysis and estimation`,
    'Essay writing': `${domain}.Essay writing`,
    'Exam preparation': `${domain}.Exam preparation`,
    'Lesson planning': `${domain}.Lesson planning`,
    'Material preparation': `${domain}.Material preparation`,
    'Pedagogical advice': `${domain}.Pedagogical advice`,
    'Questions to be researched': `${domain}.Questions to be researched`,
    'Quiz creation': `${domain}.Quiz creation`,
    'Test generation': `${domain}.Test generation`,
    Assignment: `${domain}.Assignment`,
  }

  const options: DefaultOptionType[] = Object.entries(objs).map(
    ([value, key]) => ({
      value,
      label: t(key),
    }),
  )

  return (
    <Form.Item
      id={id}
      name={formField?.name}
      rules={formField?.rules}
      hasFeedback={formField?.rules !== undefined}
      // validateTrigger={formField?.rules !== undefined ? 'validateTrigger' : undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <Select
        {...props}
        placeholder={t(formField.placeholder)}
        allowClear
        options={options}
      />
    </Form.Item>
  )
}
