import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import queryString from 'query-string'

import type { RootState } from '../app/store'
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export const baseUrl = import.meta.env.VITE_BASE_URL

export const baseQuery = fetchBaseQuery({
  // ! fix import.meta.env.BASE_URL
  baseUrl: baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
  paramsSerializer: (params: Record<string, unknown>) =>
    queryString.stringify(params, { arrayFormat: 'bracket' }),
})

export default baseQuery
