import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import type { FormItemProps } from 'antd'
import { Form, Switch as ANTDSwitch } from 'antd'
import type { SwitchProps } from 'antd/lib/switch'
import { useId } from 'react'
import { useTranslation } from 'react-i18next'

import type { FormField } from '../../utils/modelDescriptor/formFields'

interface Props {
  itemProps?: FormItemProps
  switchProps?: SwitchProps
  formField: FormField<any>
}

export default function FormSwitch({
  formField,
  itemProps = {},
  switchProps = {},
}: Props) {
  const { t } = useTranslation()
  const id = useId()

  return (
    <Form.Item
      id={id}
      name={formField?.name}
      rules={formField?.rules}
      hasFeedback={formField?.rules !== undefined}
      label={
        <label htmlFor={id} style={{ fontWeight: 'bold' }}>
          {t(formField.label)}
        </label>
      }
      {...itemProps}
    >
      <ANTDSwitch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked
        {...switchProps}
      />
    </Form.Item>
  )
}
