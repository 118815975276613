import { monitoringApi as api } from './index'
export const addTagTypes = ['health'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      health: build.query<HealthApiResponse, HealthApiArg>({
        query: () => ({ url: `/monitoring/health/` }),
        providesTags: ['health'],
      }),
      elasticHealth: build.query<ElasticHealthApiResponse, ElasticHealthApiArg>(
        {
          query: (queryArg) => ({
            url: `/monitoring/health/elastic`,
            params: { business_id: queryArg.businessId },
          }),
          providesTags: ['health'],
        }
      ),
      mongoHealth: build.query<MongoHealthApiResponse, MongoHealthApiArg>({
        query: (queryArg) => ({
          url: `/monitoring/health/mongo`,
          params: { business_id: queryArg.businessId },
        }),
        providesTags: ['health'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as healthApi }
export type HealthApiResponse = /** status 200 Successful Response */ Health
export type HealthApiArg = void
export type ElasticHealthApiResponse =
  /** status 200 Successful Response */ ElasticHealth
export type ElasticHealthApiArg = {
  /** UUID of the business to get assistants for */
  businessId: string
}
export type MongoHealthApiResponse =
  /** status 200 Successful Response */ MongoHealth
export type MongoHealthApiArg = {
  /** UUID of the business to get assistants for */
  businessId: string
}
export type Health = {
  version: string
  status: string
}
export type ElasticHealth = {
  document_count: number
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type MongoHealth = {
  collection_count: number
}
export const {
  useHealthQuery,
  useLazyHealthQuery,
  useElasticHealthQuery,
  useLazyElasticHealthQuery,
  useMongoHealthQuery,
  useLazyMongoHealthQuery,
} = injectedRtkApi
