import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../app/hooks'
import Debug from '../../components/helpers/Debug'
import Page from '../../components/Page'
import {
  useHealthQuery,
  useLazyElasticHealthQuery,
} from '../../redux/api/monitoring/health'
import { BusinessRoute } from '../../routes'

interface Props {}

export default function MonitoringPage({}: Props) {
  const { t } = useTranslation()
  const { selectedBusiness } = useAppSelector(
    (state) => state.business.business,
  )
  const { data } = useHealthQuery()

  const [getElasticHealth, { data: elasticData }] = useLazyElasticHealthQuery()

  useEffect(() => {
    if (selectedBusiness !== undefined) {
      getElasticHealth({
        businessId: selectedBusiness.id,
      })
    }
  }, [selectedBusiness])

  return (
    <Page title={t(BusinessRoute.title)}>
      <Debug debug objects={{ elasticData, data }} />
      {data && (
        <>
          <p>{data.status}</p>
          <p>{data.version}</p>
        </>
      )}
      {elasticData && (
        <>
          <p>{elasticData.document_count}</p>
        </>
      )}
    </Page>
  )
}
