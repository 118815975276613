import { Button, Col, Form, Input, Row } from 'antd'
import type { FormInstance } from 'antd/lib'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
// ----------------------------------------------------------------------

import { useAppSelector } from '../../../app/hooks'
import type { IFormBody } from '../../../components/Form/helpers'
import type { SignUpUserBody } from '../../../redux/api/auth/auth'
import { useSignUpMutation } from '../../../redux/api/auth/auth'
import { getUserModelDescriptor } from '../../../redux/slices/auth/userSlice'
import { DefaultPath } from '../../../routes/paths'
// ----------------------------------------------------------------------

type Props = {
  form: FormInstance<IFormBody<Required<SignUpUserBody>, never>>
  onSuccess: () => void
  onLogin: () => void
}

export default function SignUpForm({ onSuccess, form, onLogin }: Props) {
  const { t } = useTranslation()
  const { accessToken } = useAppSelector((state) => state.auth)
  const [signUp, { isLoading, error }] = useSignUpMutation()
  const signUpMD = getUserModelDescriptor(t, 'signUpForm')

  // type FormBody = IFormBody<Required<SignUpUserBody>, never>

  useEffect(() => {
    if (error !== undefined) {
      console.log(error)
      signUpMD.handleErrors(form as any, error)
    }
  }, [error])

  if (accessToken) {
    return <Navigate to={DefaultPath} />
  }

  const onFinish = async (values: Required<SignUpUserBody>) => {
    const signUpUserBody = {
      email: values.email,
      password: values.password,
      username: values.username,
      tenant: values.tenant,
    }

    try {
      await signUp({ signUpUserBody }).unwrap()
      signUpMD.toast.created()
      onSuccess()
    } catch (error) {
      // toast.error('Не правильно введен логин или пароль')
      console.error((error as any).data.detail)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <Row>
      <Col span={24}>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          disabled={isLoading}
          form={form}
          initialValues={{
            email: '',
            password: '',
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name={signUpMD.fields.username.name}
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            rules={signUpMD.fields.username.rules}
          >
            <Input placeholder={t(signUpMD.fields.username.placeholder)} />
          </Form.Item>
          <Form.Item
            name={signUpMD.fields.email.name}
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            rules={[...signUpMD.fields.email.rules]}
          >
            <Input placeholder={t(signUpMD.fields.email.placeholder)} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            name={signUpMD.fields.password.name}
            rules={signUpMD.fields.password.rules}
          >
            <Input.Password
              placeholder={t(signUpMD.fields.password.placeholder)}
            />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              style: {
                justifyContent: 'center',
              },
            }}
            name={signUpMD.fields.tenant.name}
            rules={signUpMD.fields.tenant.rules}
          >
            <Input placeholder={t(signUpMD.fields.tenant.placeholder)} />
          </Form.Item>
        </Form>
      </Col>
      <Col span={24}>
        {t(`${signUpMD.form.action.domain}.actions.login.question`)}
        <Button type="link" size="small" disabled={isLoading} onClick={onLogin}>
          {t(`${signUpMD.form.action.domain}.actions.login.button`)}
        </Button>
      </Col>
    </Row>
  )
}
