import logo from '../assets/back-image-logo.png'

function Logo() {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={logo}
        alt="Pronto-Edu Logo"
        style={{ height: '2rem', width: '2.25rem', marginRight: '0.5rem' }} // h-8 w-9 mr-2
      />
      <span
        style={{
          fontSize: '1.5rem', // text-2xl
          color: 'var(--violet)',
          fontWeight: 'bold',
        }}
      >
        Pronto-Edu
      </span>
    </div>
  )
}

export default Logo
