import { rbApi as api } from './index'
export const addTagTypes = ['country'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      countryGetMany: build.query<
        CountryGetManyApiResponse,
        CountryGetManyApiArg
      >({
        query: () => ({ url: `/rb/country/` }),
        providesTags: ['country'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as countryApi }
export type CountryGetManyApiResponse =
  /** status 200 Successful Response */ CountryResponse[]
export type CountryGetManyApiArg = void
export type CountryResponse = {
  id: number
  /** Country name */
  name: string
}
export const { useCountryGetManyQuery, useLazyCountryGetManyQuery } =
  injectedRtkApi
