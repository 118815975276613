import type { CascaderProps, GetProp } from 'antd'
import { Cascader } from 'antd'
import type { BaseOptionType as CascaderBaseOptionType } from 'antd/es/cascader'
import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import type { UserResponse } from '../../../redux/api/auth/auth'
import { useLazyUserGetManyQuery } from '../../../redux/api/auth/user'
import { setBusiness } from '../../../redux/slices/business/business'
import { denonify } from '../../../utils'

type BaseOptionType = Omit<CascaderBaseOptionType, 'value'> & {
  value: string
}

type DefaultOptionType = Omit<
  GetProp<CascaderProps<BaseOptionType>, 'options'>[number],
  'value' | 'label'
> & {
  value: string
  label: string
}

interface OptionType extends DefaultOptionType {
  isLeaf?: boolean
}

export type SingleValueType = (string | null)[]

type Props = {
  onChange?: (domain: [string | undefined, string | undefined]) => void
  value?: [string | undefined, string | undefined]
} & Omit<CascaderProps<OptionType>, 'value' | 'onChange'>

export default function BusinessCascader(props: Props) {
  const debounceTimeout = 300
  const { value, onChange, ...restProps } = props
  const [options, setOptions] = useState<OptionType[]>([])
  const [domainInitialized, setDomainInitialized] = useState<boolean>(false)
  const [innerValue, setInnerValue] = useState<SingleValueType>([])
  const [innerLabels, setInnerLabels] = useState<string[]>([])
  const dispatch = useDispatch()
  const [userGetMany, { data, isFetching }] = useLazyUserGetManyQuery()
  const userId = props.value ? props.value[0] : undefined
  const businessId = props.value ? props.value[1] : undefined

  const debounceFetcher = useMemo(() => {
    const loadOptions = (ftsSearch: string) => {
      userGetMany({
        ...denonify({
          emailAlike: ftsSearch || 'mdklwqmdlkqmkld', // *оставьте, чтобы возвращал пустой массив при отсутствии ввода
          id: props.value?.[0],
        }),
      })
    }
    return debounce(loadOptions, debounceTimeout)
  }, [props.value, debounceTimeout])

  const initializeDomain = async (businessId: string) => {
    const { data } = await userGetMany({
      ...denonify({
        businessId,
      }),
    })
    if (data) {
      const user = data.find(({ businesses }) =>
        businesses.map(({ id }) => id).includes(businessId),
      )
      if (user) {
        const business = user.businesses.find(({ id }) => id === businessId)
        if (business) {
          handleChange(user.id, businessId, data, [user.email, business.name])
        }
      }
    }
    setDomainInitialized(true)
  }

  useEffect(() => {
    if (businessId !== undefined && domainInitialized === false) {
      initializeDomain(businessId)
    }
  }, [businessId])

  useEffect(() => {
    if (data) {
      setOptions(
        data.map(({ id, email, businesses }) => ({
          label: email,
          value: id,
          children: businesses.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        })),
      )
    }
  }, [data])

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    )

  const handleChange = (
    userId: string,
    businessId: string,
    users: UserResponse[],
    labels: string[],
  ) => {
    setInnerValue([userId, businessId])
    setInnerLabels(labels)
    if (onChange !== undefined) {
      onChange([userId, businessId])
    }
    const user = users.find(({ id }) => id === userId)
    const business = user?.businesses.find(({ id }) => id === businessId)
    if (business !== undefined) {
      dispatch(setBusiness({ selectedBusiness: business }))
    }
  }

  const handleClearChange = () => {
    setInnerValue([null, null])
    setInnerLabels([])
    if (onChange !== undefined) {
      onChange([undefined, undefined])
    }
    dispatch(setBusiness({ selectedBusiness: undefined }))
  }

  return (
    <Cascader
      options={options}
      onChange={(
        val: SingleValueType | undefined,
        selectOptions: OptionType[],
      ) => {
        if (val !== undefined) {
          const [userId, businessId] = val
          if (
            typeof userId === 'string' &&
            typeof businessId === 'string' &&
            data !== undefined
          ) {
            handleChange(
              userId,
              businessId,
              data,
              selectOptions.map(({ label }) => label),
            )
          } else if (userId === null && businessId === null) {
            handleClearChange()
          }
        } else {
          handleClearChange()
        }
      }}
      expandTrigger="hover"
      value={innerValue as any}
      placeholder="Please select"
      style={{
        width: `${innerLabels ? innerLabels.map((v) => (v ? v.length : 0)).reduce((p, c) => p + c, 0) * 9 || 200 : 200}px`,
      }}
      showSearch={{ filter }}
      onSearch={debounceFetcher}
    />
  )
}
