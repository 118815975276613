import type { SerializedError } from '@reduxjs/toolkit'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import type { UploadFile } from 'antd'
import type dayjs from 'dayjs'
import type { SetURLSearchParams } from 'react-router-dom'

import { updateSearchParams } from '../../utils/router'

export type IFormBody<
  Body,
  DateFields extends keyof Body = never,
  FileFields extends keyof Body = never,
> = Omit<Body, DateFields | FileFields> & {
  [P in DateFields]: dayjs.Dayjs | undefined | null
} & {
  [P in FileFields]: UploadFile | null | undefined
}

// export interface FormField {
//   name: string
//   label: FlatNamespace
//   placeholder: FlatNamespace
//   rules?: Rule[]
//   iconify?: string
//   default?: null | string | number | boolean
// }

interface TError400<Type> {
  status: 400
  data: {
    [Property in keyof Type]?: string[] | 400
  }
}

function GError400<T>(
  error: FetchBaseQueryError | SerializedError | undefined,
): error is TError400<T> {
  return error !== undefined && (error as TError400<T>).status === 400
}

export function GetErrors<T>(
  error: FetchBaseQueryError | SerializedError | undefined,
) {
  const errors: {
    [Property in keyof T]?: string
  } = {}
  if (GError400<T>(error)) {
    for (const key in error.data) {
      const val = error.data[key]
      if (val !== undefined && val !== 400) {
        errors[key] = val.join('; ')
      }
    }
  }
  return errors
}

export const extractFile = (uFile: UploadFile<any> | null | undefined) => {
  let file
  if (uFile === null || uFile === undefined) {
    file = uFile as any
  } else if (uFile.uid.startsWith('rc-upload')) {
    file = uFile as any
  } else if (uFile.status === 'removed') {
    file = null as any
  }
  return file
}

export const onPageChange =
  (
    searchParams: URLSearchParams,
    setSearchParams: SetURLSearchParams,
    setPageSize: (pageSize: number) => void,
  ) =>
  (page: number, pageSize: number) => {
    updateSearchParams(
      {
        page: page.toString(),
      },
      searchParams,
      setSearchParams,
    )
    setPageSize(pageSize)
  }
