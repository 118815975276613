import { Button, Col, Modal, Row } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAssistantFileCreateMutation } from '../../../redux/api/assistant/assistant_file'

import AssistantFileTransfer from './AssistantFileTransfer'

type Props = {
  businessId: string
  assistantId: string
}

export default function AssistantFileDashboard({
  businessId,
  assistantId,
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [targetKeys, setTargetKeys] = useState<string[] | undefined>([])
  const [assistantFileCreate] = useAssistantFileCreateMutation()
  const { t } = useTranslation()

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = async () => {
    if (targetKeys !== undefined) {
      const {} = await assistantFileCreate({
        assistantFileBody: {
          assistant_id: assistantId,
          business_file_ids: targetKeys,
        },
      })
    }
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setTargetKeys([])
  }

  return (
    <>
      <Button type="default" onClick={showModal}>
        {t('assistant.assistant_file.form.create.title')}
      </Button>
      <Modal
        title={t('assistant.assistant_file.form.create.title')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        cancelText={t('components.form.control.cancel')}
        okText={t('assistant.assistant_file.form.actions.create')}
      >
        <Row>
          <Col span={24}>
            <AssistantFileTransfer
              businessId={businessId}
              assistantId={assistantId}
              targetKeys={targetKeys}
              setTargetKeys={setTargetKeys}
            />
          </Col>
        </Row>
      </Modal>
    </>
  )
}
