import dayjs from 'dayjs'

export const parseBool = (val: string | undefined) => {
  if (typeof val === 'string') {
    return ['true', '1'].includes(val.toLowerCase())
  } else if (typeof val === 'number') {
    return val === 1
  }
  return false
}

export function parseDate(
  dateStr: string | undefined | null,
  format = 'YYYY-MM-DD',
  def: undefined | any = undefined,
) {
  return typeof dateStr === 'string' &&
    dateStr !== undefined &&
    dateStr !== null
    ? dayjs(dateStr, format)
    : def
}
