import { authApi as api } from './index'
export const addTagTypes = ['/user'] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      userGetMany: build.query<UserGetManyApiResponse, UserGetManyApiArg>({
        query: (queryArg) => ({
          url: `/auth/user/`,
          params: {
            id: queryArg.id,
            business_id: queryArg.businessId,
            email_alike: queryArg.emailAlike,
          },
        }),
        providesTags: ['/user'],
      }),
      userGetMe: build.query<UserGetMeApiResponse, UserGetMeApiArg>({
        query: () => ({ url: `/auth/user/me` }),
        providesTags: ['/user'],
      }),
      userActivate: build.mutation<UserActivateApiResponse, UserActivateApiArg>(
        {
          query: (queryArg) => ({
            url: `/auth/user/activate/${queryArg.email}`,
            method: 'POST',
            body: queryArg.activatedRequest,
          }),
          invalidatesTags: ['/user'],
        }
      ),
      userTruncateThreads: build.mutation<
        UserTruncateThreadsApiResponse,
        UserTruncateThreadsApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/user/${queryArg.id}/truncate_threads`,
          method: 'DELETE',
          body: queryArg.truncateRequest,
        }),
        invalidatesTags: ['/user'],
      }),
      userDelete: build.mutation<UserDeleteApiResponse, UserDeleteApiArg>({
        query: (queryArg) => ({
          url: `/auth/user/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['/user'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as userApi }
export type UserGetManyApiResponse =
  /** status 200 Successful Response */ UserAdminResponse[]
export type UserGetManyApiArg = {
  /** UUID of the business to get assistants for */
  id?: string
  /** UUID of the business to get assistants for */
  businessId?: string
  /** Search string */
  emailAlike?: string
}
export type UserGetMeApiResponse =
  /** status 200 Successful Response */ UserResponse
export type UserGetMeApiArg = void
export type UserActivateApiResponse = /** status 200 Successful Response */ any
export type UserActivateApiArg = {
  email: string
  activatedRequest: ActivatedRequest
}
export type UserTruncateThreadsApiResponse =
  /** status 200 Successful Response */ any
export type UserTruncateThreadsApiArg = {
  id: string
  truncateRequest: TruncateRequest
}
export type UserDeleteApiResponse = /** status 200 Successful Response */ any
export type UserDeleteApiArg = {
  id: string
}
export type IndustryEnum =
  | 'Technology'
  | 'Finance'
  | 'Healthcare'
  | 'Education'
  | 'Retail'
  | 'Hospitality'
  | 'Sport'
  | 'Other'
export type CountryResponse = {
  id: number
  /** Country name */
  name: string
}
export type BusinessResponse = {
  id: string
  /** Business name */
  name: string
  /** Brief description of the business */
  description: string | null
  /** Brief description of the business */
  ein: string | null
  /** Brief description of the business */
  address: string | null
  industry: IndustryEnum | null
  /** Country details */
  country?: CountryResponse | null
}
export type RoleResponse = {
  id: number
  name: string
}
export type UserAssistantResponse = {
  id: string
  title: string
}
export type Statistics = {
  assistant_id: string
  good_run_count: number
  bad_run_count: number
  good_thread_count: number
  bad_thread_count: number
}
export type UserAdminResponse = {
  id: string
  email: string
  is_admin: boolean
  is_activated: boolean
  tenant?: string | null
  username: string
  assistant_id?: string | null
  /** List of businesses associated with the user, can be empty. */
  businesses: BusinessResponse[]
  /** List of roles associated with the user */
  roles: RoleResponse[]
  /** List of assistants the user can interact with */
  user_assistants: UserAssistantResponse[]
  statistics: Statistics[]
}
export type ValidationError = {
  loc: (string | number)[]
  msg: string
  type: string
}
export type HttpValidationError = {
  detail?: ValidationError[]
}
export type UserResponse = {
  id: string
  email: string
  is_admin: boolean
  is_activated: boolean
  tenant?: string | null
  username: string
  assistant_id?: string | null
  /** List of businesses associated with the user, can be empty. */
  businesses: BusinessResponse[]
  /** List of roles associated with the user */
  roles: RoleResponse[]
  /** List of assistants the user can interact with */
  user_assistants: UserAssistantResponse[]
}
export type ActivatedRequest = {
  is_activated: boolean
}
export type TruncateRequest = {
  is_activated: boolean
}
export const {
  useUserGetManyQuery,
  useLazyUserGetManyQuery,
  useUserGetMeQuery,
  useLazyUserGetMeQuery,
  useUserActivateMutation,
  useUserTruncateThreadsMutation,
  useUserDeleteMutation,
} = injectedRtkApi
